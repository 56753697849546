<template>
  <div id="job" class="main-wrapper">
    <Navbar></Navbar>
    <main class="page-wrapper single-job">
      <!-- <breadcrumb :links="links"></breadcrumb> -->
      <div class="container container-control">
        <div class="row pt-5 pb-5 custom-container">
          <div class="col-md-8 col-lg-9">
            <div class="col-12" id="form">
              <h3>{{ job.title }}</h3>
              <div v-if="!job.fullForm" v-html="job.description"></div>
              <div
                v-if="!job.fullForm && job.skills != null && job.skills.length"
              >
                <h4>Skills:</h4>
                <span
                  v-for="skill in job.skills"
                  :key="skill.name"
                  class="badge badge-primary mr-2"
                  >{{ skill.name }}</span
                >
              </div>
            </div>
            <!-- <div id="map" class="bg-gray mt-4">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d115895.24156288808!2d46.629473!3d24.804824!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8db8771a760e1b6e!2z2KfZhNi02LHZg9ipINin2YTZiNi32YbZitipINmE2YTYo9mG2LjZhdipINin2YTZhdmK2YPYp9mG2YrZg9mK2Kkg2KfZhiDYs9mKINin2YUg2KfYsw!5e0!3m2!1sar!2ssa!4v1611661206933!5m2!1sar!2ssa"
                style="border:0;width: 100%;"
                height="450"
                frameborder="0"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </div> -->
          </div>
          <div class="col-md-4 col-lg-3 mx-auto mt-5 mt-lg-0">
            <div class="job-map">
              <div class="apply bg-gray text-center">
                <div class="col-12">
                  <i class="text-danger" v-if="isLoggedIn && !user.canApply">{{
                    $i18n.t("Can not apply now")
                  }}</i>
                </div>
                <!-- <button
                  type="button"
                  @click="apply"
                  class="btn btn-apply text-uppercase"
                  :disabled="isLoggedIn && !user.canApply"
                >

                  {{ $i18n.t("Apply Now") }}
                </button> -->
                <button
                  type="button"
                  @click="$router.push('/jobs/apply?id=' + $route.query.id)"
                  class="btn btn-apply text-uppercase"
                  :disabled="isLoggedIn && !user.canApply"
                >
                  {{ $i18n.t("Apply Now") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer></Footer>
  </div>
</template>
<style></style>
<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import Breadcrumb from "@/components/Breadcrumb";

import Vue from "vue";
import TextElement from "../../../../ncms-vue/components/partials/templates/TextElement";
import TableElement from "../../../../ncms-vue/components/partials/templates/TableElement";
import TextAreaElement from "../../../../ncms-vue/components/partials/templates/TextAreaElement";
import CheckboxElement from "../../../../ncms-vue/components/partials/templates/CheckboxElement";
import SelectElement from "../../../../ncms-vue/components/partials/templates/SelectElement";
import TreeElement from "../../../../ncms-vue/components/partials/templates/TreeElement";
import LabelElement from "../../../../ncms-vue/components/partials/templates/LabelElement";
import LineElement from "../../../../ncms-vue/components/partials/templates/LineElement";
import RadioElement from "../../../../ncms-vue/components/partials/templates/RadioElement";
import AttachmentElement from "../../../../ncms-vue/components/partials/templates/AttachmentElement";

const _TextElement = Vue.extend(TextElement);
const _LabelElement = Vue.extend(LabelElement);
const _TableElement = Vue.extend(TableElement);
const _TextAreaElement = Vue.extend(TextAreaElement);
const _CheckboxElement = Vue.extend(CheckboxElement);
const _SelectElement = Vue.extend(SelectElement);
const _TreeElement = Vue.extend(TreeElement);
const _LineElement = Vue.extend(LineElement);
const _RadioElement = Vue.extend(RadioElement);
const _AttachmentElement = Vue.extend(AttachmentElement);

export default {
  name: "Job",
  components: { Breadcrumb, Footer, Navbar },
  data() {
    return {
      links: [
        {
          key: "Jobs",
          value: "/jobs",
        },
        {
          key: "Job",
          value: "/jobs/job?id=" + this.$route.query.id,
        },
      ],
      loading: false,
      job: {},
      userForm: [], // form - job details
      current_tab: 0,
      tabs: 0,
      uploading: true,
      uploadsDone: 0,
      uploadsFail: 0,
      files: 0,
      applying: false,
    };
  },

  created() {
    this.$store.dispatch("refresh_user");

    // document.body.style.overflowY = "hidden";
    // load job
    this.loading = true;
    axios
      .get("website/jobs/" + this.$route.query.id)
      .then((response) => {
        if (typeof response.data.data.data !== "undefined") {
          this.job = response.data.data.data;
          // show form
          let userForm = response.data.data.data.fullForm;
          if (userForm != null) {
            this.userForm.id = userForm.id;
            this.userForm.name = userForm.name;
            this.userForm.expires_at = userForm.expires_at;
            this.userForm.user = userForm.user;
            this.userForm.assigned_at = userForm.assigned_at;
            this.userForm.updated_at = userForm.updated_at;
            this.userForm.notifications = userForm.notifications;
            this.userForm.status = userForm.status;
            this.userForm.reviewedPercent = userForm.reviewedPercent;
            this.userForm.oldReviewedPercent = userForm.reviewedPercent;
            this.userForm.acceptedPercent = userForm.acceptedPercent;
            this.userForm.action =
              userForm.action === "Received" ? "Filling" : userForm.action;
            this.userForm.action_activities = userForm.action_activities;
            this.userForm.pages = [];
            // mount template pages
            let temp = this;
            for (let i = 0; i < userForm.pages.length; i++) {
              // add page
              setTimeout(function() {
                temp.addPage(userForm.pages[i].title);
                temp.userForm.pages[i].id = userForm.pages[i].id;
                // add page items
                for (let j = 0; j < userForm.pages[i].items.length; j++) {
                  if (!userForm.pages[i].items[j].website_view) continue;

                  let id = parseInt(userForm.pages[i].items[j].id);
                  let fill = "";
                  if (userForm.pages[i].items[j].filling !== null) {
                    fill = userForm.pages[i].items[j].filling.value;
                  }
                  let childList = JSON.parse(
                    userForm.pages[i].items[j].childList
                  );
                  switch (userForm.pages[i].items[j].type) {
                    case "text":
                      temp.addTextElement(fill, id);
                      break;
                    case "label":
                      temp.addLabelElement(fill, id);
                      break;
                    case "textarea":
                      temp.addTextAreaElement(fill, id);
                      break;
                    case "checkbox":
                      temp.addCheckboxElement(fill, id);
                      break;
                    case "select":
                      temp.addSelectElement(fill, id);
                      break;
                    case "table":
                      temp.addTableElement(fill, id);
                      temp.userForm.pages[i].items[j].columns =
                        childList[0].length;
                      temp.userForm.pages[i].items[j].rows = childList.length;
                      break;
                    case "tree":
                      temp.addTreeElement(fill, id);
                      break;
                    case "line":
                      temp.addLineElement(fill, id);
                      break;
                    case "radio":
                      temp.addRadioElement(fill, id);
                      break;
                    case "file":
                      temp.addAttachmentElement(fill, id);
                      break;
                    default:
                      temp.userForm.pages[i].items[j] = {};
                  }
                  temp.userForm.pages[i].items[j].label =
                    userForm.pages[i].items[j].label;
                  temp.userForm.pages[i].items[j].notes =
                    userForm.pages[i].items[j].notes;
                  temp.userForm.pages[i].items[j].childList =
                    (userForm.pages[i].items[j].type === "table" ||
                      userForm.pages[i].items[j].type === "tree") &&
                    fill !== ""
                      ? JSON.parse(fill)
                      : childList;
                  temp.userForm.pages[i].items[j].width =
                    userForm.pages[i].items[j].width;
                  temp.userForm.pages[i].items[j].height =
                    userForm.pages[i].items[j].height;
                  temp.userForm.pages[i].items[j].enabled = 0;
                  temp.userForm.pages[i].items[j].website_view =
                    userForm.pages[i].items[j].website_view;

                  // temp.$watch(() => temp.userForm.pages[i].items[j], temp.handleFilling, {deep: true});
                }
              }, 700 * i);
              setTimeout(function() {
                // temp.$watch(() => temp.userForm, temp.handleFilling, {deep: true});

                document.getElementById("form").style.visibility = "visible";
                document.body.style.overflowY = "auto";
                document.querySelector(".preloader").style.display = "none";
                temp.loading = false;
              }, 701 * userForm.pages.length);
            }
          } else {
            document.getElementById("form").style.visibility = "visible";
            document.body.style.overflowY = "auto";
            document.querySelector(".preloader").style.display = "none";
          }
        }

        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
      });
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  watch: {
    files() {
      this.checkUploading();
    },
    uploadsDone() {
      this.checkUploading();
    },
    uploadsFail() {
      this.checkUploading();
    },
    loading() {},
  },
  methods: {
    checkUploading() {
      if (this.files === this.uploadsDone + this.uploadsFail) {
        this.uploading = false;
        this.uploadingStart = false;
      }
    },
    apply() {
      this.loading = true;
      if (!this.isLoggedIn) {
        this.$router.push(
          "/login"
          // "/login?return=job-" + this.$router.currentRoute.query.id
        );
        return;
      }

      this.uploading = true;
      this.uploadingStart = true;
      this.files = 0;
      this.uploadsDone = 0;
      this.uploadsFail = 0;
      if (typeof this.userForm.id != "undefined") {
        // check for files to upload
        let self = this;
        for (let i = 0; i < this.userForm.pages.length; i++) {
          for (let j = 0; j < this.userForm.pages[i].items.length; j++) {
            if (this.userForm.pages[i].items[j].type === "file") {
              this.files += this.userForm.pages[i].items[j].childList.length;
            }
          }
        }

        if (this.files < 1) {
          this.uploading = false;
          this.uploadingStart = false;
        }

        for (let i = 0; i < this.userForm.pages.length; i++) {
          for (let j = 0; j < this.userForm.pages[i].items.length; j++) {
            if (this.userForm.pages[i].items[j].type === "file") {
              this.userForm.pages[i].items[j].childList.map(function(
                attachment,
                index
              ) {
                let formData = new FormData();
                let file = document.getElementById(
                  "files-" + self.userForm.pages[i].items[j].id + "-" + index
                ).files[0];
                if (typeof file != "undefined") {
                  formData.append("file", file);
                  formData.append(
                    "folder",
                    "website/applications/" + self.user.id
                  );

                  return;
                  axios
                    .post("uploader", formData)
                    .then((response) => {
                      if (response.data.success) {
                        self.userForm.pages[i].items[j].childList[index].file =
                          response.data.data;
                        self.uploadsDone++;
                      }
                    })
                    .catch((error) => {
                      self.uploadsFail++;
                      self.userForm.pages[i].items[j].childList[index].error =
                        error.response.data.errors.file[0];
                    });
                } else {
                  self.uploadsFail++;
                }
              });
            }
          }
        }
      } else {
        this.uploading = false;
        this.uploadingStart = false;
      }
    },
    addPage(title = "Page Title ..") {
      this.tabs++;
      this.current_tab = this.tabs - 1;
      this.userForm.pages[this.current_tab] = { title: title, items: [] };
      document.getElementById("form").innerHTML +=
        '<div class="clearfix"></div><p class="detail-head pre-line mt-4">' +
        title +
        "</p>";
    },
    addTextElement(fill = "", id = 0) {
      const textElement = new _TextElement();
      textElement.$data.reviewing = true;
      textElement.$data.previewing = true;
      textElement.$data.fill = fill;
      textElement.$data.id = id;
      textElement.$mount();
      document.getElementById("form").appendChild(textElement.$el);
      this.userForm.pages[this.current_tab].items[
        this.userForm.pages[this.current_tab].items.length
      ] = textElement.$data;
    },
    addLabelElement(fill = "", id = 0) {
      const labelElement = new _LabelElement();
      labelElement.$data.reviewing = true;
      labelElement.$data.previewing = true;
      labelElement.$data.fill = fill;
      labelElement.$data.id = id;
      labelElement.$mount();
      document.getElementById("form").appendChild(labelElement.$el);
      this.userForm.pages[this.current_tab].items[
        this.userForm.pages[this.current_tab].items.length
      ] = labelElement.$data;
    },
    addTableElement(fill = "", id = 0) {
      const tableElement = new _TableElement();
      tableElement.$data.reviewing = true;
      tableElement.$data.previewing = true;
      tableElement.$data.fill = fill;
      tableElement.$data.id = id;
      tableElement.$mount();
      document.getElementById("form").appendChild(tableElement.$el);
      this.userForm.pages[this.current_tab].items[
        this.userForm.pages[this.current_tab].items.length
      ] = tableElement.$data;
    },
    addTextAreaElement(fill = "", id = 0) {
      const textAreaElement = new _TextAreaElement();
      textAreaElement.$data.reviewing = true;
      textAreaElement.$data.previewing = true;
      textAreaElement.$data.fill = fill;
      textAreaElement.$data.id = id;
      textAreaElement.$mount();
      document.getElementById("form").appendChild(textAreaElement.$el);
      this.userForm.pages[this.current_tab].items[
        this.userForm.pages[this.current_tab].items.length
      ] = textAreaElement.$data;
    },
    addCheckboxElement(fill = "", id = 0) {
      const checkboxElement = new _CheckboxElement();
      checkboxElement.$data.reviewing = true;
      checkboxElement.$data.previewing = true;
      checkboxElement.$data.fill = fill;
      checkboxElement.$data.id = id;
      checkboxElement.$mount();
      document.getElementById("form").appendChild(checkboxElement.$el);
      this.userForm.pages[this.current_tab].items[
        this.userForm.pages[this.current_tab].items.length
      ] = checkboxElement.$data;
    },
    addSelectElement(fill = "", id = 0) {
      const selectElement = new _SelectElement();
      selectElement.$data.reviewing = true;
      selectElement.$data.previewing = true;
      selectElement.$data.fill = fill;
      selectElement.$data.id = id;
      selectElement.$mount();
      document.getElementById("form").appendChild(selectElement.$el);
      this.userForm.pages[this.current_tab].items[
        this.userForm.pages[this.current_tab].items.length
      ] = selectElement.$data;
    },
    addTreeElement(fill = "", id = 0) {
      const treeElement = new _TreeElement();
      treeElement.$data.previewing = true;
      treeElement.$data.reviewing = true;
      treeElement.$data.fill = fill;
      treeElement.$data.id = id;
      treeElement.$mount();
      document.getElementById("form").appendChild(treeElement.$el);
      this.userForm.pages[this.current_tab].items[
        this.userForm.pages[this.current_tab].items.length
      ] = treeElement.$data;
    },
    addLineElement(fill = "", id = 0) {
      const lineElement = new _LineElement();
      lineElement.$data.reviewing = true;
      lineElement.$data.previewing = true;
      lineElement.$data.fill = fill;
      lineElement.$data.id = id;
      lineElement.$mount();
      document.getElementById("form").appendChild(lineElement.$el);
      this.userForm.pages[this.current_tab].items[
        this.userForm.pages[this.current_tab].items.length
      ] = lineElement.$data;
    },
    addRadioElement(fill = "", id = 0) {
      const radioElement = new _RadioElement();
      radioElement.$data.reviewing = true;
      radioElement.$data.previewing = true;
      radioElement.$data.fill = fill;
      radioElement.$data.id = id;
      radioElement.$mount();
      document.getElementById("form").appendChild(radioElement.$el);
      this.userForm.pages[this.current_tab].items[
        this.userForm.pages[this.current_tab].items.length
      ] = radioElement.$data;
    },
    addAttachmentElement(fill = "", id = 0) {
      const attachmentElement = new _AttachmentElement();
      attachmentElement.$data.reviewing = true;
      attachmentElement.$data.previewing = true;
      attachmentElement.$data.fill = fill;
      attachmentElement.$data.id = id;
      attachmentElement.$mount();
      document.getElementById("form").appendChild(attachmentElement.$el);
      this.userForm.pages[this.current_tab].items[
        this.userForm.pages[this.current_tab].items.length
      ] = attachmentElement.$data;
    },
  },
};

// window.onload = function () {
//   //hide the preloader
//   document.querySelector(".preloader").style.display = "none";
// }
</script>

<style></style>
