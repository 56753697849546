import jobs from "@/pages/jobs/jobs";
import job from "@/pages/jobs/job";
import apply from "@/pages/jobs/apply";
import login from "@/pages/auth/login";
import verify from "@/pages/auth/verify";
import signup from "@/pages/auth/signup";
import forgot from "@/pages/auth/forgot";
import reset from "@/pages/auth/reset";

import profile from "@/pages/user/profile";
import applications from "@/pages/user/applications";
import quiz from "@/pages/user/quiz";
import application from "@/pages/user/application";

// import terms from "@/pages/home/terms";
import underMaintenance from "@/pages/home/underMaintenance";

export const routes = [
  // {path: '/', component: home},
  { path: "/", component: jobs },

  { path: "/jobs", component: jobs },
  { path: "/jobs/job", component: job },
  { path: "/jobs/apply", component: apply },

  { path: "/login", component: login, name: "login" },
  { path: "/verify", component: verify },
  { path: "/signup", component: signup },
  { path: "/forgot", component: forgot, name: "forgot" },
  { path: "/reset", component: reset, name: "reset" },

  { path: "/profile", component: profile, name: "profile" },
  { path: "/applications", component: applications },
  { path: "/quiz", component: quiz },
  { path: "/application", component: application },

  // { path: "/terms", component: terms },

  {
    path: "/under-maintenance",
    component: underMaintenance,
    name: "underMaintenance",
  },
];
