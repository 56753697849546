<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import Breadcrumb from "@/components/Breadcrumb";
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "Login",
  components: {
    Breadcrumb,
    Footer,
    Navbar,
    VueRecaptcha,
  },
  data() {
    return {
      links: [
        {
          key: "Login",
          value: "login",
        },
      ],
      email: "",
      password: "",
      error: false,
      errorMessage: "",
      loading: false,
      isLogged: false,
      sitekey: "6LdTrmMlAAAAAPoizJVl0U99syaFd7r3YX5r71Zd", // "6LdoES0mAAAAAEAIOWNjqR78KOrfnTPBWuC3Xvf_",
      recaptcha_verified: false,
      recaptcha: null,
      showPw: false,
    };
  },
  computed: {
    passwordFieldType() {
      return this.showPw ? "text" : "password";
    },
  },
  mounted() {
    // document.querySelector(".preloader").style.display = "none";
    // document.body.style.overflowY = "auto";
    setTimeout(() => {
      this.heideAlerst();
    }, 8000);
  },
  methods: {
    showPassword(e) {
      e.preventDefault();
      this.showPw = true;
    },
    hidePassword(e) {
      e.preventDefault();
      this.showPw = false;
    },
    heideAlerst() {
      const alertElms = document.querySelectorAll(".alert-container");
      alertElms.forEach((alertElm) => {
        alertElm.classList.add("d-none");
      });
    },
    onVerify: function(response) {
      this.recaptcha_verified = true;
      this.recaptcha = response;
    },
    onExpired: function() {
      this.recaptcha_verified = false;
    },
    resetRecaptcha() {
      this.recaptcha_verified = false;
      this.$refs.recaptcha.reset();
    },
    login() {
      this.error = false;
      this.errorMessage = "";
      this.loading = true;
      // if (!this.recaptcha_verified) {
      //   this.loading = false;
      //   return;
      // }

      let email = this.email;
      let password = btoa(this.password);

      this.$store
        .dispatch("login", {
          email,
          password,
          // "g-recaptcha-response": this.recaptcha,
        })
        .then(() => {
          let returnTo = this.$router.currentRoute.query.return;
          this.isLogged = true;
          let go = "/";
          if (returnTo && returnTo.split("-").length === 2) {
            returnTo = returnTo.split("-");
            switch (returnTo[0]) {
              case "job":
                go = "/jobs/apply?id=" + returnTo[1];
                break;
            }
          }
          // this.$router.push(go)
          setTimeout(() => {
            location.href = go;
            // this.isLogged = false
          }, 2000);
        })
        .catch((error) => {
          this.isLogged = false;
          this.errorMessage = this.$i18n.t("The given data was invalid");
          this.error = true;
          this.loading = false;
          if (
            typeof error.response.data.message != "undefined" &&
            error.response.data.message == "Please verify your mail first"
          )
            this.errorMessage = this.$i18n.t("Please verify your mail first");

          // this.resetRecaptcha();
        });
    },
  },
};
</script>

<template>
  <div id="login">
    <main class="page-wrapper login-page">
      <div class="row">
        <div
          class="col-12 text-center alert-container"
          v-if="typeof $router.currentRoute.query.verify != 'undefined'"
        >
          <span class="alert alert-success">{{
            $i18n.t(
              "An email with the verification code was sent to your email"
            )
          }}</span>
        </div>
        <div
          class="col-12 text-center alert-container"
          v-else-if="typeof $router.currentRoute.query.verified != 'undefined'"
        >
          <span class="alert alert-success">{{
            $i18n.t("Your email was verified successfully")
          }}</span>
        </div>
        <div
          class="col-12 text-center alert-container"
          v-else-if="
            typeof $router.currentRoute.query.unverified != 'undefined'
          "
        >
          <span class="alert alert-danger">{{
            $i18n.t("Verified Failed")
          }}</span>
        </div>

        <div class="col-12 col-lg-6 right_side d-none d-xl-block d-lg-block">
          <img src="@/assets/bck_login.svg" alt="" />
          <div class="overlay_s">
            <img src="@/assets/logo-w.png" alt="" id="gadd" />
            <img src="@/assets/log.svg" alt="" id="log" />
          </div>
        </div>
        <div class="col-lg-6 col-sm-12 mx-auto ">
          <div class="login">
            <div v-if="0" class="socialLogin text-center mb-5">
              <h5 class="mb-3">{{ $i18n.t("Login with social media") }}</h5>
              <a href="#" class="fb mx-2" title="Facebook">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="33"
                  viewBox="0 0 21.369 23.192"
                >
                  <g data-name="Group 224" transform="translate(0)">
                    <path
                      data-name="Path 12"
                      d="M647.813,345.97v2.453h-1.772a1.093,1.093,0,0,0-1.094,1.092v2.055H647.7l-.357,2.829h-2.4v7.132h-2.923v-7.151h-2.406v-2.81h2.424V349.1a3.2,3.2,0,0,1,3.278-3.19Z"
                      transform="translate(-633.935 -342.335)"
                      fill="#fff"
                      class="path"
                    ></path>
                  </g>
                </svg>
              </a>
              <a href="#" class="tw" title="Twitter">
                <svg
                  data-name="Group 225"
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="33"
                  viewBox="0 0 21.715 23.193"
                  class="path"
                >
                  <g transform="translate(3.843 5.614)">
                    <path
                      data-name="Path 1564"
                      d="M15.157,7.633a6.027,6.027,0,0,1-1.724.473,3.017,3.017,0,0,0,1.319-1.661,6.01,6.01,0,0,1-1.907.728A3,3,0,0,0,7.734,9.91,8.521,8.521,0,0,1,1.549,6.774a3.005,3.005,0,0,0,.929,4.008,2.975,2.975,0,0,1-1.36-.375v.038a3,3,0,0,0,2.407,2.943,2.975,2.975,0,0,1-.79.1,2.937,2.937,0,0,1-.565-.054,3,3,0,0,0,2.8,2.083A6.006,6.006,0,0,1,1.246,16.8a6.271,6.271,0,0,1-.714-.04,8.492,8.492,0,0,0,4.6,1.346,8.479,8.479,0,0,0,8.538-8.535c0-.132,0-.261-.009-.391a6,6,0,0,0,1.5-1.552"
                      transform="translate(-0.531 -6.227)"
                      fill="#fff"
                      class="path"
                    ></path>
                  </g>
                </svg>
              </a>
            </div>
            <div class="form-group px-3 mx-auto">
              <div
                class="d-flex flex-column align-items-center justify-content-center"
              >
                <img src="@/assets/logo.png" alt="Wakeb" class="mb-3 logo-sm" />
                <div class="login-heading text-center mb-3">
                  <h3>{{ $i18n.t("login.welcome") }}</h3>
                  <p>{{ $i18n.t("login.sub_msg") }}</p>
                </div>
              </div>
              <form @submit.prevent="login">
                <div class="col-12" v-if="error">
                  <i class="text-danger">{{ errorMessage }}</i>
                </div>

                <label for="" class="font-weight-bold">{{
                  $i18n.t("login.email")
                }}</label>
                <i class="text-danger" v-if="error && email === ''">{{
                  $i18n.t("Email field is required")
                }}</i>
                <input
                  v-model="email"
                  type="email"
                  class="form-control mb-4"
                  :placeholder="$i18n.t('login.email')"
                />
                <label for="" class="font-weight-bold">{{
                  $i18n.t("login.password")
                }}</label>
                <i class="text-danger" v-if="error && password === ''">{{
                  $i18n.t("Password field is required")
                }}</i>
                <div class="pw-cont">
                  <input
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control mb-4"
                    :placeholder="$i18n.t('login.password')"
                  />
                  <button
                    type="button"
                    class="toggle-pw"
                    @mousedown="showPassword"
                    @mouseup="hidePassword"
                    @mouseleave="hidePassword"
                  >
                    <i class="show-pw" v-if="showPw">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g id="surface1">
                          <path
                            style=" stroke:none;fill-rule:evenodd;fill:rgb(5.882353%,5.882353%,5.882353%);fill-opacity:1;"
                            d="M 11.996094 15.5 C 13.925781 15.5 15.496094 13.933594 15.496094 12 C 15.496094 10.066406 13.925781 8.5 11.996094 8.5 C 10.0625 8.5 8.496094 10.066406 8.496094 12 C 8.496094 13.933594 10.0625 15.5 11.996094 15.5 Z M 11.996094 13.496094 C 11.167969 13.496094 10.5 12.824219 10.5 12 C 10.5 11.175781 11.167969 10.503906 11.996094 10.503906 C 12.820312 10.503906 13.488281 11.175781 13.488281 12 C 13.488281 12.824219 12.820312 13.496094 11.996094 13.496094 Z M 11.996094 13.496094 "
                          />
                          <path
                            style=" stroke:none;fill-rule:evenodd;fill:rgb(5.882353%,5.882353%,5.882353%);fill-opacity:1;"
                            d="M 12 5 C 7.1875 5 3.917969 7.609375 2.1875 9.480469 C 0.855469 10.921875 0.855469 13.078125 2.1875 14.519531 C 3.917969 16.390625 7.1875 19 12 19 C 16.8125 19 20.082031 16.390625 21.8125 14.519531 C 23.144531 13.078125 23.144531 10.921875 21.8125 9.480469 C 20.082031 7.609375 16.8125 5 12 5 Z M 3.660156 10.835938 C 5.1875 9.179688 7.96875 7 12 7 C 16.03125 7 18.8125 9.179688 20.339844 10.835938 C 20.964844 11.511719 20.964844 12.488281 20.339844 13.164062 C 18.8125 14.820312 16.03125 17 12 17 C 7.96875 17 5.1875 14.820312 3.660156 13.164062 C 3.035156 12.488281 3.035156 11.511719 3.660156 10.835938 Z M 3.660156 10.835938 "
                          />
                        </g>
                      </svg>
                    </i>
                    <i class="hide-pw" v-else>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g id="surface1">
                          <path
                            style=" stroke:none;fill-rule:nonzero;fill:rgb(5.882353%,5.882353%,5.882353%);fill-opacity:1;"
                            d="M 4.496094 7.441406 C 3.546875 8.117188 2.777344 8.839844 2.1875 9.480469 C 0.855469 10.921875 0.855469 13.078125 2.1875 14.519531 C 3.917969 16.390625 7.1875 19 12 19 C 13.296875 19 14.480469 18.8125 15.550781 18.496094 L 13.890625 16.835938 C 13.292969 16.941406 12.664062 17 12 17 C 7.96875 17 5.1875 14.820312 3.660156 13.164062 C 3.035156 12.488281 3.035156 11.511719 3.660156 10.835938 C 4.238281 10.210938 4.996094 9.507812 5.933594 8.878906 Z M 4.496094 7.441406 "
                          />
                          <path
                            style=" stroke:none;fill-rule:nonzero;fill:rgb(5.882353%,5.882353%,5.882353%);fill-opacity:1;"
                            d="M 8.53125 11.476562 C 8.507812 11.648438 8.496094 11.824219 8.496094 12 C 8.496094 13.933594 10.0625 15.5 11.996094 15.5 C 12.171875 15.5 12.347656 15.488281 12.515625 15.460938 Z M 8.53125 11.476562 "
                          />
                          <path
                            style=" stroke:none;fill-rule:nonzero;fill:rgb(5.882353%,5.882353%,5.882353%);fill-opacity:1;"
                            d="M 15.464844 12.445312 L 11.546875 8.527344 C 11.695312 8.507812 11.84375 8.5 11.996094 8.5 C 13.925781 8.5 15.496094 10.066406 15.496094 12 C 15.496094 12.152344 15.484375 12.300781 15.464844 12.445312 Z M 15.464844 12.445312 "
                          />
                          <path
                            style=" stroke:none;fill-rule:nonzero;fill:rgb(5.882353%,5.882353%,5.882353%);fill-opacity:1;"
                            d="M 18.113281 15.09375 C 19.027344 14.46875 19.773438 13.78125 20.339844 13.164062 C 20.964844 12.488281 20.964844 11.511719 20.339844 10.835938 C 18.8125 9.179688 16.03125 7 12 7 C 11.359375 7 10.75 7.054688 10.171875 7.152344 L 8.503906 5.484375 C 9.5625 5.183594 10.726562 5 12 5 C 16.8125 5 20.082031 7.609375 21.8125 9.480469 C 23.144531 10.921875 23.144531 13.078125 21.8125 14.519531 C 21.230469 15.148438 20.476562 15.859375 19.546875 16.527344 Z M 18.113281 15.09375 "
                          />
                          <path
                            style=" stroke:none;fill-rule:nonzero;fill:rgb(5.882353%,5.882353%,5.882353%);fill-opacity:1;"
                            d="M 2.007812 3.421875 C 1.617188 3.03125 1.617188 2.398438 2.007812 2.007812 C 2.398438 1.617188 3.03125 1.617188 3.421875 2.007812 L 22 20.585938 C 22.390625 20.976562 22.390625 21.609375 22 22 C 21.609375 22.390625 20.976562 22.390625 20.585938 22 Z M 2.007812 3.421875 "
                          />
                        </g>
                      </svg>
                    </i>
                  </button>
                </div>
                <!-- <vue-recaptcha
                  ref="recaptcha"
                  id="recaptcha"
                  @verify="onVerify"
                  @expired="onExpired"
                  :sitekey="sitekey"
                  :loadRecaptchaScript="true"
                >
                </vue-recaptcha> -->
                <router-link
                  :to="{ name: 'forgot' }"
                  class="d-block text-mainColor font-weight-bold"
                >
                  {{ $i18n.t("Forgot my password") }}
                </router-link>
                <button
                  :disabled="loading"
                  type="submit"
                  class="input-group-text btn btn-main d-block w-100 mt-2 mx-auto p-2"
                >
                  <div class="row">
                    <div class="col-5 text-left spinner-cont">
                      <span
                        class="spinner-border "
                        role="status"
                        :style="'display: ' + (loading ? 'block' : 'none')"
                      >
                        <span class="sr-only">{{ $i18n.t("Loading") }}</span>
                      </span>
                    </div>
                    <div class="col d-block p-1 text-center">
                      {{ $i18n.t("Login") }}
                    </div>
                  </div>
                </button>
              </form>
            </div>
            <div class="text-center mt-4">
              <h6 class="text-sign_up w-100 d-inline">
                <span class="font-weight-normal">
                  {{ $i18n.t("Sign up") }}
                </span>
                <router-link to="signup" class="d-inline mb-3">
                  {{ $i18n.t("sign_up") }}
                </router-link>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div class="logining login_bg" v-if="isLogged">
      <img src="@/assets/logo-w.png" alt="" class="logo mx-auto" />
      <div class="text-content d-flex flex-column">
        <!-- <img src="@/assets/loader.gif" alt="" width="100" class="loading" /> -->
        <div
          style="width: 100px; height: 100px;"
          class="d-flex justify-content-center align-items-center"
        >
          <div class="loader loading"></div>
        </div>
        <div>
          <p class="logged-text">
            {{ $i18n.t("logged_success") }}
          </p>
        </div>
        <p class="mt-3 font-weight-bold" style="color: #fff">
          {{ $i18n.t("please_wait") }}.
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.logining {
  user-select: none !important;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: url("../../assets/bck_login.svg");
  background-size: cover;

  .logo {
    max-width: 225px;
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
  }

  .text-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    gap: 10px;
    justify-content: center;
    align-items: center;

    .loading {
      //animation: spin 1s alternate infinite;
    }

    .logged {
      max-width: 225px;
    }
  }
}

.text-sign_up {
  margin: 0 auto;
  font-weight: normal;
  color: #475467;
  text-align: center;

  a {
    font-weight: 600;

    &:hover {
      color: var(--main-color);
    }
  }
}

.right_side {
  max-height: 100vh;
  height: 100vh;
  position: relative;
  //background: url("../../assets/bck_login.svg");
  //background-position: center;
  //background-repeat: no-repeat;
  //background-size: 100% 100%;
  overflow-y: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    //height: 100vh;
    //max-height: 100vh;
    //height: 100%;
  }

  .overlay_s {
    width: 100%;
    height: 100%;
    position: relative;

    //z-index: 3;
    img#gadd {
      max-width: 280px;
      margin: 0 auto;
      position: absolute;
      top: 10%;
      left: 50%;
      transform: translateX(-50%);
    }

    img#log {
      width: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}

#login {
  height: 100vh;
}

.form-group {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.login-heading {
  h3 {
    color: #101828;
    font-weight: 700;
  }

  p {
    color: #667085 !important;
    font-weight: 500 !important;
  }
}

.login {
  padding: 10px;
  box-shadow: none;
  height: 100vh;
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: center;
  border: none;

  label {
    color: #344054;
  }
}

.text-mainColor {
  color: var(--main-color) !important;
}

.socialLogin .fb {
  background: #3b5998;
  padding: 2px 0;
  display: inline-block;
  transition: 0.3s;
}

.socialLogin .tw {
  background: #00aced;
  padding: 2px 0;
  display: inline-block;
  transition: 0.3s;
}

.socialLogin .tw:hover,
.socialLogin .fb:hover {
  transform: scale(1.08);
}

.login .form-control {
  height: 44px;
}

.login .btn-main:focus {
  background: #295a28;
  border-color: #295a28;
  color: #fff;
}

.login .text-colored {
  color: #00979e;
}

.login .text-colored:hover {
  color: #3a325e;
}

.login .text-mainColor {
  color: #333;
}

.login .text-mainColor:hover {
  color: #333;
}

.login-page .alert-container {
  left: 50vw;
  top: 35px;
  z-index: 1000;
}
.logged-text {
  font-size: 26px;
  font-weight: bold;
  color: #fff;
}
@media (min-width: 992px) {
  .login-page .alert-container {
    left: 26vw;
  }
}
</style>
