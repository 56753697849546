<template>
  <div
    v-if="!removed"
    class="element float-left"
    :style="{ height: height }"
    :class="{
      'col-3': width === 'col-3',
      'col-4': width === 'col-4',
      'col-6': width === 'col-6',
      'col-8': width === 'col-8',
      'col-12': width === 'col-12'
    }"
    @focusin="fillNotes = true"
    @focusout="fillNotes = false"
  >
    <input
      v-if="!filling && !reviewing"
      type="text"
      v-model="label"
      class="border-0"
      style="width:98%"
    />
    <label v-else class="border-0 text-bold">
      <span v-html="$globals.linkParser(label)"></span>
      <span
        v-if="showRequiredNotify && required && !childList.text"
        class="text-danger"
      >
        *</span
      >
    </label>

    <div
      class="note-content"
      :class="{
        fadeIn: filling && fillNotes && notes !== null,
        fadeOut: !(filling && fillNotes && notes !== null)
      }"
      @mouseover="fillNotes = true"
      @mouseout="fillNotes = false"
    >
      <p v-html="$globals.linkParser(notes)"></p>
    </div>

    <div
      v-if="filling && review.comment !== '' && review.comment !== null"
      class="alert alert-warning"
    >
      <p class="mb-0" v-html="$globals.linkParser(review.comment)"></p>
    </div>

    <div v-if="reviewing" class="reviewing-box">
      <div class="mt-1 text-left">
        <span class="mr-1 check-input">
          <label class="checkbox-cont checkbox-forms">
            <input type="checkbox" v-model="review.review" />
            <span class="checkmark"></span>
          </label>
        </span>
        <span
          class="add-note"
          title="Add Note"
          @click="commenting = !commenting"
        >
          <img src="/images/add-icon.svg" />
        </span>
        <div class="note-input" v-if="commenting">
          <textarea
            class="form-control"
            v-model="review.comment"
            style="min-height:60px;max-height: 60px;height: 60px; background: 0 !important;border: 0;"
          ></textarea>
        </div>
      </div>
    </div>

    <figure>
      <figcaption></figcaption>
      <ul class="tree" style="margin: 0 auto;">
        <li>
          <code>
            <input
              type="text"
              v-model="childList.text"
              class="form-control"
              style="width: 200px"
              :disabled="!enabled"
            />
            <img
              src="/images/add-icon.svg"
              alt="Add"
              title="Add"
              class="add ml-2 cursor-pointer"
              v-if="!reviewing && enabled"
              @click="childList.nodes.push({ text: '', nodes: [] })"
            />
          </code>
          <ul v-if="childList.nodes.length">
            <li v-for="(node, i1) in childList.nodes">
              <code>
                <input
                  type="text"
                  v-model="node.text"
                  class="form-control"
                  style="width: 200px"
                  :disabled="!enabled"
                />

                <img
                  src="/images/add-icon.svg"
                  alt="Add"
                  title="Add"
                  class="add ml-2 cursor-pointer"
                  v-if="!reviewing && enabled"
                  @click="
                    childList.nodes[i1].nodes.push({ text: '', nodes: [] })
                  "
                />
                <img
                  src="/images/remove.svg"
                  alt="Remove"
                  title="Remove"
                  width="13"
                  class="remove ml-2 cursor-pointer"
                  v-if="!reviewing && enabled"
                  @click="childList.nodes.splice(i1, 1)"
                />
              </code>
              <ul v-if="typeof node.nodes !== 'undefined' && node.nodes.length">
                <li v-for="(node2, i2) in node.nodes">
                  <code>
                    <input
                      type="text"
                      v-model="node2.text"
                      class="form-control"
                      style="width: 200px"
                      :disabled="!enabled"
                    />
                    <img
                      src="/images/add-icon.svg"
                      alt="Add"
                      title="Add"
                      class="add ml-2 cursor-pointer"
                      v-if="!reviewing && enabled"
                      @click="
                        childList.nodes[i1].nodes[i2].nodes.push({
                          text: '',
                          nodes: []
                        })
                      "
                    />
                    <img
                      src="/images/remove.svg"
                      alt="Remove"
                      title="Remove"
                      width="13"
                      class="remove ml-2 cursor-pointer"
                      v-if="!reviewing && enabled"
                      @click="childList.nodes[i1].nodes.splice(i2, 1)"
                    />
                  </code>
                  <ul
                    v-if="
                      typeof node2.nodes !== 'undefined' && node2.nodes.length
                    "
                  >
                    <li v-for="(node3, i3) in node2.nodes">
                      <code>
                        <input
                          type="text"
                          v-model="node3.text"
                          class="form-control"
                          style="width: 200px"
                          :disabled="!enabled"
                        />

                        <img
                          src="/images/add-icon.svg"
                          alt="Add"
                          title="Add"
                          class="add ml-2 cursor-pointer"
                          v-if="!reviewing && enabled"
                          @click="
                            childList.nodes[i1].nodes[i2].nodes[i3].nodes.push({
                              text: '',
                              nodes: []
                            })
                          "
                        />
                        <img
                          src="/images/remove.svg"
                          alt="Remove"
                          title="Remove"
                          width="13"
                          class="remove ml-2 cursor-pointer"
                          v-if="!reviewing && enabled"
                          @click="
                            childList.nodes[i1].nodes[i2].nodes.splice(i3, 1)
                          "
                        />
                      </code>
                      <ul
                        v-if="
                          typeof node3.nodes !== 'undefined' &&
                            node3.nodes.length
                        "
                      >
                        <li v-for="(node4, i4) in node3.nodes">
                          <code>
                            <input
                              type="text"
                              v-model="node4.text"
                              class="form-control"
                              style="width: 200px"
                              :disabled="!enabled"
                            />

                            <img
                              src="/images/add-icon.svg"
                              alt="Add"
                              title="Add"
                              class="add ml-2 cursor-pointer"
                              v-if="!reviewing && enabled"
                              @click="
                                childList.nodes[i1].nodes[i2].nodes[i3].nodes[
                                  i4
                                ].nodes.push({ text: '', nodes: [] })
                              "
                            />
                            <img
                              src="/images/remove.svg"
                              alt="Remove"
                              title="Remove"
                              width="13"
                              class="remove ml-2 cursor-pointer"
                              v-if="!reviewing && enabled"
                              @click="
                                childList.nodes[i1].nodes[i2].nodes[
                                  i3
                                ].nodes.splice(i4, 1)
                              "
                            />
                          </code>
                          <ul
                            v-if="
                              typeof node4.nodes !== 'undefined' &&
                                node4.nodes.length
                            "
                          >
                            <li v-for="(node5, i5) in node4.nodes">
                              <code>
                                <input
                                  type="text"
                                  v-model="node5.text"
                                  class="form-control"
                                  style="width: 200px"
                                  :disabled="!enabled"
                                />

                                <img
                                  src="/images/add-icon.svg"
                                  alt="Add"
                                  title="Add"
                                  class="add ml-2 cursor-pointer"
                                  v-if="!reviewing && enabled"
                                  @click="
                                    childList.nodes[i1].nodes[i2].nodes[
                                      i3
                                    ].nodes[i4].nodes[i5].nodes.push({
                                      text: '',
                                      nodes: []
                                    })
                                  "
                                />
                                <img
                                  src="/images/remove.svg"
                                  alt="Remove"
                                  title="Remove"
                                  width="13"
                                  class="remove ml-2 cursor-pointer"
                                  v-if="!reviewing && enabled"
                                  @click="
                                    childList.nodes[i1].nodes[i2].nodes[
                                      i3
                                    ].nodes[i4].nodes.splice(i5, 1)
                                  "
                                />
                              </code>
                              <ul
                                v-if="
                                  typeof node5.nodes !== 'undefined' &&
                                    node5.nodes.length
                                "
                              >
                                <li v-for="(node6, i6) in node5.nodes">
                                  <code>
                                    <input
                                      type="text"
                                      v-model="node6.text"
                                      class="form-control"
                                      style="width: 200px"
                                      :disabled="!enabled"
                                    />

                                    <img
                                      v-if="0"
                                      src="/images/add-icon.svg"
                                      alt="Add"
                                      title="Add"
                                      class="add ml-2 cursor-pointer"
                                      @click="
                                        childList.nodes[i1].nodes[i2].nodes[
                                          i3
                                        ].nodes[i4].nodes[i5].nodes[
                                          i6
                                        ].nodes.push({ text: '', nodes: [] })
                                      "
                                    />
                                    <img
                                      src="/images/remove.svg"
                                      alt="Remove"
                                      title="Remove"
                                      width="13"
                                      class="remove ml-2 cursor-pointer"
                                      v-if="!reviewing && enabled"
                                      @click="
                                        childList.nodes[i1].nodes[i2].nodes[
                                          i3
                                        ].nodes[i4].nodes[i5].nodes.splice(
                                          i6,
                                          1
                                        )
                                      "
                                    />
                                  </code>
                                  <ul></ul>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </figure>

    <div class="tree-menu" v-if="0">
      <div class="row">
        <input
          type="text"
          v-model="childList.text"
          class="form-control"
          style="width: 200px"
          :disabled="!enabled"
        />

        <img
          src="/images/add-icon.svg"
          alt="Add"
          title="Add"
          class="ml-2 cursor-pointer"
          v-if="!reviewing && enabled"
          @click="childList.nodes.push({ text: '', nodes: [] })"
        />
      </div>
      <div
        class="tree-menu"
        v-if="childList.nodes.length"
        v-for="(node, i1) in childList.nodes"
      >
        <div class="row">
          <input
            type="text"
            v-model="node.text"
            class="form-control"
            style="width: 200px"
            :disabled="!enabled"
          />

          <img
            src="/images/add-icon.svg"
            alt="Add"
            title="Add"
            class="ml-2 cursor-pointer"
            v-if="!reviewing && enabled"
            @click="childList.nodes[i1].nodes.push({ text: '', nodes: [] })"
          />
          <img
            src="/images/remove.svg"
            alt="Remove"
            title="Remove"
            width="13"
            class="ml-2 cursor-pointer"
            v-if="!reviewing && enabled"
            @click="childList.nodes.splice(i1, 1)"
          />
        </div>
        <div
          class="tree-menu"
          v-if="typeof node.nodes !== 'undefined' && node.nodes.length"
          v-for="(node2, i2) in node.nodes"
        >
          <div class="row">
            <input
              type="text"
              v-model="node2.text"
              class="form-control"
              style="width: 200px"
              :disabled="!enabled"
            />

            <img
              src="/images/add-icon.svg"
              alt="Add"
              title="Add"
              class="ml-2 cursor-pointer"
              v-if="!reviewing && enabled"
              @click="
                childList.nodes[i1].nodes[i2].nodes.push({
                  text: '',
                  nodes: []
                })
              "
            />
            <img
              src="/images/remove.svg"
              alt="Remove"
              title="Remove"
              width="13"
              class="ml-2 cursor-pointer"
              v-if="!reviewing && enabled"
              @click="childList.nodes[i1].nodes.splice(i2, 1)"
            />
          </div>
          <div
            class="tree-menu"
            v-if="typeof node2.nodes !== 'undefined' && node2.nodes.length"
            v-for="(node3, i3) in node2.nodes"
          >
            <div class="row">
              <input
                type="text"
                v-model="node3.text"
                class="form-control"
                style="width: 200px"
                :disabled="!enabled"
              />

              <img
                src="/images/add-icon.svg"
                alt="Add"
                title="Add"
                class="ml-2 cursor-pointer"
                v-if="!reviewing && enabled"
                @click="
                  childList.nodes[i1].nodes[i2].nodes[i3].nodes.push({
                    text: '',
                    nodes: []
                  })
                "
              />
              <img
                src="/images/remove.svg"
                alt="Remove"
                title="Remove"
                width="13"
                class="ml-2 cursor-pointer"
                v-if="!reviewing && enabled"
                @click="childList.nodes[i1].nodes[i2].nodes.splice(i3, 1)"
              />
            </div>
            <div
              class="tree-menu"
              v-if="typeof node3.nodes !== 'undefined' && node3.nodes.length"
              v-for="(node4, i4) in node3.nodes"
            >
              <div class="row">
                <input
                  type="text"
                  v-model="node4.text"
                  class="form-control"
                  style="width: 200px"
                  :disabled="!enabled"
                />

                <img
                  src="/images/add-icon.svg"
                  alt="Add"
                  title="Add"
                  class="ml-2 cursor-pointer"
                  v-if="!reviewing && enabled"
                  @click="
                    childList.nodes[i1].nodes[i2].nodes[i3].nodes[
                      i4
                    ].nodes.push({ text: '', nodes: [] })
                  "
                />
                <img
                  src="/images/remove.svg"
                  alt="Remove"
                  title="Remove"
                  width="13"
                  class="ml-2 cursor-pointer"
                  v-if="!reviewing && enabled"
                  @click="
                    childList.nodes[i1].nodes[i2].nodes[i3].nodes.splice(i4, 1)
                  "
                />
              </div>
              <div
                class="tree-menu"
                v-if="typeof node4.nodes !== 'undefined' && node4.nodes.length"
                v-for="(node5, i5) in node4.nodes"
              >
                <div class="row">
                  <input
                    type="text"
                    v-model="node5.text"
                    class="form-control"
                    style="width: 200px"
                    :disabled="!enabled"
                  />

                  <img
                    src="/images/add-icon.svg"
                    alt="Add"
                    title="Add"
                    class="ml-2 cursor-pointer"
                    v-if="!reviewing && enabled"
                    @click="
                      childList.nodes[i1].nodes[i2].nodes[i3].nodes[i4].nodes[
                        i5
                      ].nodes.push({ text: '', nodes: [] })
                    "
                  />
                  <img
                    src="/images/remove.svg"
                    alt="Remove"
                    title="Remove"
                    width="13"
                    class="ml-2 cursor-pointer"
                    v-if="!reviewing && enabled"
                    @click="
                      childList.nodes[i1].nodes[i2].nodes[i3].nodes[
                        i4
                      ].nodes.splice(i5, 1)
                    "
                  />
                </div>
                <div
                  class="tree-menu"
                  v-if="
                    typeof node5.nodes !== 'undefined' && node5.nodes.length
                  "
                  v-for="(node6, i6) in node5.nodes"
                >
                  <div class="row">
                    <input
                      type="text"
                      v-model="node6.text"
                      class="form-control"
                      style="width: 200px"
                      :disabled="!enabled"
                    />

                    <img
                      v-if="0"
                      src="/images/add-icon.svg"
                      alt="Add"
                      title="Add"
                      class="ml-2 cursor-pointer"
                      @click="
                        childList.nodes[i1].nodes[i2].nodes[i3].nodes[i4].nodes[
                          i5
                        ].nodes[i6].nodes.push({ text: '', nodes: [] })
                      "
                    />
                    <img
                      src="/images/remove.svg"
                      alt="Remove"
                      title="Remove"
                      width="13"
                      class="ml-2 cursor-pointer"
                      v-if="!reviewing && enabled"
                      @click="
                        childList.nodes[i1].nodes[i2].nodes[i3].nodes[i4].nodes[
                          i5
                        ].nodes.splice(i6, 1)
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="!reviewing && !filling"
      class="moving-tool row position-absolute"
    >
      <span
        class="material-icons cursor-pointer col-12 moving-up"
        @click="moveUp($event, referenceX, referenceY)"
      >
        keyboard_arrow_up
      </span>
      <span
        class="material-icons cursor-pointer col-12 moving-down"
        @click="moveDown($event, referenceX, referenceY)"
      >
        keyboard_arrow_down
      </span>
    </div>

    <img
      src="/images/menu.svg"
      class="menu"
      @click="menuOpen = !menuOpen"
      v-if="!filling && !reviewing"
    />
    <div class="menu-body" v-if="menuOpen" v-on:clickout="menuOpen = false">
      <div
        class="cursor-pointer col-12 row m-0 p-0 mb-1"
        @click="confirmRemove"
      >
        <p class="m-0 text-danger">
          <img src="/images/remove.svg" class="remove p-0" />
          Remove
        </p>
      </div>
      <hr class="mt-2 mb-2" />
      <div
        class="cursor-pointer col-12 row m-0 p-0 mb-1"
        @click="noting = !noting"
      >
        <p class="m-0">
          <img src="/images/notes.svg" class="notes-img p-0" />
          Notes
        </p>
      </div>
      <textarea
        type="text"
        v-model="notes"
        class="notes-text form-control-sm"
        :class="{ hidden: !noting }"
      ></textarea>

      <div class="col-12 row m-0 p-0 mb-1">
        <small>Width</small>
        <select v-model="width" class="width form-control-sm col-12">
          <option value="col-3">25%</option>
          <option value="col-4">33.3%</option>
          <option value="col-6">50%</option>
          <option value="col-8">66.6%</option>
          <option value="col-12">100%</option>
        </select>
      </div>
      <div class="col-12 row m-0 p-0 mb-1">
        <small>Enabled</small>
        <select v-model="enabled" class="height form-control-sm col-12">
          <option value="true">Enabled</option>
          <option value="false">Disabled</option>
        </select>
      </div>
      <div class="col-12 row m-0 p-0 mb-1">
        <small>Required</small>
        <select v-model="required" class="height form-control-sm col-12">
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </div>
      <div class="col-12 row m-0 p-0 mb-1">
        <small>Website View</small>
        <select v-model="website_view" class="height form-control-sm col-12">
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TreeElement",
  watch: {
    showRequiredNotify: function() {
      if (this.showRequiredNotify && this.required && !this.childList.text) {
        if (this.$el.closest(".e-item") != null) {
          let tabIndex = this.$el
            .closest(".e-item")
            .id.replace("e-content-TabInstance_", "");
          document
            .querySelectorAll(".nav-index")
            [parseInt(tabIndex) - 1].classList.add("bg-danger");
        }
      }
    }
  },
  data() {
    return {
      showRequiredNotify: false,
      removed: false,
      type: "tree",
      enabled: true,
      required: false,
      website_view: true,
      label: "Title here ..",
      width: "col-4",
      height: "auto",
      notes: "",
      childList: {
        text: "",
        nodes: []
      },
      noting: false,
      menuOpen: false,

      reviewing: false,
      commenting: false,
      review: {
        review: false,
        comment: ""
      },
      filling: false,
      fill: "",
      fillNotes: false,

      id: 0,

      referenceX: 0,
      referenceY: 0
    };
  },
  created: function() {
    let self = this;
    window.addEventListener("click", function(e) {
      // close dropdown when clicked outside
      if (!self.$el.contains(e.target)) {
        self.noting = false;
        self.menuOpen = false;
      }
    });
  },
  methods: {
    moveUp(e, x, y) {},
    moveDown(e, x, y) {},
    confirmRemove() {
      this.removed = confirm("Are you sure ?");
    }
  }
};
</script>

<style scoped></style>
