<template>
  <div>
    <p><b>{{ $i18n.t('Notes') }}:</b> {{ action.comment }}</p>
    <p><b>{{ $i18n.t('Duration') }}:</b> {{ action.estmaited_time }}</p>
    <p v-if="date"><b>{{ $i18n.t('Date') }}:</b> {{ date }}</p>
    <p v-if="date"><b>{{ $i18n.t('Meeting Link') }}:</b> <a target="_blank" :href="action.link">{{
        $i18n.t('Click here')
      }}</a></p>
    <!-- <div v-else>
      <label v-for="date_option in action.dates" :key="date_option.id" class="col-12">
        <input type="radio" name="date" :value="date_option.id" v-model="approveDateId">
        {{ date_option.date }}
      </label>
      <button type="button" class="btn btn-primary btn-sm" @click="saveApprovedDate">{{ $i18n.t('Approve') }}</button>
    </div> -->
  </div>
</template>
<script>

export default {
  name: 'Interview',
  props: ['action'],
  data() {
    return {
      loading: false,
      approveDateId: 0,
    }
  },
  created() {
    this.approveDateId = this.action.dates[0].id;
  },
  computed: {
    date() {
      return this.action.dates.map(x => {
        if (x.status === 'approved') return x.date;
        return '';
      }).join('');
    }
  },
  methods: {
    saveApprovedDate() {
      this.loading = true;
      axios.post('website/user/interview/approveDate', {
        id: this.approveDateId,
        interview_id: this.action.id
      })
          .then(response => {
            this.$router.go();
            this.$notify({
              group: 'notify',
              type: 'success',
              title: this.$i18n.t('Success'),
              text: this.$i18n.t('Done successfully')
            });
          })
          .catch(() => {
            this.loading = false;
            this.$notify({
              group: 'notify',
              type: 'error',
              title: this.$i18n.t('Error'),
              text: this.$i18n.t('Something go wrong')
            });
          })
    }
  }
}
</script>