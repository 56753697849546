<template>
  <div>
    <div class="details" v-if="details">
      <h5>Quiz Details</h5>
      <ul>
        <li> Location : {{ details.location }}</li>
        <li> Date : {{ details.date }}</li>
        <li> Type : {{ details.type }}</li>
      </ul>
      <p v-if="date"><b>{{ $i18n.t('Date') }}:</b> {{ date }}</p>
      <div v-else>
        <label v-for="date_option in details.dates" :key="date_option.id" class="col-12">
          <input type="radio" name="date" :value="date_option.id" v-model="approveDateId">
          {{ date_option.schedule_date }}
        </label>
        <button type="button" class="btn btn-primary btn-sm" @click="saveApprovedDate">{{ $i18n.t('Approve') }}</button>
      </div>
      <br>
    </div>
    <button v-if="date"
            type="button" class="btn btn-main col-4" @click="start">
      <div class="row">
        <div class="col-4 text-left">
        <span class="spinner-border " role="status" :style="'display: '+(loading ? 'block' : 'none')">
          <span class="sr-only">{{ $i18n.t('Loading') }}</span>
        </span>
        </div>
        <div class="col d-block p-1 text-left">
          <span>{{ $i18n.t('Open Quiz') }}</span>
        </div>
      </div>
    </button>
  </div>
</template>
<script>

export default {
  name: 'Quiz',
  props: ['action', 'details'],
  data() {
    return {
      loading: false,
      status: false,
      quiz: {},
      approveDateId: '',
    }
  },
  created() {
    this.start(false);
  },
  computed: {
    date() {
      return this.details.dates.map(x => {
        if (x.status === 'approved') {
          return x.schedule_date;
        }
        return '';
      }).join('');
    }
  },
  methods: {
    saveApprovedDate() {
      this.loading = true;
      axios.post('website/user/quiz/approveDate', {
        id: this.approveDateId,
        detail_id: this.details.id
      })
          .then(response => {
            this.$router.go();
            this.$notify({
              group: 'notify',
              type: 'success',
              title: this.$i18n.t('Success'),
              text: this.$i18n.t('Done successfully')
            });
          })
          .catch(() => {
            this.loading = false;
            this.$notify({
              group: 'notify',
              type: 'error',
              title: this.$i18n.t('Error'),
              text: this.$i18n.t('Something go wrong')
            });
          })
    },
    start(open = true) {
      if (this.date) {
        this.loading = true;
        axios.get('website/user/quizstart/' + this.action.id)
            .then(response => {
              if (open && Date.parse(this.date) <= Date.now()) {
                this.$router.push('/quiz?id=' + this.action.id);
                return;
              }
              if (Date.parse(this.date) >= Date.now()) {
                alert("Need to wait the quiz date")
              }
              if (response.data.data.status === 'Quizzing' && response.data.data.remained > 0 && Date.parse(this.date) <= Date.now()) {
                this.status = true;
                if (open)
                  this.$router.push('/quiz?id=' + this.action.id)
              } else {
                this.status = false;
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            })
      }else {
        if (open)
        axios.get('website/user/quiz-confirm/' + this.details.id)
            .then(response => {
              this.details.confirmation = true;
              if (Date.parse(this.date) <= Date.now()) {
                this.$router.push('/quiz?id=' + this.action.id);
                return;
              }

              if (response.data.data.status === 'Quizzing' && response.data.data.remained > 0) {
                this.status = true;
                if (open)
                  this.$router.push('/quiz?id=' + this.action.id)
              } else {
                this.status = false;
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            })
      }
    }
  }
}
</script>
