<template>
  <div v-if="!removed" :style="{'height': height}" class="element float-left"
       :class="{
        'col-3': width==='col-3','col-4': width==='col-4','col-6': width==='col-6','col-8': width==='col-8','col-12': width==='col-12',
    }">
    <div v-if="!reviewing&&!filling" class="col-12" style="border-bottom: 2px dashed #233261;margin-top: 20px;"></div>
    <div v-if="!reviewing&&!filling" class="moving-tool row position-absolute">
      <span class="material-icons cursor-pointer col-12 moving-up"
            @click="moveUp($event, referenceX, referenceY)">
        keyboard_arrow_up
      </span>
      <span class="material-icons cursor-pointer col-12 moving-down"
            @click="moveDown($event, referenceX, referenceY)">
        keyboard_arrow_down
      </span>
    </div>

    <img src="/images/menu.svg" class="menu" @click="menuOpen = !menuOpen" v-if="!reviewing&&!filling">
    <div class="menu-body" v-if="menuOpen" v-on:clickout="menuOpen=false">
      <div class="cursor-pointer col-12 row m-0 p-0 mb-1" @click="confirmRemove">
        <p class="m-0 text-danger">
          <img src="/images/remove.svg" class="remove p-0">
          Remove
        </p>
      </div>
      <hr class="mt-2 mb-2">
      <div class="col-12 row m-0 p-0 mb-1">
        <small>Height</small>
        <select v-model="height" class="height form-control-sm col-12">
          <option value="1px">Fit</option>
          <option value="10px">Small</option>
          <option value="30px">Medium</option>
          <option value="50px">Large</option>
        </select>
      </div>
      <div class="col-12 row m-0 p-0 mb-1">
        <small>Website View</small>
        <select v-model="website_view" class="height form-control-sm col-12">
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LineElement',
  data() {
    return {
      removed: false,
      type: 'line',
      enabled: true,
      required: false,
      website_view: true,
      label: 'Title here ..',
      width: 'col-12',
      height: 'auto',
      notes: '',
      childList: [],
      noting: false,
      menuOpen: false,

      reviewing: false,
      commenting: false,
      review: {
        review: false,
        comment: '',
      },
      filling: false,
      fill: '',
      fillNotes: false,

      id: 0,

      referenceX: 0,
      referenceY: 0,
    }
  },
  created: function () {
    let self = this;
    window.addEventListener('click', function (e) {
      // close dropdown when clicked outside
      if (!self.$el.contains(e.target)) {
        self.noting = false
        self.menuOpen = false
        self.commenting = false
      }
    })
  },
  methods: {
    moveUp(e, x, y) {

    },
    moveDown(e, x, y) {

    },
    confirmRemove() {
      this.removed = confirm('Are you sure ?')
    }
  }
}
</script>

<style scoped>

</style>
