<template>
  <div id="jobs">
    <Navbar></Navbar>
    <breadcrumb :links="links"></breadcrumb>
    <main class="page-wrapper single-job">
      <div class="container-fluid container-control">
        <div class="row pt-5">
          <div class="col-md-12">
            <div class="col-12 row">
              <h3 class="col ">{{ quiz.name }}</h3>

              <div class="float-right" v-if="status">
                <div class="timer digital-numbers">
                  <span class="minute">{{ minutes }}</span>
                  <span>:</span>
                  <span class="seconds">{{ seconds }}</span>
                </div>
              </div>
            </div>
            <div class="question form-group col-12" v-for="question in quiz.questions" :key="question.question">
              <label :for="question.question">{{ question.question }}</label>
              <div class="answer" v-if="question.type === 'text'">
                <textarea class="form-control" v-model="question.user_answer[0]" :disabled="!status"></textarea>
              </div>
              <div class="answer row" v-else-if="question.type === 'radio'">
                <div class="input-group col" v-for="answer in question.answers" :key="answer.answer">
                  <label :for="answer.answer">
                    <input type="radio" :name="question.question" v-model="question.user_answer[0]"
                           :value="answer.answer" :id="answer.answer" :disabled="!status"
                           :checked="question.user_answer.includes(answer.id)">
                    {{ answer.answer }}
                  </label>
                </div>
              </div>
              <div class="answer row" v-else-if="question.type === 'checkbox'">
                <div class="input-group col" v-for="(answer, i) in question.answers" :key="answer.answer">
                  <label :for="answer.answer">
                    <input type="checkbox" :name="question.question"
                           @click="handleCheckbox($event, question, i, answer.id)" :value="answer.answer"
                           :id="answer.answer" :disabled="!status" :checked="question.user_answer.includes(answer.id)">
                    {{ answer.answer }}
                  </label>
                </div>
              </div>
            </div>

            <button type="button" class="btn btn-success" @click="submit" v-if="status">{{ $i18n.t('Submit') }}</button>
          </div>
        </div>
      </div>
    </main>
    <Footer></Footer>
  </div>
</template>
<style>
@font-face {
  font-family: 'digital-7';
  font-weight: normal;
  font-style: normal;

  src: url('/css/fonts/digital-7.regular.ttf');
}

.digital-numbers {
  font-family: 'digital-7', sans-serif !important;
  font-size: 50px;
}
</style>
<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  name: 'Quiz',
  components: {Breadcrumb, Footer, Navbar},
  data() {
    return {
      links: [
        {
          key: 'My Profile',
          value: "profile",
        },
        {
          key: 'Applications',
          value: "applications",
        },
        {
          key: 'Quiz',
          value: "quiz?id=6",
        }
      ],
      quiz: {},

      timer: null,
      totalTime: 0,
      status: true,
    }
  },
  created() {
    this.check();
    // load quiz
    axios.get('website/user/quiz/' + this.$router.currentRoute.query.id)
        .then(response => {
          let quiz = response.data.data.data;
          quiz.questions.map((question, i) => {
            if (!response.data.data.solve) response.data.data.solve = [];
            quiz.questions[i].user_answer = response.data.data.solve.map(x => {
              if (x.question.id === question.id) return x.answer_id;
              return null;
            });
            if (question.type === 'text')
              quiz.questions[i].user_answer[0] = quiz.questions[i].user_answer.join('');
          })
          this.quiz = quiz;
        })
        .catch((error) => {
          // console.log(error)
          //this.$router.push('/404')
        });
  },
  mounted() {
    document.querySelector(".preloader").style.display = "none";
    document.body.style.overflowY = 'auto';
  },
  computed: {
    minutes() {
      const minutes = Math.floor(this.totalTime / 60);
      return this.padTime(minutes);
    },
    seconds() {
      const seconds = this.totalTime - (this.minutes * 60);
      if (seconds == 0 && this.minutes == 0) {
        this.stopTimer();
        if (this.status)
          this.submit();
        return this.padTime(0);
      }
      return this.padTime(seconds);
    },
  },
  methods: {
    submit(end = true) {
      // submit quiz
      if (this.quiz.id)
        axios.post('website/user/answerQuiz', {
          quiz_id: this.quiz.id,
          action_id: this.$router.currentRoute.query.id,
          questions: this.quiz.questions
        })
            .then(response => {
              if (end) {
                this.$router.back();
              }
              this.check();
            })
            .catch(() => {

            })
    },
    check() {
      this.loading = true;
      axios.get('website/user/quizstart/' + this.$router.currentRoute.query.id)
          .then(response => {
            if (response.data.data.status === 'Quizzing' && response.data.data.remained > 0) {
              this.resetTimer(response.data.data.remained);
              this.startTimer();
            } else {
              //this.$router.push('/404')
              this.status = false;
            }
            this.loading = false;
          })
          .catch(() => {
            this.$router.push('/404')
          })
    },
    handleCheckbox(e, question, i, answer_id) {
      if (e.target.checked) {
        question.user_answer[i] = answer_id;
      } else {
        question.user_answer[i] = 0;
      }
    },
    // timer
    startTimer() {
      this.timer = setInterval(() => this.countdown(), 1000); //1000ms = 1 second
    },
    stopTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
    resetTimer(time = 1) {
      this.totalTime = time;
      this.stopTimer();
    },
    padTime(time) {
      return (time < 10 ? '0' : '') + time;
    },
    countdown() {
      this.totalTime--;
    }
  }
}
</script>

