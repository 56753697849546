import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        status: '',
        token: localStorage.getItem('token') || '',
        user: localStorage.getItem('user') || '{}',
        locale: localStorage.getItem('locale') || 'ar'
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, token, user) {
            state.status = 'success'
            state.token = token
            state.user = user
        },
        auth_user_refresh(state, user) {
            state.user = JSON.stringify(user)
        },
        auth_error(state) {
            state.status = 'error'
        },
        logout(state) {
            state.status = ''
            state.token = ''
            state.user = {}
        },
        setLocale(state, val) {
            state.location = val;
        }
    },
    actions: {
        login({commit}, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios({url: 'website/auth/login', data: user, method: 'POST'})
                    .then(resp => {
                        const token = resp.data.access_token
                        const user = JSON.stringify(resp.data.user)
                        localStorage.setItem('token', 'Bearer ' + token)
                        localStorage.setItem('user', user)
                        // Add the following line:
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
                        commit('auth_success', token, user)
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('auth_error')
                        localStorage.removeItem('token')
                        localStorage.removeItem('user')
                        reject(err)
                    })
            })
        },
        logout({commit}) {
            return new Promise((resolve, reject) => {
                commit('logout')
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                delete axios.defaults.headers.common['Authorization']
                resolve()
            })
        },
        refresh_user({commit}, user = null) {
            // console.log(user)
            if (!user) {
                axios({url: 'website/auth/user', method: 'GET'})
                    .then(resp => {
                        const user = resp.data.data.user;
                        localStorage.setItem('user', JSON.stringify(user))
                        commit('auth_user_refresh', user)
                    })
                    .catch(err => {
                        commit('auth_error')
                        localStorage.removeItem('token')
                        localStorage.removeItem('user')
                    })
            } else {
                localStorage.setItem('user', JSON.stringify(user))
                commit('auth_user_refresh', user)
            }
        },
        set_locale({commit}, locale) {
            localStorage.setItem('locale', locale);
            commit('setLocale', locale);
        }
    },
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
        user: state => state.user !== null ? JSON.parse(state.user) : {},
        locale: state => state.locale
    }
})