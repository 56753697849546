<template>
  <!-- Footer -->
  <div class="footer-cont">
    <footer class="footer d-flex" id="footer">
      <div class="footer-logo">
        <img
          width="250"
          height="60"
          src="../../public/images/footer-logo.png"
          alt="logo"
        />
      </div>
      <div class="copy-right">
        <!-- {{ settings.app.name }} &copy; {{ new Date().getFullYear() }}, -->
        <span>
          {{ $i18n.t("All copyrights reserved") }}
        </span>

        <span>
          {{ $i18n.t("For Wakeb") }}
        </span>
        <span> &copy; {{ new Date().getFullYear() }} </span>
      </div>
    </footer>
  </div>
  <!--  <div class="footer-cont">-->
  <!--    <router-link to="/" class="home-btn"></router-link>-->
  <!--    <footer class="footer" id="footer">-->
  <!--      <div class="footer-logo">-->
  <!--        &lt;!&ndash; <img src="../../public/images/logo_white.png" alt="logo" /> &ndash;&gt;-->
  <!--        <img src="../../public/images/footer-logo.png" alt="logo" />-->
  <!--      </div>-->
  <!--      <div class="copy-right">-->
  <!--        {{ settings.app.name }} &copy; {{ new Date().getFullYear() }},-->
  <!--        {{ $i18n.t("All copyrights reserved") }}-->
  <!--      </div>-->
  <!--      <div class="bg-bottom"></div>-->
  <!--    </footer>-->
  <!--  </div>-->
  <!-- /Footer -->
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      themeDir: "ltr",
      settings: {
        app: {
          name: "",
          logo: "",
          TWITTER: "",
          LINKEDIN: "",
          WHATSAPP: "",
          INSTAGRAM: "",
          FACEBOOK: "",
        },
      },
    };
  },
  created() {
    // load settings
    this.loadSettings();
  },
  methods: {
    loadSettings() {
      axios
        .get("feed/settings")
        .then((response) => {
          if (typeof response.data.data !== "undefined")
            this.settings = response.data.data.settings;
        })
        .catch((error) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-cont {
  position: relative;
  .copy-right {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-weight: bold;
  }
}
</style>
