<script>
export default {
  name: "JobsComponent",
  props: ["jobs"],
  methods: {
    goPosition(id) {
      console.log(id);
      this.$router.push(`/jobs/apply?id=${id}`);
    },
    ConvertToArabicNumbers(num) {
      const arabicNumbers =
        "\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669";
      return new String(num).replace(/[0123456789]/g, (d) => {
        return arabicNumbers[d];
      });
    },
  },
};
</script>

<template>
  <div>
    <div class="latest-job-card" v-for="(job, key) in jobs" v-bind:key="key">
      <div class="row">
        <div class="col-12">
          <h4 class="mb-2 title">{{ job.title }}</h4>
          <p
            v-if="job.region"
            class="compay d-flex gap-3 align-center text-left mb-0  mt-2"
          >
            <span class=" d-flex gap-1 align-center">
              <img src="@/assets/map.svg" alt="" width="22" height="22" />
              <span>
                {{
                  $i18n.locale === "ar" ? job.region.name_ar : job.region.name
                }}
              </span>
            </span>
            <span class="mx-3 d-flex gap-1 align-center">
              <img src="@/assets/calendar.svg" alt="" width="22" height="22" />
              <span>
                {{
                  job.create_dates.created_at_human
                    .toString()
                    .toLowerCase()
                    .split(" ")[1] == "months"
                    ? $i18n.t("months_ago", {
                        month: ConvertToArabicNumbers(
                          job.create_dates.created_at_human
                            .toString()
                            .toLowerCase()
                            .split(" ")[0]
                        ),
                      })
                    : job.create_dates.created_at_human
                        .toString()
                        .toLowerCase()
                        .split(" ")[1] == "year"
                    ? $i18n.t("years_ago", {
                        year: ConvertToArabicNumbers(
                          job.create_dates.created_at_human
                            .toString()
                            .toLowerCase()
                            .split(" ")[0]
                        ),
                      })
                    : ""
                }}
              </span>
            </span>
            <span v-if="0" class="text-muted">company location</span>
          </p>
        </div>
        <div class="col-12">
          <p class="id text-left mb-0 mt-2" v-if="0">
            {{ job.form.job_level }}
          </p>
          <p class="mb-0">
            {{
              job.short_description ? job.short_description : $i18n.t("no_desc")
            }}
          </p>
          <button class="btn-show_details mt-1" @click="goPosition(job.id)">
            <img src="@/assets/document.svg" alt="" width="22" height="22" />
            <span class="mx-1">
              {{ $i18n.t("Show details") }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.btn-show_details {
  background: var(--main-color) !important;
  color: #fff;
  font-weight: bold;
  min-width: 180px;
  min-height: 45px;
  border-radius: 12px;
  border: none;
}
h4 {
  color: #1b2124 !important;
  text-transform: capitalize !important;
}
.compay span {
  color: #515151 !important;
  font-weight: 500;
  text-transform: capitalize;
}
</style>
