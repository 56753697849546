<template>
  <div id="jobs">
    <Navbar></Navbar>
    <!-- <breadcrumb :links="links"></breadcrumb> -->
    <main class="page-wrapper single-job">
      <div class="container container-control timeline">
        <div class="row custom-container mt-5">
          <div class="col-12">
            <div class="text-left mt-2">
              <div class="alert alert-info text-left d-inline-block m-0">
                <p class="p-0 m-0">
                  <!-- <strong>{{ $i18n.t("") }}: </strong> -->
                  <strong>Appliaction Id: </strong>
                  {{ application.id }}
                </p>
              </div>
            </div>
            <div
              class="alert alert-info"
              v-if="
                application.system_job &&
                  application.job.title !== application.system_job
              "
              role="alert"
            >
              {{ $i18n.t("System assigned you in") }}
              {{ application.system_job }}
            </div>
            <h3 class="section-head mb-2 mt-3">
              <span class="text">
                <p class="mr-1">{{ application.job.title }}</p>
                <!-- <p class="mr-1">{{ application.job.title }} »</p> -->
                <!-- <p>{{ $i18n.t("Timeline") }}</p> -->
              </span>
            </h3>
            <div class="card-body">
              <div
                v-if="currentStage && currentStage.name === 'Rejected'"
                class="alert alert-danger text-center"
              >
                {{ $i18n.t("Sorry, we moved forward with another candidate") }}
              </div>
              <ol v-else class="list-group vertical-steps">
                <!-- <li class="list-group-item completed user">
                  <span
                    class="cursor-pointer stage-container left"
                    @click="
                      action = { name: 'Applied at ' + application.applied_at };
                      $modal.show('ActionModal');
                    "
                  >
                    <span class="cursor"></span>
                    {{ $i18n.t("Applied at") }} {{ application.applied_at }}
                  </span>
                </li> -->

                <li
                  v-for="(stage, i) in defaultStages"
                  :key="'stage-' + i"
                  class="list-group-item admin "
                  :class="isCompleted(stage.order)"
                >
                  <div class="stage-container right">
                    <span class="cursor"></span>
                    <span class="cursor-pointer">
                      {{
                        stage.name === "Applied"
                          ? stage.name + " at " + application.applied_at
                          : stage.name
                      }}
                    </span>

                    <span v-for="(action, i) in stage.actions" :key="action.id">
                      <span
                        v-if="
                          typeof action.action !== 'undefined' &&
                            typeof action.action.name !== 'undefined'
                        "
                        class="cursor-pointer"
                        @click="
                          action.action.id = action.action.id;
                          type = action.type;
                          $data.action = action.action;
                          $data.actionDetails = action.details;
                          $modal.show('ActionModal');
                        "
                      >
                        »» {{ action.action.name }}
                      </span>
                    </span>
                  </div>
                </li>

                <li v-if="0" class="list-group-item completed admin">
                  <span>text</span>
                </li>
                <li v-if="0" class="list-group-item active user">
                  <span>text</span>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer></Footer>
    <action :action="action" :details="actionDetails" :type="type"></action>
  </div>
</template>
<style scoped>
.card-body {
  min-height: 300px;
}
</style>
<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import Breadcrumb from "@/components/Breadcrumb";
import action from "@/pages/user/partials/action";

export default {
  name: "Profile",
  components: { Breadcrumb, Footer, Navbar, action },
  data() {
    return {
      links: [
        {
          key: "My Profile",
          value: "profile",
        },
        {
          key: "Applications",
          value: "applications",
        },
        {
          key: "Application",
          value: "application",
        },
      ],
      action: {},
      stages: [],
      actionDetails: {},
      type: "",
      application: {
        job: {
          title: "",
        },
        applied_at: "",
        stagings: [],
      },
      defaultStages: [],
      currentStage: null,
    };
  },
  created() {
    // load application

    let id = parseInt(this.$router.currentRoute.query.id);
    if (id < 1) this.required.push("/404");

    // load default stages
    let loadDefaultStages = axios.get("website/workflows/active");

    // .then(res => {
    //   this.defaultStages = res.data.data.data;

    // })

    // load application
    // load stages
    let loadApplication = axios.get("website/user/applications/" + id);

    // .then((response) => {
    //   this.application = response.data.data.data;

    //   this.currentStage = {
    //     name: this.application.active_stage.workflow.name,
    //     id: this.application.active_stage.workflow.id,
    //     order: this.application.active_stage.workflow.order
    //   }
    //   document.querySelector(".preloader").style.display = "none";
    //   document.body.style.overflowY = "auto";
    // });

    Promise.all([loadDefaultStages, loadApplication])
      .then((values) => {
        this.application = values[1].data.data.data;

        // let unsortedStages = values[0].data.data.data.map(stage => {

        //   this.application.stagings.forEach(appStage => {
        //     stage.actions = [];
        //     stage.actions[stage.name] = [];
        //     if(appStage.workflow.id === stage.id){

        //       stage.actions[stage.name].push(...appStage.actions)
        //     }else{
        //       stage.actions[stage.name].push([]);
        //     }

        //   })

        //   return stage
        // });

        let unsortedStages = values[0].data.data.data.map((stage) => {
          stage.actions = [];
          this.application.stagings.forEach((appStage) => {
            if (appStage.workflow.id === stage.id) {
              stage.actions = appStage.actions;
            }
          });

          return stage;
        });

        this.defaultStages = unsortedStages.sort((a, b) => {
          return a.order - b.order;
        });

        this.currentStage = {
          name: this.application.applicantstage.name,
          id: this.application.applicantstage.id,
          order: this.application.applicantstage.order,
        };

        if (document.querySelector(".preloader")) {
          document.querySelector(".preloader").style.display = "none";
        }
        document.body.style.overflowY = "auto";
      })
      .catch((error) => {
        console.log(error);
      });
    //.catch(() => this.$router.push('/404'))
  },
  methods: {
    groupBy(objectArray) {
      let actions = [];
      objectArray.forEach((el) => {
        if (actions[el.workflow.name] && actions[el.workflow.name].length > 0) {
          actions[el.workflow.name].push(...el.actions);
        } else {
          actions[el.workflow.name] = [];
          actions[el.workflow.name].push(...el.actions);
        }
      });
      return actions;
    },
    isCompleted(stageOrder) {
      let currentClass = "active";
      // this.application.stages.forEach(stage => {
      //   if(stage.workflow_id === stageId){
      //     if(stage.status === 0){
      //       currentClass = 'completed';
      //     }
      //   }
      // })
      if (this.currentStage !== null && stageOrder < this.currentStage.order) {
        currentClass = "completed";
      }

      return currentClass;
    },
  },
};
</script>

<style></style>
