<template>
  <div class="page-breadcrumb">
    <div class="container-fluid container-control">
      <nav aria-label="breadcrumb" class="m-0 p-0">
        <ol class="breadcrumb m-0 d-flex align-items-center">
          <li class="breadcrumb-item">
            <router-link to="/">{{ $i18n.t('Home') }}</router-link>
          </li>
          <li class="breadcrumb-item active" v-for="(link,key) in links" :key="key">
            <router-link :to="link.value">{{ $i18n.t(link.key) }}</router-link>
          </li>
        </ol>
      </nav>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Breadcrumb',
  props:{
    links:{
      type:Array,
      required: true
    }
  }
}
</script>