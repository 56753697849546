var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"jobs"}},[_c('Navbar'),_c('main',{staticClass:"page-wrapper single-job"},[_c('div',{staticClass:"container container-control timeline"},[_c('div',{staticClass:"row custom-container mt-5"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"text-left mt-2"},[_c('div',{staticClass:"alert alert-info text-left d-inline-block m-0"},[_c('p',{staticClass:"p-0 m-0"},[_c('strong',[_vm._v("Appliaction Id: ")]),_vm._v(" "+_vm._s(_vm.application.id)+" ")])])]),(
              _vm.application.system_job &&
                _vm.application.job.title !== _vm.application.system_job
            )?_c('div',{staticClass:"alert alert-info",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.$i18n.t("System assigned you in"))+" "+_vm._s(_vm.application.system_job)+" ")]):_vm._e(),_c('h3',{staticClass:"section-head mb-2 mt-3"},[_c('span',{staticClass:"text"},[_c('p',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.application.job.title))])])]),_c('div',{staticClass:"card-body"},[(_vm.currentStage && _vm.currentStage.name === 'Rejected')?_c('div',{staticClass:"alert alert-danger text-center"},[_vm._v(" "+_vm._s(_vm.$i18n.t("Sorry, we moved forward with another candidate"))+" ")]):_c('ol',{staticClass:"list-group vertical-steps"},[_vm._l((_vm.defaultStages),function(stage,i){return _c('li',{key:'stage-' + i,staticClass:"list-group-item admin ",class:_vm.isCompleted(stage.order)},[_c('div',{staticClass:"stage-container right"},[_c('span',{staticClass:"cursor"}),_c('span',{staticClass:"cursor-pointer"},[_vm._v(" "+_vm._s(stage.name === "Applied" ? stage.name + " at " + _vm.application.applied_at : stage.name)+" ")]),_vm._l((stage.actions),function(action,i){return _c('span',{key:action.id},[(
                        typeof action.action !== 'undefined' &&
                          typeof action.action.name !== 'undefined'
                      )?_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){action.action.id = action.action.id;
                        _vm.type = action.type;
                        _vm.$data.action = action.action;
                        _vm.$data.actionDetails = action.details;
                        _vm.$modal.show('ActionModal');}}},[_vm._v(" »» "+_vm._s(action.action.name)+" ")]):_vm._e()])})],2)])}),(0)?_c('li',{staticClass:"list-group-item completed admin"},[_c('span',[_vm._v("text")])]):_vm._e(),(0)?_c('li',{staticClass:"list-group-item active user"},[_c('span',[_vm._v("text")])]):_vm._e()],2)])])])])]),_c('Footer'),_c('action',{attrs:{"action":_vm.action,"details":_vm.actionDetails,"type":_vm.type}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }