var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"jobs"}},[_c('Navbar'),_c('main',{staticClass:"page-wrapper single-job pt-5"},[_c('div',{staticClass:"container container-control"},[_c('div',{staticClass:"row pt-5 custom-container"},[_c('div',{staticClass:"col-md-12 h-full"},[_c('ul',{staticClass:"latest-jobs-container jobs-page-card m-0 p-0 h-auto"},[(_vm.applications.data.length)?_c('div',{attrs:{"jobs":_vm.applications.data}},_vm._l((_vm.applications.data),function(application,key){return _c('li',{key:key,staticClass:"latest-job-card"},[_c('router-link',{staticClass:"more",attrs:{"to":'/application?id=' + application.id}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('h4',{staticClass:"mb-1 title"},[_vm._v(" "+_vm._s(application.job ? application.job.title : application.job_request.title)+" ")]),_c('p',[_c('strong',[_vm._v("Appliaction Id: ")]),_vm._v(" "+_vm._s(application.id)+" ")])]),(
                          application.job.title !== application.system_job
                        )?_c('div',{staticClass:"alert alert-info",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.$i18n.t("System assigned you in"))+" "+_vm._s(application.system_job)+" ")]):_vm._e(),_c('p',{staticClass:"date text-left text-success mb-0"},[_vm._v(" "+_vm._s(application.job.create_dates ? application.job.create_dates.created_at_human : "")+" ")])]),_c('div',{staticClass:"col-12 "},[_c('p',{staticClass:"id text-left mb-0"},[_vm._v(" "+_vm._s(application.job_level)+" ")]),_c('p',{domProps:{"innerHTML":_vm._s(application.job.short_description)}})])])])],1)}),0):(_vm.loading)?_c('div',{staticClass:"col-12 mt-5"},[_c('div',{staticClass:"text-content d-flex align-items-center flex-column"},[_vm._m(0),_c('p',{staticClass:"mt-3 font-weight-bold",staticStyle:{"color":"#002e29"}},[_vm._v(" "+_vm._s(_vm.$i18n.t("please_wait"))+". ")])])]):_c('div',{staticClass:"d-flex justify-center align-center flex-column"},[_c('img',{staticClass:"mx-auto mb-3",attrs:{"src":require("@/assets/logo.png"),"alt":"GADD","width":"100"}}),_c('p',{staticClass:"mb-0 font-weight-bold text-center"},[_vm._v(" "+_vm._s(_vm.$i18n.t("No applications"))+" ")])])]),(_vm.applications.meta.lastPage)?_c('paginate',{attrs:{"page-count":_vm.applications.meta.lastPage,"click-handler":function (pageNum) {
                this$1.$router.push('?page=' + pageNum);
                this$1.getData(pageNum);
              },"prev-text":'<',"next-text":'>',"page-range":10,"margin-pages":0,"container-class":'pagination mt-3 mb-3',"page-class":'page-link cursor-pointer'},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)])])]),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-content-center align-items-center",staticStyle:{"width":"100px","height":"100px"}},[_c('div',{staticClass:"loader loading"})])}]

export { render, staticRenderFns }