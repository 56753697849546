<template>
  <form action="/jobs" method="get">
    <div class="filter-jobs">
      <!-- <h1 class="title">{{ $i18n.t("Jobs By Title") }}</h1> -->
      <div class="input-group input-group-lg search-container mb-1">
        <div class="input-group-prepend">
          <button
            class="btn dropdown-toggle region-dropdown-btn"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img width="24" height="24" src="@/assets/location.svg" alt="" />
            <span>
              {{ $i18n.t("regions") }}
            </span>
          </button>
          <div class="dropdown-menu region-dropdown">
            <span
              v-for="(name, id) in regions"
              :key="name"
              class="dropdown-item check-control"
            >
              <label
                :for="id"
                class="control control-checkbox d-flex justify-content-between align-items-center"
              >
                <input
                  type="checkbox"
                  name="regions[]"
                  :id="id"
                  v-model="selected_regions"
                  :value="id"
                />
                <div class="control-indicator"></div>
                {{ getRegionName(name) }}
              </label>
            </span>
          </div>
        </div>
        <input
          type="text"
          class="form-control rounded-0"
          id="inputGroup-sizing-lg"
          :placeholder="$i18n.t('search')"
          name="keyword"
          :value="$route.query.keyword"
        />
        <div class="input-group-append">
          <button
            type="submit"
            class="btn btn-search-filter"
            id="search-btn"
            aria-label="Search"
          >
            <i class="search-icon d-md-none" aria-hidden="true">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-search"
                viewBox="0 0 16 16"
                role="img"
                aria-label="Search icon"
              >
                <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                />
              </svg>
            </i>
            <span class="search-text d-none d-md-block">
              {{ $i18n.t("Search") }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";

export default {
  name: "JobFilter",
  components: {},
  data() {
    return {
      regions: [],
      selected_regions: [],
    };
  },
  methods: {
    getRegionName(name) {
      return this.$i18n.t(name);
    },
  },
  created() {
    // load departments
    /*axios.get('website/departments/get/all')
        .then(response => {
          this.departments = response.data.data.data;
          if (typeof this.$route.query['departments[]'] === 'undefined')
            this.departments.map(x => this.selected_departments.push(x.id));
          else {
            if (typeof this.$route.query['departments[]'] === 'string')
              this.selected_departments.push(this.$route.query['departments[]']);
            else
              this.selected_departments = this.$route.query['departments[]'];
          }
        });*/
    // load all regions
    axios.get("website/regions/get/all").then((response) => {
      if (typeof response.data.success != "undefined" && response.data.success)
        this.regions = response.data.data.data;

      if (typeof this.$route.query["regions[]"] === "undefined") {
        // this.regions.map((x) => this.selected_regions.push(x.id));
      } else {
        if (typeof this.$route.query["regions[]"] === "string")
          this.selected_regions.push(this.$route.query["regions[]"]);
        else this.selected_regions = this.$route.query["regions[]"];
      }
    });
  },
  mounted() {
    if (window.jQuery) {
      window.jQuery(".region-dropdown").on("click", (e) => e.stopPropagation());
    }
  },
};
</script>

<style></style>
