<template>
  <div id="jobs">
    <Navbar></Navbar>
    <!-- <breadcrumb :links="links"></breadcrumb> -->
    <main class="page-wrapper single-job pt-5">
      <div class="container container-control">
        <div class="row pt-5 custom-container">
          <div class="col-md-12 h-full">
            <ul class="latest-jobs-container jobs-page-card m-0 p-0 h-auto">
              <div v-if="applications.data.length" :jobs="applications.data">
                <li
                  class="latest-job-card"
                  v-for="(application, key) in applications.data"
                  v-bind:key="key"
                >
                  <router-link
                    :to="'/application?id=' + application.id"
                    class="more"
                  >
                    <div class="row">
                      <div class="col-12">
                        <div
                          class="d-flex justify-content-between align-items-center"
                        >
                          <h4 class="mb-1 title">
                            {{
                              application.job
                                ? application.job.title
                                : application.job_request.title
                            }}
                          </h4>
                          <p>
                            <!-- <strong>{{ $i18n.t("") }}: </strong> -->
                            <strong>Appliaction Id: </strong>
                            {{ application.id }}
                          </p>
                        </div>
                        <div
                          class="alert alert-info"
                          v-if="
                            application.job.title !== application.system_job
                          "
                          role="alert"
                        >
                          {{ $i18n.t("System assigned you in") }}
                          {{ application.system_job }}
                        </div>
                        <!-- <span class="section"></span> -->
                        <!--                        <p class="compay text-left mb-0">-->
                        <!--                          company name,-->
                        <!--                          <span class="text-muted">company location</span>-->
                        <!--                        </p>-->
                        <p class="date text-left text-success mb-0">
                          {{
                            application.job.create_dates
                              ? application.job.create_dates.created_at_human
                              : ""
                          }}
                        </p>
                      </div>
                      <div class="col-12 ">
                        <p class="id text-left mb-0">
                          {{ application.job_level }}
                        </p>

                        <p v-html="application.job.short_description"></p>
                      </div>
                    </div>
                  </router-link>
                </li>
              </div>
              <div class="col-12 mt-5" v-else-if="loading">
                <div class="text-content d-flex align-items-center flex-column">
                  <!-- <img
                    src="@/assets/loader.gif"
                    alt=""
                    width="100"
                    class="loading"
                  /> -->
                  <div
                    style="width: 100px; height: 100px;"
                    class="d-flex justify-content-center align-items-center"
                  >
                    <div class="loader loading"></div>
                  </div>
                  <p class="mt-3 font-weight-bold" style="color: #002e29">
                    {{ $i18n.t("please_wait") }}.
                  </p>
                </div>
              </div>
              <div
                class="d-flex justify-center align-center flex-column"
                v-else
              >
                <img
                  src="@/assets/logo.png"
                  alt="GADD"
                  class="mx-auto mb-3"
                  width="100"
                />
                <p class="mb-0 font-weight-bold text-center">
                  {{ $i18n.t("No applications") }}
                </p>
              </div>

              <!-- <div v-if="applications.data.length" :jobs="applications.data">
                <li
                  class="latest-job-card"
                  v-for="(application, key) in applications.data"
                  v-bind:key="key"
                >
                  <router-link
                    :to="'/application?id=' + application.id"
                    class="more"
                  >
                    <div class="row">
                      <div class="col-3">
                        <h4 class="mb-1 title">{{ application.job.title }}</h4>
                        <span class="section"></span>
                        <span class="id">{{ application.job_level }}</span>
                      </div>
                      <div class="col-7">
                        <p class="mb-0">{{ application.note }}</p>
                      </div>
                      <div
                        class="col-2 d-flex flex-column align-items-center date-more-container"
                      >
                        <span class="date mb-2">{{
                          application.assigned_at
                        }}</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                        >
                          <defs>
                            <clipPath id="clip-path2">
                              <rect width="16" height="16" fill="none" />
                            </clipPath>
                          </defs>
                          <g
                            id="Component_44_1"
                            data-name="Component 44 – 1"
                            transform="translate(16 16) rotate(180)"
                            clip-path="url(#clip-path2)"
                          >
                            <path
                              id="Path_10"
                              data-name="Path 10"
                              d="M8,0,6.545,1.455l5.506,5.506H0V9.039H12.052L6.545,14.545,8,16l8-8Z"
                              transform="translate(16 16) rotate(180)"
                              fill="#3a325e"
                            />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </router-link>
                </li>
              </div> -->
            </ul>
            <paginate
              v-if="applications.meta.lastPage"
              :page-count="applications.meta.lastPage"
              :click-handler="
                (pageNum) => {
                  this.$router.push('?page=' + pageNum);
                  this.getData(pageNum);
                }
              "
              :prev-text="'<'"
              :next-text="'>'"
              :page-range="10"
              :margin-pages="0"
              :container-class="'pagination mt-3 mb-3'"
              :page-class="'page-link cursor-pointer'"
              v-model="currentPage"
            >
            </paginate>
          </div>
        </div>
      </div>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import JobsComponent from "@/components/JobsComponent";

export default {
  name: "Applications",
  components: { JobsComponent, Footer, Navbar },
  data() {
    return {
      links: [
        {
          key: "My Profile",
          value: "profile",
        },
        {
          key: "Applications",
          value: "applications",
        },
      ],
      loading: false,
      applications: {
        data: [],
        meta: {},
      },
      limit: 10,
      field: "id",
      sort: "DESC",
      deleted: false,
      resource: "all",
      columns: [],
      operand: [],
      column_values: [],
      departments: [],
      currentPage: 1,
    };
  },
  created() {
    this.getData(
      parseInt(this.$route.query.page) ? parseInt(this.$route.query.page) : 1
    );
  },
  mounted() {
    const loader = document.querySelector(".preloader");
    if (loader) {
      loader.style.display = "none";
    }
    document.body.style.overflowY = "auto";
  },

  methods: {
    getData(offset = 1) {
      this.loading = true;

      if (offset === parseInt(this.applications.meta.currentPage)) {
        return;
      }

      axios
        .get("website/user/applications", {
          params: {
            limit: this.limit,
            offset: offset,
            field: this.field,
            sort: this.sort,
            deleted: this.deleted,
            resource: this.resource,
            columns: this.columns,
            operand: this.operand,
            column_values: this.column_values,
            departments: this.departments,
          },
        })
        .then((response) => {
          if (typeof response.data.data !== "undefined") {
            this.applications = response.data.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
div#jobs {
  background: #fff !important;
  display: flex;
  flex-direction: column;
  min-width: 100vh;
}
::v-deep .footer-cont {
  margin-top: auto;
}
</style>
