<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  name: "forgot",
  components: {
    Breadcrumb,
    Footer,
    Navbar,
  },

  data() {
    return {
      links: [
        {
          key: "Forgot Password",
          value: "/forgot",
        },
      ],
      password: "",
      password_confirmation: "",
      errors: [],
      loading: false,
    };
  },
  mounted() {
    // document.body.style.overflowY = "auto";
  },
  computed: {
    email() {
      return this.$router.currentRoute.query.email ?? "";
    },
  },
  methods: {
    reset() {
      this.errors = [];
      this.loading = true;

      axios
        .post("website/auth/password/reset", {
          token: this.$router.currentRoute.query._token ?? "",
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
        })
        .then(() => {
          this.$notify({
            group: "notify",
            type: "success",
            title: this.$i18n.t("Success"),
            text: this.$i18n.t("Your password has been changed Successfully."),
          });
          this.$router.push({ name: "login" });
        })
        .catch((error) => {
          if (error.response.data && error.response.data.errors)
            this.errors = error.response.data.errors;

          this.loading = false;
        });
    },
  },
};
</script>

<template>
  <div id="login">
    <main class="page-wrapper login-page">
      <div class="row">
        <div class="col-12 col-lg-6 right_side d-none d-xl-block d-lg-block">
          <img src="@/assets/bck_login.svg" alt="" />
          <div class="overlay_s">
            <img src="@/assets/gad.svg" alt="" id="gadd" />
            <img src="@/assets/log.svg" alt="" id="log" />
          </div>
        </div>
        <div class="col-lg-6 col-sm-12 mx-auto ">
          <div class="login">
            <div class="form-group mx-auto">
              <img src="@/assets/logo.svg" alt="GADD" class="mb-3" />
              <div class="login-heading mb-3">
                <h3>{{ $i18n.t("login.welcome") }}</h3>
                <p>{{ email }}</p>
              </div>

              <div class="form-group">
                <input
                  type="password"
                  class="form-control mb-4"
                  v-model="password"
                  :placeholder="$i18n.t('New Password')"
                />
                <i class="text-danger" v-if="errors.password">{{
                  errors.password[0]
                }}</i>
              </div>

              <div class="form-group">
                <input
                  type="password"
                  class="form-control mb-4"
                  v-model="password_confirmation"
                  :placeholder="$i18n.t('Confirm New Password')"
                />
                <i class="text-danger" v-if="errors.password_confirmation">{{
                  errors.password_confirmation[0]
                }}</i>
              </div>

              <button
                type="button"
                class="input-group-text btn btn-main d-block w-100 p-2"
                @click="reset"
                :disabled="loading"
              >
                <div class="row">
                  <div class="col-5 text-left">
                    <span
                      class="spinner-border "
                      role="status"
                      :style="'display: ' + (loading ? 'block' : 'none')"
                    >
                      <span class="sr-only">{{ $i18n.t("Loading") }}</span>
                    </span>
                  </div>
                  <div class="col d-block p-1 text-left">
                    {{ $i18n.t("Reset Password") }}
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div class="logining" v-if="isLogged">
      <img src="@/assets/gad.svg" alt="" class="logo mx-auto" />
      <div class="text-content d-flex flex-column">
        <img src="@/assets/rst.svg" alt="" class="logged" />
        <img src="@/assets/chk.svg" alt="" class="loading" />
        <!--        <p class="mt-3 font-weight-bold" style="color: #fff">{{ $i18n.t("please_wait") }}.</p>-->

        <router-link to="login" class="d-inline mb-3">
          <button class="btn btn-back">{{ $i18n.t("back_login") }}</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.text-sign_up {
  margin: 0 auto;
  font-weight: normal;
  color: #475467;
  text-align: center;
  a {
    font-weight: 600;
    &:hover {
      color: var(--main-color);
    }
  }
}
.right_side {
  max-height: 100vh;
  height: 100vh;
  position: relative;
  //background: url("../../assets/back_login.svg");
  //background-position: center;
  //background-repeat: no-repeat;
  //background-size: cover;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    //height: 100%;
  }
  .overlay_s {
    width: 100%;
    height: 100%;
    position: relative;
    //z-index: 3;
    img#gadd {
      max-width: 450px;
      margin: 0 auto;
      position: absolute;
      top: 5%;
      left: 50%;
      transform: translateX(-50%);
    }
    img#log {
      width: 70%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}
#login {
  height: 100vh;
}
.form-group {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}
.login-heading {
  h3 {
    color: #101828;
    font-weight: 700;
  }
  p {
    color: #667085 !important;
    font-weight: 500 !important;
  }
}
.login {
  padding: 10px;
  box-shadow: none;
  height: 100vh;
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: center;
  border: none;
  label {
    color: #344054;
  }
}

.text-mainColor {
  color: #295a28 !important;
}

.socialLogin .fb {
  background: #3b5998;
  padding: 2px 0;
  display: inline-block;
  transition: 0.3s;
}

.socialLogin .tw {
  background: #00aced;
  padding: 2px 0;
  display: inline-block;
  transition: 0.3s;
}

.socialLogin .tw:hover,
.socialLogin .fb:hover {
  transform: scale(1.08);
}

.login .form-control {
  height: 44px;
}

.login .btn-main:focus {
  background: #295a28;
  border-color: #295a28;
  color: #fff;
}

.login .text-colored {
  color: #00979e;
}

.login .text-colored:hover {
  color: #3a325e;
}

.login .text-mainColor {
  color: #333;
}

.login .text-mainColor:hover {
  color: #333;
}
</style>
