<script>
import VueRecaptcha from "vue-recaptcha";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  name: "forgot",
  components: {
    VueRecaptcha,
    Breadcrumb,
    Footer,
    Navbar,
  },
  data() {
    return {
      links: [
        {
          key: "Forgot Password",
          value: "/forgot",
        },
      ],
      email: "",
      error: false,
      loading: false,
      isLogged: false,
      sitekey: "6LdTrmMlAAAAAPoizJVl0U99syaFd7r3YX5r71Zd", // "6LdoES0mAAAAAEAIOWNjqR78KOrfnTPBWuC3Xvf_",
      recaptcha_verified: false,
      recaptcha: null,
    };
  },
  mounted() {
    const preLoaderElm = document.querySelector(".preloader");
    if (preLoaderElm) {
      preLoaderElm.style.display = "none";
    }
    document.body.style.overflowY = "auto";
  },
  methods: {
    onVerify: function(response) {
      this.recaptcha_verified = true;
      this.recaptcha = response;
    },
    onExpired: function() {
      this.recaptcha_verified = false;
    },
    resetRecaptcha() {
      this.recaptcha_verified = false;
      this.$refs.recaptcha.reset();
    },
    forgot() {
      // if (!this.recaptcha_verified) return;
      if (this.email.trim().length <= 5) return;

      this.error = false;
      this.loading = true;

      let email = this.email;

      axios
        .post("website/auth/password/email", {
          email,
          "g-recaptcha-response": this.recaptcha,
        })
        .then(() => {
          this.isLogged = true;
          this.$notify({
            group: "notify",
            type: "success",
            title: this.$i18n.t("Success"),
            text: this.$i18n.t(
              "Please check your mail to complete changing your password."
            ),
          });
          setTimeout(() => {
            this.$router.push({ name: "login" });
          }, 2000);
        })
        .catch(() => {
          this.error = true;
          this.loading = false;
          this.isLogged = true;
        });
    },
  },
};
</script>

<template>
  <div id="login">
    <main class="page-wrapper login-page">
      <div class="row">
        <div
          class="col-12 text-center alert-container"
          v-if="typeof $router.currentRoute.query.verify != 'undefined'"
        >
          <span class="alert alert-success">{{
            $i18n.t(
              "An email with the verification code was sent to your email"
            )
          }}</span>
        </div>
        <div
          class="col-12 text-center alert-container"
          v-else-if="typeof $router.currentRoute.query.verified != 'undefined'"
        >
          <span class="alert alert-success">{{
            $i18n.t("Your email was verified successfully")
          }}</span>
        </div>
        <div
          class="col-12 text-center alert-container"
          v-else-if="
            typeof $router.currentRoute.query.unverified != 'undefined'
          "
        >
          <span class="alert alert-danger">{{
            $i18n.t("Verified Failed")
          }}</span>
        </div>

        <div class="col-12 col-lg-6 right_side d-none d-xl-block d-lg-block">
          <img src="@/assets/bck_login.svg" alt="" />
          <div class="overlay_s">
            <img src="@/assets/logo-w.png" alt="" id="gadd" />
            <img src="@/assets/log.svg" alt="" id="log" />
          </div>
        </div>
        <div class="col-lg-6 col-sm-12 mx-auto ">
          <div class="login">
            <div v-if="0" class="socialLogin text-center mb-5">
              <h5 class="mb-3">{{ $i18n.t("Login with social media") }}</h5>
              <a href="#" class="fb mx-2" title="Facebook">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="33"
                  viewBox="0 0 21.369 23.192"
                >
                  <g data-name="Group 224" transform="translate(0)">
                    <path
                      data-name="Path 12"
                      d="M647.813,345.97v2.453h-1.772a1.093,1.093,0,0,0-1.094,1.092v2.055H647.7l-.357,2.829h-2.4v7.132h-2.923v-7.151h-2.406v-2.81h2.424V349.1a3.2,3.2,0,0,1,3.278-3.19Z"
                      transform="translate(-633.935 -342.335)"
                      fill="#fff"
                      class="path"
                    ></path>
                  </g>
                </svg>
              </a>
              <a href="#" class="tw" title="Twitter">
                <svg
                  data-name="Group 225"
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="33"
                  viewBox="0 0 21.715 23.193"
                  class="path"
                >
                  <g transform="translate(3.843 5.614)">
                    <path
                      data-name="Path 1564"
                      d="M15.157,7.633a6.027,6.027,0,0,1-1.724.473,3.017,3.017,0,0,0,1.319-1.661,6.01,6.01,0,0,1-1.907.728A3,3,0,0,0,7.734,9.91,8.521,8.521,0,0,1,1.549,6.774a3.005,3.005,0,0,0,.929,4.008,2.975,2.975,0,0,1-1.36-.375v.038a3,3,0,0,0,2.407,2.943,2.975,2.975,0,0,1-.79.1,2.937,2.937,0,0,1-.565-.054,3,3,0,0,0,2.8,2.083A6.006,6.006,0,0,1,1.246,16.8a6.271,6.271,0,0,1-.714-.04,8.492,8.492,0,0,0,4.6,1.346,8.479,8.479,0,0,0,8.538-8.535c0-.132,0-.261-.009-.391a6,6,0,0,0,1.5-1.552"
                      transform="translate(-0.531 -6.227)"
                      fill="#fff"
                      class="path"
                    ></path>
                  </g>
                </svg>
              </a>
            </div>
            <div class="form-group mx-auto">
              <div
                class="d-flex flex-column align-items-center justify-content-center"
              >
                <img src="@/assets/logo.png" alt="Wakeb" class="mb-3 logo-sm" />
                <div class="login-heading text-center mb-3">
                  <h3>{{ $i18n.t("login.welcome") }}</h3>
                  <p>{{ $i18n.t("login.sub_msg") }}</p>
                </div>
              </div>
              <label for="" class="font-weight-bold">{{
                $i18n.t("login.email")
              }}</label>
              <input
                type="email"
                class="form-control"
                v-model="email"
                :placeholder="$i18n.t('Email')"
              />
              <i class="text-danger" v-if="error">{{
                $i18n.t("Will send a reset email")
              }}</i>

              <!-- <vue-recaptcha ref="recaptcha" id="recaptcha" @verify="onVerify" @expired="onExpired" :sitekey="sitekey"
                :loadRecaptchaScript="true">
              </vue-recaptcha> -->

              <button
                type="button"
                class="input-group-text btn btn-main d-block w-100 p-2 mt-3"
                :disabled="loading"
                @click="forgot"
              >
                <div class="row">
                  <div class="col-5 text-left">
                    <span
                      class="spinner-border "
                      role="status"
                      :style="'display: ' + (loading ? 'block' : 'none')"
                    >
                      <span class="sr-only">{{ $i18n.t("Loading") }}</span>
                    </span>
                  </div>
                  <div class="col d-block p-1 text-left font-weight-bold">
                    {{ $i18n.t("Send Reset Mail") }}
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div class="logining" v-if="isLogged">
      <img src="@/assets/logo-w.png" alt="" class="logo mx-auto" />
      <div class="text-content d-flex flex-column">
        <p class="reset-text">
          {{ $i18n.t("check your email") }}
        </p>
        <!-- <img src="@/assets/chk.svg" alt="" class="loading" /> -->
        <!--        <p class="mt-3 font-weight-bold" style="color: #fff">{{ $i18n.t("please_wait") }}.</p>-->

        <router-link to="login" class="d-inline mb-3">
          <button class="btn btn-back">{{ $i18n.t("back_login") }}</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.logining {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: url("../../assets/bck_login.svg");
  background-size: cover;

  .logo {
    max-width: 225px;
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
  }

  .text-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    gap: 10px;
    justify-content: center;
    align-items: center;

    .loading {
      margin-top: 20px;
      max-width: 500px;
      //animation: spin 1s alternate infinite;
    }

    .logged {
      max-width: 500px;
    }

    .btn-back {
      min-width: 280px;
      background: #fff;
      margin-top: 20px;
      font-weight: bold;
      color: var(--main-color);
      padding: 12px 15px;
    }
  }
}

.text-sign_up {
  margin: 0 auto;
  font-weight: normal;
  color: #475467;
  text-align: center;

  a {
    font-weight: 600;

    &:hover {
      color: var(--main-color);
    }
  }
}

.right_side {
  max-height: 100vh;
  height: 100vh;
  position: relative;
  //background: url("../../assets/bck_login.svg");
  //background-position: center;
  //background-repeat: no-repeat;
  //background-size: 100% 100%;
  overflow-y: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    //height: 100vh;
    //max-height: 100vh;
    //height: 100%;
  }

  .overlay_s {
    width: 100%;
    height: 100%;
    position: relative;

    //z-index: 3;
    img#gadd {
      max-width: 280px;
      margin: 0 auto;
      position: absolute;
      top: 10%;
      left: 50%;
      transform: translateX(-50%);
    }

    img#log {
      width: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}

#login {
  height: 100vh;
}

.form-group {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.login-heading {
  h3 {
    color: #101828;
    font-weight: 700;
  }

  p {
    color: #667085 !important;
    font-weight: 500 !important;
  }
}

.login {
  padding: 10px;
  box-shadow: none;
  height: 100vh;
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: center;
  border: none;

  label {
    color: #344054;
  }
}

.text-mainColor {
  color: #295a28 !important;
}

.socialLogin .fb {
  background: #3b5998;
  padding: 2px 0;
  display: inline-block;
  transition: 0.3s;
}

.socialLogin .tw {
  background: #00aced;
  padding: 2px 0;
  display: inline-block;
  transition: 0.3s;
}

.socialLogin .tw:hover,
.socialLogin .fb:hover {
  transform: scale(1.08);
}

.login .form-control {
  height: 44px;
}

.login .btn-main:focus {
  background: #295a28;
  border-color: #295a28;
  color: #fff;
}

.login .text-colored {
  color: #00979e;
}

.login .text-colored:hover {
  color: #3a325e;
}

.login .text-mainColor {
  color: #333;
}

.login .text-mainColor:hover {
  color: #333;
}
.reset-text {
  font-size: 28px;
  font-weight: bold;
  color: #fff;
}
</style>
