import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import store from "./store";
// import i18n from './i18n'

import { routes } from "@/routes";

import VModal from "vue-js-modal";

Vue.use(VModal);

Vue.config.productionTip = false;
Vue.use(VueRouter);
const router = new VueRouter({
  routes,
  mode: "history",
});

import Paginate from "vuejs-paginate";

Vue.component("paginate", Paginate);

const globals = {
  countries: [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Côte d'Ivoire",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo (Congo-Brazzaville)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czechia (Czech Republic)",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Holy See",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (formerly Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Korea",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ],
  grades: ["Good", "Very Good", "Excellent"],
  linkParser(text) {
    if (text != null)
      return text
        .replace(
          /(\b(https?|ftp|file):\/\/[\-A-Z0-9+&@#\/%?=~_|!:,.;]*[\-A-Z09+&@#\/%=~_|])/gim,
          `<a href='$1' target='_blank'>$1</a>`
        )
        .replace(/\n/g, "<br>");
    return "";
  },
};

//this is to help Webstorm with autocomplete
Vue.prototype.$globals = globals;

export default ({ app }, inject) => {
  inject("globals", globals);
};

// notification
import Notifications from "vue-notification";

Vue.use(Notifications);
// phone
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

Vue.component("vue-phone-number-input", VuePhoneNumberInput);
// calendar
import VCalendar from "v-calendar";
// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {});
// translations
import VueI18n from "vue-i18n";

Vue.use(VueI18n);
//axios
window.axios = require("axios");
axios
  .get(window.location.origin + "/config.json")
  .then((response) => {
    window.BASEURL = response.data.URL;
    window.ncms_url = response.data.ncms_url;
    window.gadd_url = response.data.gadd_url;
  })
  .then(async () => {
    axios.defaults.baseURL = BASEURL + "/api/";
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = BASEURL;
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common["Content-Type"] = "application/json";
    const locale = localStorage.getItem("locale");
    axios.defaults.headers.common["Accept-Language"] = locale || "ar";
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }

    // dynamic translations
    let en = await window.axios
      .get(window.location.origin + "/lang/en.json")
      .then((response) => {
        return response.data;
      });
    let ar = await window.axios
      .get(window.location.origin + "/lang/ar.json")
      .then((response) => {
        return response.data;
      });

    let i18n = new VueI18n({
      locale: "en",
      fallbackLocale: "en",
      messages: {
        en: en,
        ar: ar,
      },
    });

    new Vue({
      i18n,
      router,
      store,
      render: (h) => h(App),
      created() {
        this.$i18n.locale = this.$store.state.locale;
      },
    }).$mount("#app");
  });
