<template>
  <div id="app">
    <router-view
      v-if="
        settings.app.website == 'true' ||
          $router.currentRoute.name == 'underMaintenance'
      "
    ></router-view>
    <notifications group="notify" />
    <link
      rel="stylesheet"
      href="/css/style.rtl.css"
      v-if="$i18n.locale === 'ar'"
    />
  </div>
</template>
<script>
import notifications from "vue-notification";

export default {
  name: "App",
  data() {
    return {
      settings: {
        app: {
          career: false,
          website: false,
        },
      },
    };
  },
  created: function() {
    // check website status
    const lang = localStorage.getItem("locale") || "ar";
    axios
      .get("feed/settings")
      .then((response) => {
        if (typeof response.data.data.settings.app.website !== "undefined") {
          this.settings = response.data.data.settings;
          if (
            response.data.data.settings.app.website != "true" &&
            this.$router.currentRoute.name != "underMaintenance"
          )
            this.$router.push({ name: "underMaintenance" });
          else if (
            response.data.data.settings.app.website == "true" &&
            this.$router.currentRoute.name == "underMaintenance"
          ) {
            this.$router.push("/");
          }
        }
      })
      .catch((error) => {});

    this.checkProfile();
    axios.interceptors.response.use(undefined, function(err) {
      return new Promise(function(resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch("logout");
        }
        throw err;
      });
    });
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  watch: {
    $route(to, from) {
      this.checkProfile();
      window.scrollTo(0, 0);
    },
  },
  methods: {
    checkProfile() {
      // check auth
      // check profile filled
      if (
        this.settings.app.website == "true" &&
        this.$router.currentRoute.name !== "profile" &&
        this.isLoggedIn &&
        !this.user.profileFilled
      ) {
        this.$router.push("/profile");
        return;
      }
    },
  },
};
</script>
