<template>
  <div class="container text-center">
    <img :src="settings.app.logo" alt="">
    <h5><b>{{ $i18n.t('Under Maintenance') }}</b> {{ $i18n.t('we will back soon') }}</h5>
  </div>
</template>
<style scoped>

</style>
<script>
export default {
  data() {
    return {
      settings: {
        app: {
          name: '',
          logo: '',
        }
      }
    }
  },
  created() {
    axios.get('feed/settings').then(response => {
      if (typeof response.data.data !== 'undefined')
        this.settings = response.data.data.settings
    }).catch(error => {

    })
  }
}
</script>