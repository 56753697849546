<template>
  <modal
      name="ActionModal"
      :width="'60%'"
      :height="'auto'"
      :scrollable="true"
      :focusTrap="true"
      :clickToClose="false"
      :draggable="false"
      :adaptive="true"
  >
    <form @submit.stop.prevent="create">
      <div class="model-header text-center font-weight-bold mt-5">
        <h3 class="section-head mb-2 mt-5">
          <span class="text">
            <p class="mr-1">{{ action.name }}</p>
          </span>
        </h3>
      </div>
      <div
        class="modal-body text-center"
        style="max-height: 400px;overflow-y: scroll;"
      >
        <span v-if="action.id">
          <span v-if="type === 'Quiz'">
            <quiz :action="action" :details="details"></quiz>
          </span>
          <span v-else-if="type === 'Interview'">
            <interview :action="action"></interview>
          </span>
        </span>
      </div>
      <div class="modal-footer border-top-0 pt-0">
        <div class="m-auto d-flex">
          <button
            class="cursor-pointer d-inline-block mt-2 px-5 mr-3 btn btn-secondary  text-white"
            type="button"
            @click="$modal.hide('ActionModal')"
          >
            {{ $i18n.t("Close") }}
          </button>
        </div>
      </div>
    </form>
  </modal>
</template>
<script>
import quiz from "@/pages/user/partials/quiz";
import Interview from "@/pages/user/partials/interview";

export default {
  name: "action",
  props: ["action", "type", "details"],
  components: { Interview, quiz },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style scoped></style>
