<template>
  <div
    v-if="!removed"
    :style="{ height: height }"
    class="element float-left"
    :class="{
      'col-3': width === 'col-3',
      'col-4': width === 'col-4',
      'col-6': width === 'col-6',
      'col-8': width === 'col-8',
      'col-12': width === 'col-12'
    }"
    @mouseover="fillNotes = true"
    @mouseout="fillNotes = false"
  >
    <input
      v-if="!reviewing && !filling"
      type="text"
      v-model="label"
      class="border-0"
      style="width:98%"
    />
    <label v-else class="border-0 text-bold" style="width:98%">
      <span v-html="$globals.linkParser(label)"></span>
      <span v-if="showRequiredNotify && required && !fill" class="text-danger">
        *</span
      >
    </label>

    <div
      v-if="filling && review.comment !== '' && review.comment !== null"
      class="alert alert-warning"
    >
      <p class="mb-0" v-html="$globals.linkParser(review.comment)"></p>
    </div>

    <div v-if="previewing">{{ fill }}</div>
    <input
      v-else-if="!reviewing && !filling"
      type="text"
      class="form-control"
      readonly
      style="height: 58%"
    />
    <input
      v-else
      type="text"
      class="form-control"
      v-model="fill"
      style="height: 58%"
      :required="required"
      :disabled="reviewing || !enabled"
      :class="{ 'border-danger': showRequiredNotify && required && !fill }"
    />
    <div
      v-if="!previewing"
      class="note-content"
      :class="{
        fadeIn: filling && fillNotes && notes !== null,
        fadeOut: !(filling && fillNotes && notes !== null)
      }"
      @mouseover="fillNotes = true"
      @mouseout="fillNotes = false"
    >
      <p v-html="$globals.linkParser(notes)"></p>
    </div>

    <div v-if="reviewing && !previewing" class="reviewing-box">
      <div class="mt-1 text-left">
        <span class="mr-1 check-input">
          <label class="checkbox-cont checkbox-form">
            <input type="checkbox" v-model="review.review" />
            <span class="checkmark"></span>
          </label>
        </span>
        <span
          class="add-note"
          title="Add Note"
          @click="commenting = !commenting"
        >
          <img src="/images/add-icon.svg" />
        </span>
        <div class="note-input" v-if="commenting">
          <textarea
            class="form-control"
            v-model="review.comment"
            style="min-height:60px;max-height: 60px;height: 60px; background: 0 !important;border: 0;"
          ></textarea>
        </div>
      </div>
    </div>

    <div
      v-if="!reviewing && !filling"
      class="moving-tool row position-absolute"
    >
      <span
        class="material-icons cursor-pointer col-12 moving-up"
        @click="moveUp($event, referenceX, referenceY)"
      >
        keyboard_arrow_up
      </span>
      <span
        class="material-icons cursor-pointer col-12 moving-down"
        @click="moveDown($event, referenceX, referenceY)"
      >
        keyboard_arrow_down
      </span>
    </div>

    <img
      src="/images/menu.svg"
      class="menu"
      @click="menuOpen = !menuOpen"
      v-if="!reviewing && !filling"
    />
    <div class="menu-body" v-if="menuOpen" v-on:clickout="menuOpen = false">
      <div
        class="cursor-pointer col-12 row m-0 p-0 mb-1"
        @click="confirmRemove"
      >
        <p class="m-0 text-danger">
          <img src="/images/remove.svg" class="remove p-0" />
          Remove
        </p>
      </div>
      <small>Excel Name</small>
      <input
        type="text"
        v-model="excel_name"
        class="width form-control-sm col-12"
      />
      <hr class="mt-2 mb-2" />
      <div
        class="cursor-pointer col-12 row m-0 p-0 mb-1"
        @click="noting = !noting"
      >
        <p class="m-0">
          <img src="/images/notes.svg" class="notes-img p-0" />
          Notes
        </p>
      </div>
      <textarea
        type="text"
        v-model="notes"
        class="notes-text form-control-sm"
        :class="{ hidden: !noting }"
      ></textarea>

      <div class="col-12 row m-0 p-0 mb-1">
        <small>Width</small>
        <select v-model="width" class="width form-control-sm col-12">
          <option value="col-3">25%</option>
          <option value="col-4">33.3%</option>
          <option value="col-6">50%</option>
          <option value="col-8">66.6%</option>
          <option value="col-12">100%</option>
        </select>
      </div>
      <div class="col-12 row m-0 p-0 mb-1">
        <small>Height</small>
        <select v-model="height" class="height form-control-sm col-12">
          <option value="auto">Fit</option>
          <option value="65px">Small</option>
          <option value="90px">Medium</option>
          <option value="115px">Large</option>
        </select>
      </div>
      <div class="col-12 row m-0 p-0 mb-1">
        <small>Enabled</small>
        <select v-model="enabled" class="height form-control-sm col-12">
          <option value="true">Enabled</option>
          <option value="false">Disabled</option>
        </select>
      </div>
      <div class="col-12 row m-0 p-0 mb-1">
        <small>Required</small>
        <select v-model="required" class="height form-control-sm col-12">
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </div>
      <div class="col-12 row m-0 p-0 mb-1">
        <small>Website View</small>
        <select v-model="website_view" class="height form-control-sm col-12">
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextElement",
  watch: {
    showRequiredNotify: function() {
      if (this.showRequiredNotify && this.required && !this.fill) {
        if (this.$el.closest(".e-item") != null) {
          let tabIndex = this.$el
            .closest(".e-item")
            .id.replace("e-content-TabInstance_", "");
          document
            .querySelectorAll(".nav-index")
            [parseInt(tabIndex) - 1].classList.add("bg-danger");
        }
      }
    }
  },
  data() {
    return {
      showRequiredNotify: false,
      removed: false,
      type: "text",
      excel_name: "",
      enabled: true,
      required: false,
      website_view: true,
      label: "Title here ..",
      width: "col-4",
      height: "auto",
      notes: "",
      childList: [],
      noting: false,
      menuOpen: false,

      reviewing: false,
      commenting: false,
      review: {
        review: false,
        comment: ""
      },
      filling: false,
      fill: "",
      fillNotes: false,

      previewing: false,

      id: 0,

      referenceX: 0,
      referenceY: 0
    };
  },
  created: function() {
    let self = this;
    window.addEventListener("click", function(e) {
      // close dropdown when clicked outside
      if (!self.$el.contains(e.target)) {
        self.noting = false;
        self.menuOpen = false;
        self.commenting = false;
      }
    });
  },
  methods: {
    moveUp(e, x, y) {},
    moveDown(e, x, y) {},
    confirmRemove() {
      this.removed = confirm("Are you sure ?");
    }
  }
};
</script>

<style scoped></style>
