<template>
  <div id="login">
    <Navbar></Navbar>
    <main class="page-wrapper login-page">
      <div class="container container-control">
        <div class="row custom-container">
          <div class="col-lg-5 col-sm-8 mx-auto">
            <div class="login my-5">
              <p class="col-12">{{ $i18n.t("Verifying") }}</p>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  name: "Verify",
  components: {
    Breadcrumb,
    Footer,
    Navbar,
  },
  data() {
    return {
      links: [
        {
          key: "Verify",
          value: "verify",
        },
      ],
    };
  },
  mounted() {
    const preLoaderElm = document.querySelector(".preloader");
    if (preLoaderElm) {
      preLoaderElm.style.display = "none";
    }

    document.body.style.overflowY = "auto";
  },
  created() {
    axios
      .post(
        this.$router.currentRoute.query.redirect +
          "&signature=" +
          this.$router.currentRoute.query.signature
      )
      .then((res) => {
        this.$router.push("/login?verified");
      })
      .catch((error) => {
        this.$router.push("/login?unverified");
      });
  },
};
</script>

<style>
.login-page {
  //min-height: 80vh;
}

.login {
  background-image: linear-gradient(to right, #ffffff, #edecff17);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.16);
  padding: 3rem 1rem;
}

.socialLogin .fb {
  background: #3b5998;
  padding: 2px 0;
  display: inline-block;
  transition: 0.3s;
}

.socialLogin .tw {
  background: #00aced;
  padding: 2px 0;
  display: inline-block;
  transition: 0.3s;
}

.socialLogin .tw:hover,
.socialLogin .fb:hover {
  transform: scale(1.08);
}

.login .form-control {
  height: 44px;
}

.login .btn-main:focus {
  background: #171c28;
  border-color: #171c28;
  color: #fff;
}

.login .text-colored {
  color: #00979e;
}

.login .text-colored:hover {
  color: #3a325e;
}

.login .text-mainColor {
  color: #333;
}

.login .text-mainColor:hover {
  color: #333;
}
</style>
