<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import Breadcrumb from "@/components/Breadcrumb";

import Vue from "vue";
import TextElement from "../../../../ncms-vue/components/partials/templates/TextElement";
import TableElement from "../../../../ncms-vue/components/partials/templates/TableElement";
import TextAreaElement from "../../../../ncms-vue/components/partials/templates/TextAreaElement";
import CheckboxElement from "../../../../ncms-vue/components/partials/templates/CheckboxElement";
import SelectElement from "../../../../ncms-vue/components/partials/templates/SelectElement";
import TreeElement from "../../../../ncms-vue/components/partials/templates/TreeElement";
import LabelElement from "../../../../ncms-vue/components/partials/templates/LabelElement";
import LineElement from "../../../../ncms-vue/components/partials/templates/LineElement";
import RadioElement from "../../../../ncms-vue/components/partials/templates/RadioElement";
import AttachmentElement from "../../../../ncms-vue/components/partials/templates/AttachmentElement";

const _TextElement = Vue.extend(TextElement);
const _LabelElement = Vue.extend(LabelElement);
const _TableElement = Vue.extend(TableElement);
const _TextAreaElement = Vue.extend(TextAreaElement);
const _CheckboxElement = Vue.extend(CheckboxElement);
const _SelectElement = Vue.extend(SelectElement);
const _TreeElement = Vue.extend(TreeElement);
const _LineElement = Vue.extend(LineElement);
const _RadioElement = Vue.extend(RadioElement);
const _AttachmentElement = Vue.extend(AttachmentElement);

export default {
  name: "Job",
  components: { Breadcrumb, Footer, Navbar },
  data() {
    return {
      links: [
        {
          key: "Jobs",
          value: "/jobs",
        },
        {
          key: "Job",
          value: "/jobs/job?id=" + this.$route.query.id,
        },
        {
          key: "Apply",
          value: "/jobs/apply?id=" + this.$route.query.id,
        },
      ],
      loading: false,
      job: {},
      userForm: [], //application form - template
      current_tab: 0,
      tabs: 0,
      skills: [
        {
          name: "HTML",
        },
        {
          name: "HTML",
        },
        {
          name: "HTML",
        },
        {
          name: "HTML",
        },
      ],
      role: [
        "أنت كمصمم منتج تقف وتدافع عما هو أفضل للمستخدم.",
        "سيكون لديك الملكية الكاملة لعملية التصميم الشاملة. ستتعاون جنبًا إلى جنب مع إدارة المنتجات والتصميم والهندسة لتحويل مفاهيم التصميم الخاصة بك إلى ميزات تم تنفيذها بالكامل.",
        "ستجري بحثًا عن المستخدم للتحقق من صحة افتراضاتك والدفاع عن احتياجات المستخدم مع أصحاب المصلحة الآخرين.",
        "ستحافظ على نظام التصميم الخاص بنا وتنميه. ستدافع عن إمكانية الوصول إلى منتجاتنا واتساقها وجودتها.",
        "ستكون جزءًا من العملية برمتها، بدءًا من بداية الفكرة للميزات الجديدة المحتملة ورسم المفاهيم التقريبية، وبناء نماذج أولية ونماذج بالحجم الطبيعي منخفضة إلى عالية الدقة لاختبار الوثائق والتعامل معها وحتى الهندسة.",
      ],
      responses: [
        "تقديم حلول مع التركيز على تجربة مستخدم رائعة مع الموازنة بين احتياجات العملاء وأهداف الشركة وتماسك التصميم وسهولة التنفيذ.",
        "امتلك رحلة مستخدم شاملة وجميع جوانب تنفيذ التصميم بدءًا من أبحاث العملاء وحتى التفكير والنماذج الأولية واختبارات قابلية الاستخدام وحتى المنتج النهائي.",
        "ابحث عن التعليقات النوعية والكمية وفهمها ودمجها وإبلاغها طوال عملية التصميم من خلال الاستفادة من الاستطلاعات واختبار المستخدم وبيانات المستخدم لقياس أداء الميزات داخل التطبيق.",
        "اعمل بشكل مستقل في مجموعة منتجات مرنة ومتعددة الوظائف مع مديري المنتجات والمهندسين وعلماء البيانات لتحديد الأولويات وبناء ميزات جديدة.",
        "احصل على فهم عميق لاحتياجات المستخدم وأهداف العمل في مجال منتج مجموعتك الرشيقة.",
        "تحديد الأولويات بشكل مناسب: استخدم الحكم الجيد لإجراء المقايضات المناسبة لتسريع تعلم المنتج وتحقيق التوازن بين الأولويات المتنافسة.",
      ],
      haves: [
        "محفظة تعرض فهمًا واضحًا لتصميم واجهة المستخدم (الطباعة والبساطة والتفاعلات والتصميم المرئي.",
        "ما لا يقل عن 3 سنوات من الخبرة في التصميم ذات الصلة بالمنتجات الرقمية، ستكون ميزة إضافية (ولكنها ليست ضرورية) إذا كانت لديك خبرة في المنصات التعليمية أو الاستهلاكية أو SaaS.",
        "لديك خبرة في العمل مع فرق متعددة التخصصات في دورات تطوير المنتج التكرارية. أنت جيد في تحديد أولويات المهام، بالإضافة إلى إجراء المفاضلات بين جودة التصميم وجهود التنفيذ.",
        "استراتيجي وهادف في اتخاذ قرارات التصميم ويمكنك القيام بذلك من خلال دمج اعتبارات المستخدم والأعمال والاعتبارات الفنية في عملية التصميم الخاصة بك.",
        "أنت متواصل قوي وتعرف كيفية الدفاع عن التصميم.",
      ],
      uploading: true,
      uploadsDone: 0,
      uploadsFail: 0,
      files: 0,
      applying: false,
    };
  },
  created() {
    this.$store.dispatch("refresh_user");

    if (!this.isLoggedIn) {
      this.$router.push(
        "/login"
        // "/login?return=job-" + this.$router.currentRoute.query.id
      );
      return;
    }
    // if (!this.user.canApply) {
    //   this.$router.back();
    //   return;
    // }
    if (this.$i18n.locale == "en") {
      document.body.style.overflowY = "scroll";
    }
    // load job
    this.loading = true;
    axios
      .get("website/jobs/" + this.$route.query.id)
      .then((response) => {
        if (typeof response.data.data.data !== "undefined") {
          this.job = response.data.data.data;
          // show application
          let userForm = response.data.data.data.fullApplication;
          if (userForm != null) {
            this.userForm.id = userForm.id;
            this.userForm.name = userForm.name;
            this.userForm.expires_at = userForm.expires_at;
            this.userForm.user = userForm.user;
            this.userForm.assigned_at = userForm.assigned_at;
            this.userForm.updated_at = userForm.updated_at;
            this.userForm.notifications = userForm.notifications;
            this.userForm.status = userForm.status;
            this.userForm.reviewedPercent = userForm.reviewedPercent;
            this.userForm.oldReviewedPercent = userForm.reviewedPercent;
            this.userForm.acceptedPercent = userForm.acceptedPercent;
            this.userForm.action =
              userForm.action === "Received" ? "Filling" : userForm.action;
            this.userForm.action_activities = userForm.action_activities;
            this.userForm.pages = [];
            // mount template pages
            let temp = this;
            for (let i = 0; i < userForm.pages.length; i++) {
              // add page
              setTimeout(function() {
                temp.addPage(userForm.pages[i].title);
                temp.userForm.pages[i].id = userForm.pages[i].id;
                // add page items
                for (let j = 0; j < userForm.pages[i].items.length; j++) {
                  let id = parseInt(userForm.pages[i].items[j].id);
                  let notes = userForm.pages[i].items[j].notes;
                  let enabled = userForm.pages[i].items[j].enabled;
                  let fill = "",
                    comment = "",
                    review = false;

                  let childList = JSON.parse(
                    userForm.pages[i].items[j].childList
                  );
                  switch (userForm.pages[i].items[j].type) {
                    case "text":
                      temp.addTextElement(fill, comment, notes, review, id);
                      break;
                    case "label":
                      temp.addLabelElement(fill, comment, notes, review, id);
                      break;
                    case "textarea":
                      temp.addTextAreaElement(fill, comment, notes, review, id);
                      break;
                    case "checkbox":
                      temp.addCheckboxElement(fill, comment, notes, review, id);
                      break;
                    case "select":
                      temp.addSelectElement(fill, comment, notes, review, id);
                      break;
                    case "table":
                      temp.addTableElement(fill, comment, notes, review, id);
                      temp.userForm.pages[i].items[j].columns =
                        childList[0].length;
                      temp.userForm.pages[i].items[j].rows = childList.length;
                      break;
                    case "tree":
                      temp.addTreeElement(fill, comment, notes, review, id);
                      break;
                    case "line":
                      temp.addLineElement(fill, comment, notes, review, id);
                      break;
                    case "radio":
                      temp.addRadioElement(fill, comment, notes, review, id);
                      break;
                    case "file":
                      temp.addAttachmentElement(
                        fill,
                        comment,
                        notes,
                        review,
                        id
                      );
                      break;
                    default:
                      temp.userForm.pages[i].items[j] = {};
                  }
                  temp.userForm.pages[i].items[j].label =
                    userForm.pages[i].items[j].label;
                  temp.userForm.pages[i].items[j].notes =
                    userForm.pages[i].items[j].notes;
                  temp.userForm.pages[i].items[j].childList =
                    (userForm.pages[i].items[j].type === "table" ||
                      userForm.pages[i].items[j].type === "tree") &&
                    fill !== ""
                      ? JSON.parse(fill)
                      : childList;
                  temp.userForm.pages[i].items[j].width =
                    userForm.pages[i].items[j].width;
                  temp.userForm.pages[i].items[j].height =
                    userForm.pages[i].items[j].height;
                  temp.userForm.pages[i].items[j].enabled = enabled;
                  temp.userForm.pages[i].items[j].required =
                    userForm.pages[i].items[j].required === 1;

                  // temp.$watch(() => temp.userForm.pages[i].items[j], temp.handleFilling, {deep: true});
                }
              }, 700 * i);
              setTimeout(function() {
                //temp.$watch(() => temp.userForm, temp.handleFilling, {deep: true});

                // document.getElementById('application').style.visibility = 'visible'
                // temp.loading = false

                document.querySelector(".preloader").style.display = "none";
                document.body.style.overflowY = "auto";
              }, 701 * userForm.pages.length);
            }
          } else {
            document.querySelector(".preloader").style.display = "none";
            document.body.style.overflowY = "auto";
          }
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
      });
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
    user() {
      return this.$store.getters.user;
    },
    uploadingStart: {
      get() {
        return this.uploading;
      },
      set(value) {
        if (!value) {
          if (this.applying) return;
          this.applying = true;
          this.loading = true;
          axios
            .put(
              "website/jobs/apply/" +
                parseInt(this.$router.currentRoute.query.id),
              {
                id: this.userForm ? this.userForm.id : "",
                pages: this.userForm ? this.userForm.pages : "",
                action: "Submitted",
              }
            )
            .then((response) => {
              this.$router.push("/applications");
            })
            .catch((error) => {
              this.loading = false;
              this.applying = false;
              if (error.response.data.message === "Validation Error")
                this.validationErrors = error.response.data.errors;
              this.$notify({
                group: "notify",
                type: "error",
                title: this.$i18n.t("Error"),
                text: this.$i18n.t(`${error.response.data?.message}`),
              });

              if (this.userForm != null)
                for (let i = 0; i < this.userForm.pages.length; i++) {
                  for (
                    let j = 0;
                    j < this.userForm.pages[i].items.length;
                    j++
                  ) {
                    this.userForm.pages[i].items[j].showRequiredNotify = false;
                    this.userForm.pages[i].items[j].showRequiredNotify = true;
                  }
                }
            });
        }
      },
    },
  },
  watch: {
    files() {
      this.checkUploading();
    },
    uploadsDone() {
      this.checkUploading();
    },
    uploadsFail() {
      this.checkUploading();
    },
    loading() {},
  },
  methods: {
    getJobName(job) {
      const lang = this.$i18n.locale;

      return lang === "ar" ? job?.region?.name_ar : job?.region?.name;
    },
    ConvertToArabicNumbers(num) {
      const arabicNumbers =
        "\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669";
      return new String(num).replace(/[0123456789]/g, (d) => {
        return arabicNumbers[d];
      });
    },
    checkUploading() {
      if (this.files === this.uploadsDone + this.uploadsFail) {
        this.uploading = false;
        this.uploadingStart = false;
      }
    },
    apply() {
      this.loading = true;
      if (!this.isLoggedIn) {
        this.$router.push(
          "/login"
          // "/login?return=job-" + this.$router.currentRoute.query.id
        );
        return;
      }

      this.uploading = true;
      this.uploadingStart = true;
      this.files = 0;
      this.uploadsDone = 0;
      this.uploadsFail = 0;
      if (typeof this.userForm.id != "undefined") {
        // check for files to upload
        let self = this;
        for (let i = 0; i < this.userForm.pages.length; i++) {
          for (let j = 0; j < this.userForm.pages[i].items.length; j++) {
            if (this.userForm.pages[i].items[j].type === "file") {
              this.files += this.userForm.pages[i].items[j].childList.length;
            }
          }
        }

        if (this.files < 1) {
          this.uploading = false;
          this.uploadingStart = false;
        }

        for (let i = 0; i < this.userForm.pages.length; i++) {
          for (let j = 0; j < this.userForm.pages[i].items.length; j++) {
            if (this.userForm.pages[i].items[j].type === "file") {
              this.userForm.pages[i].items[j].childList.map(function(
                attachment,
                index
              ) {
                let formData = new FormData();
                let file = document.getElementById(
                  "files-" + self.userForm.pages[i].items[j].id + "-" + index
                ).files[0];
                if (typeof file != "undefined") {
                  formData.append("file", file);
                  formData.append(
                    "folder",
                    "website/applications/" + self.user.id
                  );
                  axios
                    .post("uploader", formData)
                    .then((response) => {
                      if (response.data.success) {
                        self.userForm.pages[i].items[j].childList[index].file =
                          response.data.data;
                        self.uploadsDone++;
                      }
                    })
                    .catch((error) => {
                      self.uploadsFail++;
                      self.userForm.pages[i].items[j].childList[index].error =
                        error.response.data.errors.file[0];
                    });
                } else {
                  self.uploadsFail++;
                }
              });
            }
          }
        }
      } else {
        this.uploading = false;
        this.uploadingStart = false;
      }
    },

    appendHTML(html = "") {
      const div = document.createElement("div");
      div.className = "row";
      div.innerHTML = html;
      document.getElementById("application").appendChild(div);
    },
    addPage(title = "Page Title ..") {
      this.tabs++;
      this.current_tab = this.tabs - 1;
      this.userForm.pages[this.current_tab] = { title: title, items: [] };
      this.appendHTML(
        '<div class="clearfix"></div><p class="detail-head pre-line mt-4">' +
          title +
          "</p>"
      );
    },
    addTextElement(
      fill = "",
      comment = "",
      notes = "",
      review = false,
      id = 0
    ) {
      const textElement = new _TextElement();
      textElement.$data.filling = true;
      textElement.$data.fill = fill;
      textElement.$data.review.comment = comment;
      textElement.$data.notes = notes;
      textElement.$data.review.review = review;
      textElement.$data.id = id;
      textElement.$mount();
      document.getElementById("application").appendChild(textElement.$el);
      this.userForm.pages[this.current_tab].items[
        this.userForm.pages[this.current_tab].items.length
      ] = textElement.$data;
    },
    addLabelElement(
      fill = "",
      comment = "",
      notes = "",
      review = false,
      id = 0
    ) {
      const labelElement = new _LabelElement();
      labelElement.$data.filling = true;
      labelElement.$data.fill = fill;
      labelElement.$data.review.comment = comment;
      labelElement.$data.notes = notes;
      labelElement.$data.review.review = review;
      labelElement.$data.id = id;
      labelElement.$mount();
      document.getElementById("application").appendChild(labelElement.$el);
      this.userForm.pages[this.current_tab].items[
        this.userForm.pages[this.current_tab].items.length
      ] = labelElement.$data;
    },
    addTableElement(
      fill = "",
      comment = "",
      notes = "",
      review = false,
      id = 0
    ) {
      const tableElement = new _TableElement();
      tableElement.$data.filling = true;
      tableElement.$data.fill = fill;
      tableElement.$data.review.comment = comment;
      tableElement.$data.notes = notes;
      tableElement.$data.review.review = review;
      tableElement.$data.id = id;
      tableElement.$mount();
      document.getElementById("application").appendChild(tableElement.$el);
      this.userForm.pages[this.current_tab].items[
        this.userForm.pages[this.current_tab].items.length
      ] = tableElement.$data;
    },
    addTextAreaElement(
      fill = "",
      comment = "",
      notes = "",
      review = false,
      id = 0
    ) {
      const textAreaElement = new _TextAreaElement();
      textAreaElement.$data.filling = true;
      textAreaElement.$data.fill = fill;
      textAreaElement.$data.review.comment = comment;
      textAreaElement.$data.notes = notes;
      textAreaElement.$data.review.review = review;
      textAreaElement.$data.id = id;
      textAreaElement.$mount();
      document.getElementById("application").appendChild(textAreaElement.$el);
      this.userForm.pages[this.current_tab].items[
        this.userForm.pages[this.current_tab].items.length
      ] = textAreaElement.$data;
    },
    addCheckboxElement(
      fill = "",
      comment = "",
      notes = "",
      review = false,
      id = 0
    ) {
      const checkboxElement = new _CheckboxElement();
      checkboxElement.$data.filling = true;
      checkboxElement.$data.fill = fill;
      checkboxElement.$data.review.comment = comment;
      checkboxElement.$data.notes = notes;
      checkboxElement.$data.review.review = review;
      checkboxElement.$data.id = id;
      checkboxElement.$mount();
      document.getElementById("application").appendChild(checkboxElement.$el);
      this.userForm.pages[this.current_tab].items[
        this.userForm.pages[this.current_tab].items.length
      ] = checkboxElement.$data;
    },
    addSelectElement(
      fill = "",
      comment = "",
      notes = "",
      review = false,
      id = 0
    ) {
      const selectElement = new _SelectElement();
      selectElement.$data.filling = true;
      selectElement.$data.fill = fill;
      selectElement.$data.review.comment = comment;
      selectElement.$data.notes = notes;
      selectElement.$data.review.review = review;
      selectElement.$data.id = id;
      selectElement.$mount();
      document.getElementById("application").appendChild(selectElement.$el);
      this.userForm.pages[this.current_tab].items[
        this.userForm.pages[this.current_tab].items.length
      ] = selectElement.$data;
    },
    addTreeElement(
      fill = "",
      comment = "",
      notes = "",
      review = false,
      id = 0
    ) {
      const treeElement = new _TreeElement();
      treeElement.$data.filling = true;
      treeElement.$data.fill = fill;
      treeElement.$data.review.comment = comment;
      treeElement.$data.notes = notes;
      treeElement.$data.review.review = review;
      treeElement.$data.id = id;
      treeElement.$mount();
      document.getElementById("application").appendChild(treeElement.$el);
      this.userForm.pages[this.current_tab].items[
        this.userForm.pages[this.current_tab].items.length
      ] = treeElement.$data;
    },
    addLineElement(
      fill = "",
      comment = "",
      notes = "",
      review = false,
      id = 0
    ) {
      const lineElement = new _LineElement();
      lineElement.$data.filling = true;
      lineElement.$data.fill = fill;
      lineElement.$data.review.comment = comment;
      lineElement.$data.notes = notes;
      lineElement.$data.review.review = review;
      lineElement.$data.id = id;
      lineElement.$mount();
      document.getElementById("application").appendChild(lineElement.$el);
      this.userForm.pages[this.current_tab].items[
        this.userForm.pages[this.current_tab].items.length
      ] = lineElement.$data;
    },
    addRadioElement(
      fill = "",
      comment = "",
      notes = "",
      review = false,
      id = 0
    ) {
      const radioElement = new _RadioElement();
      radioElement.$data.filling = true;
      radioElement.$data.fill = fill;
      radioElement.$data.review.comment = comment;
      radioElement.$data.notes = notes;
      radioElement.$data.review.review = review;
      radioElement.$data.id = id;
      radioElement.$mount();
      document.getElementById("application").appendChild(radioElement.$el);
      this.userForm.pages[this.current_tab].items[
        this.userForm.pages[this.current_tab].items.length
      ] = radioElement.$data;
    },
    addAttachmentElement(
      fill = "",
      comment = "",
      notes = "",
      review = false,
      id = 0
    ) {
      const attachmentElement = new _AttachmentElement();
      attachmentElement.$data.filling = true;
      attachmentElement.$data.fill = fill;
      attachmentElement.$data.review.comment = comment;
      attachmentElement.$data.notes = notes;
      attachmentElement.$data.review.review = review;
      attachmentElement.$data.id = id;
      attachmentElement.$mount();
      document.getElementById("application").appendChild(attachmentElement.$el);
      this.userForm.pages[this.current_tab].items[
        this.userForm.pages[this.current_tab].items.length
      ] = attachmentElement.$data;
    },
  },
};
</script>

<template>
  <div id="job" class="main-wrapper">
    <Navbar></Navbar>
    <main class="page-wrapper">
      <div class="container container-control">
        <div class="row pt-5 pb-5">
          <div class="col-12 apply-banner">
            <img src="@/assets/apply.svg" alt="" />
            <div
              class="job_icon"
              :class="{ rtl: $i18n.locale == 'ar', ltr: $i18n.locale == 'en' }"
            >
              <img src="@/assets/gd.svg" alt="" width="100" />
            </div>
          </div>
          <div class="col-12 px-lg-5">
            <div class="job-details">
              <div class="details bg-gray">
                <p class="font-weight-bold" style="color: #7A7B7C;">
                  {{ $i18n.t("job_require") }}
                </p>
                <h1
                  class="detail-head font-weight-bold"
                  style="color: #222325;"
                >
                  {{ job.title }}
                </h1>
                <p class="d-flex" style="color: #7A7B7C;">
                  <span class=" d-flex gap-1 align-center">
                    <img src="@/assets/map.svg" alt="" width="22" height="22" />
                    <span>
                      {{ getJobName(job) }}
                    </span>
                  </span>
                  <span class="mx-3 d-flex gap-1 align-center">
                    <img
                      src="@/assets/calendar.svg"
                      alt=""
                      width="22"
                      height="22"
                    />
                    <span v-if="job.create_dates">
                      {{
                        job.create_dates.created_at_human
                          .toString()
                          .toLowerCase()
                          .split(" ")[1] == "months"
                          ? $i18n.t("months_ago", {
                              month:
                                $i18n.locale == "ar"
                                  ? ConvertToArabicNumbers(
                                      job.create_dates.created_at_human
                                        .toString()
                                        .toLowerCase()
                                        .split(" ")[0]
                                    )
                                  : job.create_dates.created_at_human
                                      .toString()
                                      .toLowerCase()
                                      .split(" ")[0],
                            })
                          : job.create_dates.created_at_human
                              .toString()
                              .toLowerCase()
                              .split(" ")[1] == "year"
                          ? $i18n.t("years_ago", {
                              year:
                                $i18n.locale == "ar"
                                  ? ConvertToArabicNumbers(
                                      job.create_dates.created_at_human
                                        .toString()
                                        .toLowerCase()
                                        .split(" ")[0]
                                    )
                                  : job.create_dates.created_at_human
                                      .toString()
                                      .toLowerCase()
                                      .split(" ")[0],
                            })
                          : ""
                      }}
                    </span>
                  </span>
                </p>
                <!--                <div class="col-12" v-if="isLoggedIn && !user.canApply">-->
                <div class="col-12" v-if="!isLoggedIn">
                  <i class="text-danger">{{
                    $i18n.t("You must login to apply")
                  }}</i>
                </div>
                <div class="col-12" v-if="isLoggedIn && !user.canApply">
                  <i class="text-danger">{{ $i18n.t("Can not apply now") }}</i>
                </div>
                <button
                  v-else
                  type="button"
                  class="input-group-text btn btn-main btn-apply d-block p-2"
                  :disabled="loading"
                  @click="apply"
                >
                  <div class="row justify-center align-center">
                    <div class="col-5 text-left" v-if="loading">
                      <span class="spinner-border " role="status">
                        <span class="sr-only">{{ $i18n.t("Loading") }}</span>
                      </span>
                    </div>
                    <div class="col col-7 d-block p-1 text-center mx-auto">
                      <img
                        src="@/assets/document.svg"
                        alt=""
                        width="24"
                        height="24"
                      />
                      <span class="mx-1">{{ $i18n.t("Apply Now") }}</span>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 px-lg-5 mt-3">
            <div id="application"></div>
            <div id="form">
              <!--              {{job}}-->
              <div class="mt-5 w-75">
                <h5 class="font-weight-bold" style="color: #3D3D3F">
                  {{ $i18n.t("overview") }}
                </h5>
                <p v-html="job.description" style="color: #3D3D3F"></p>
                <!-- <p style="color: #3D3D3F">{{ job.description }}</p> -->
                <!--                <p style="color: #3D3D3F" v-if="$i18n.locale == 'en'">-->
                <!--                  Mailchimp is on a major mission to revolutionize how designers build their careers. Since day 1, our goal has been to create a gamified learning experience, and now we’re taking the mission to help designers around the world test their skills, build an amazing profile, and get hired. With more than 120K users from more than 150 countries, we’re a fast-growing remote team based in the USA and Europe.-->
                <!--                </p>-->
                <!--                <p style="color: #3D3D3F" v-else>تقوم Mailchimp بمهمة كبرى تتمثل في إحداث ثورة في كيفية بناء المصممين لمسيرتهم المهنية. منذ اليوم الأول، كان هدفنا هو إنشاء تجربة تعليمية تعتمد على الألعاب، والآن نأخذ على عاتقنا مهمة مساعدة المصممين في جميع أنحاء العالم على اختبار مهاراتهم، وبناء ملف تعريفي رائع، والحصول على وظيفة. مع أكثر من 120 ألف مستخدم من أكثر من 150 دولة، نحن فريق سريع النمو عن بعد ومقره في الولايات المتحدة الأمريكية وأوروبا.</p>-->
              </div>
              <div class="mt-5 w-75" v-if="job.roles && job.roles.length > 0">
                <h5 class="font-weight-bold" style="color: #3D3D3F">
                  {{ $i18n.t("role") }}:
                </h5>
                <ul class="mx-4">
                  <li
                    style="list-style: disc; color: #3D3D3F"
                    v-for="rl in job.roles"
                  >
                    {{ rl }}
                  </li>
                </ul>
              </div>
              <div class="mt-5 w-75" v-if="job.responses && job.responses > 0">
                <h5 class="font-weight-bold" style="color: #3D3D3F">
                  {{ $i18n.t("responsibilities") }}:
                </h5>
                <ul class="mx-4">
                  <li
                    style="list-style: disc; color: #3D3D3F"
                    v-for="rl in job.responses"
                  >
                    {{ rl }}
                  </li>
                </ul>
              </div>
              <div class="mt-5 w-75" v-if="job.skills && job.skills > 0">
                <h5 class="font-weight-bold" style="color: #3D3D3F">
                  {{ $i18n.t("you_have") }}:
                </h5>
                <ul class="mx-4">
                  <li
                    style="list-style: disc; color: #3D3D3F"
                    v-for="rl in job.skills"
                  >
                    {{ rl.name }}
                  </li>
                </ul>
              </div>
              <!--              <div class="mt-5 w-75">-->
              <!--                <p  style="color: #3D3D3F">-->
              <!--                  Mailchimp يدعم التنوع والشمول بنسبة 100%. تساعد اختلافاتنا في تحقيق نجاحنا، كما أن وجود فريق متنوع أمر أساسي لنجاحنا. ترحب Uxcel بالأشخاص من أي عرق أو اتجاه أو جنس أو دين أو عمر أو عرق أو إعاقة أو هوية!-->
              <!--                </p>-->
              <!--              </div>-->
              <!--              <div v-if="job.skills">-->
              <!--                <h4>{{ $i18n.t("skills") }}</h4>-->
              <!--                <span-->
              <!--                    v-for="skill in skills"-->
              <!--                    :key="skill.name"-->
              <!--                    class="badge badge-primary mr-2"-->
              <!--                >{{ skill.name }}</span-->
              <!--                >-->
              <!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer></Footer>
  </div>
</template>

<style lang="scss" scoped>
::marker {
  font-size: smaller !important;
}
.page-wrapper {
  min-height: calc(100vh - 224px) !important;
}

.apply-banner {
  position: relative;
  margin-bottom: 15px;
  height: 300px;

  img {
    position: absolute;
    top: 0;
    width: 100%;
  }

  .job_icon {
    position: absolute;
    width: 100px;
    height: 100px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      position: relative !important;
      width: 70px !important;
    }

    &.rtl {
      right: 3%;
      bottom: 0%;
    }

    &.ltr {
      left: 3%;
      bottom: 0%;
    }
  }
}

.btn-apply {
  width: 250px;
}
</style>
