import { render, staticRenderFns } from "./AttachmentElement.vue?vue&type=template&id=320a7be8&scoped=true&"
import script from "./AttachmentElement.vue?vue&type=script&lang=js&"
export * from "./AttachmentElement.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../website-vue/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "320a7be8",
  null
  
)

export default component.exports