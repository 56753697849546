<script>
import "vue-select/src/scss/vue-select.scss";

import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import Breadcrumb from "@/components/Breadcrumb";
import Datepicker from "vuejs-datetimepicker";
import vSelect from "vue-select";

import moment from "moment";

export default {
  name: "Profile",
  components: { Breadcrumb, Footer, Navbar, Datepicker, vSelect },
  watch: {
    "userProfile.city"(newVal) {
      if (newVal === "Other")
        document.getElementById("otherCity").style.display = "block";
      else document.getElementById("otherCity").style.display = "none";
    },
    "userProfile.university"(newVal) {
      const elm = document.getElementById("otherUniversity");
      if (!elm) return;
      if (newVal === "Other") {
        elm.style.display = "block";
      } else elm.style.display = "none";
    },
    "userProfile.age"(newVal) {
      // const checkUserAge = this.isUserGreaterThanTwintyFive(newVal);
      // if(checkUserAge){
      //   this.selectedExp = 'experienced'
      // }else{
      //   this.selectedExp = 'fresh'
      // }
    },
  },
  data() {
    return {
      links: [
        {
          key: "My Profile",
          value: "profile",
        },
        {
          key: "Applications",
          value: "applications",
        },
        {
          key: "Application",
          value: "application",
        },
      ],
      socialStatus: [
        { name: this.$i18n.t("Single"), value: "single" },
        { name: this.$i18n.t("Married"), value: "married" },
      ],
      genders: [
        { name: this.$i18n.t("Male"), value: "male" },
        { name: this.$i18n.t("Female"), value: "female" },
      ],
      selectedExp: "fresh",
      cities: [],
      universities: [],
      specialists: [],
      links: [
        {
          key: "My Profile",
          value: "profile",
        },
      ],
      userProfile: {},
      userProfileImages: {
        id: "",
        qualification: "",
        academicRecord: "",
        trainingCourses: [],
        workExperiences: [],
      },
      otherCity: "",
      otherUniversity: [],
      otherSpecialist: [],
      loading: false,
      validationErrors: [],
      currentActiveTab: 0,
      imageNotValid: false,
    };
  },
  computed: {
    expLevels() {
      return [
        { label: this.$i18n.t("Fresh Graduate"), value: "fresh" },
        { label: this.$i18n.t("Add Experience"), value: "experienced" },
      ];
    },
    disabledDates() {
      const today = new Date();
      const twentyYearsAgo = new Date();
      twentyYearsAgo.setFullYear(today.getFullYear() - 18);

      return this.formatDate(twentyYearsAgo);
    },
    transCountries() {
      return this.$globals.countries.map((country) => ({
        country,
        label: this.$t(country),
      }));
    },
    transCities() {
      return this.cities.map((city) => ({
        city,
        label: this.$t(city),
      }));
    },
    getWorkExperiences() {
      let years = 0;
      let months = 0;
      this.userProfileImages.workExperiences.forEach((wExp) => {
        let startDate = wExp.t3;
        let endDate = wExp.t4;
        if (!startDate || !endDate) {
          return;
        }
        if (endDate === "current") {
          endDate = new Date();
        }
        let { yearsDiff, monthsDiff } = this.getDateDiff(
          new Date(startDate),
          new Date(endDate)
        );
        years += yearsDiff;
        months += monthsDiff;
        if (months >= 12) {
          years += 1;
          months = months - 12;
        }
      });

      if (years === 0 && months === 0) return { years: 0, months: 0 };
      if (years < 0 && months < 0) return { years: 0, months: 0 };

      return { years, months };
      // return `${years} ${this.$i18n.t('years')}, ${months} ${this.$i18n.t('months')} `
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    user() {
      return this.$store.getters.user;
    },
    age() {
      let res = null;
      if (this.$store.getters.user.age)
        res = parseInt(moment(this.$store.getters.user.age).fromNow());
      return res;
    },
    avatarUrl(val) {
      // console.log(val);
      // if (this.user.avatar) {
      //   return this.getUrl(this.user.avatar);
      // }
      // console.log("avatar",this.user?.avatar);
      // console.log("aaaaaaaaa",this.userProfile?.avatar);
      if (this.user?.avatar?.split("public/")[1]) {
        return this.getUrl(this.user.avatar);
      } else {
        // console.log("default");
        return "images/user.png";
      }
      // return BASEURL + '/' + this.user.avatar.replace('public', 'storage');

      // return BASEURL + '/' + this.user.avatar.replace('public', 'storage');
    },
  },
  created() {
    if (!this.isLoggedIn) this.$router.push("/");

    this.userProfile = this.user;

    if (!this.userProfile.nationality) {
      this.userProfile.nationality = "Saudi Arabia";
    }

    if (!this.userProfile?.skills?.length)
      this.userProfile.skills.push({ name: "", level: "" });
    if (!this.userProfile.qualifications.length) this.addQualification();

    this.userProfile?.photos?.map((photo) => {
      if (photo.type === "trainingCourses")
        this.userProfileImages.trainingCourses.push(photo);
      else if (photo.type === "workExperiences")
        this.userProfileImages.workExperiences.push(photo);
      else this.userProfileImages[photo.type] = photo?.name;
    });

    if (!this.userProfileImages.trainingCourses.length)
      this.userProfileImages.trainingCourses.push({
        name: "",
        t1: "",
        t2: "",
        t3: "",
        t4: "",
      });

    const isThereWorkExp = this.userProfile?.photos.find(
      (pho) => pho.type === "workExperiences"
    );
    if (isThereWorkExp) {
      this.selectedExp = "experienced";
    }

    if (!this.userProfile.workingPerferedErea) {
      this.userProfile.workingPerferedErea = "riyadh";
    }
    // if (!this.userProfileImages.workExperiences.length)
    //   this.createWorkExperience();

    // load cities
    axios.get("website/cities").then((response) => {
      this.cities = Object.values(response.data.data.data);

      this.cities.push("Other");
      if (Object.values(this.cities).indexOf(this.userProfile.city) === -1) {
        this.otherCity = this.userProfile.city;
        this.userProfile.city = "Other";
      }
    });
    // load universities
    axios.get("website/universities").then((response) => {
      this.universities = Object.values(response.data.data.data);
      this.universities.push("Other");
      this.userProfile.qualifications.map((x) => {
        this.otherUniversity.push(x.university);
        if (Object.values(this.universities).indexOf(x.university) === -1)
          x.university = "Other";
      });
    });
    // load specialists
    axios.get("website/specialists").then((response) => {
      this.specialists = [...Object.values(response.data.data.data), "Other"];
      // this.specialists.push('Other');
      // this.specialists = response.data.data.data;
      // this.specialists['Other'] = 'Other';
      // this.userProfile.qualifications.map((x) => {
      //   this.otherSpecialist.push(x.specialist)
      //   if (Object.values(this.specialists).indexOf(x.specialist) === -1)
      //     x.specialist = 'Other';
      // });
    });
  },
  mounted() {
    if (document.querySelector(".preloader")) {
      document.querySelector(".preloader").style.display = "none";
      document.body.style.overflowY = "auto";
    }
    document.body.style.overflowY = "auto";
  },
  methods: {
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    chackWorkExpName(val) {
      if (val && val?.name) return true;
      return false;
    },
    checkQualification(val, i) {
      if (val === this.$i18n.t("Primary")) {
        // this.userProfile.qualifications[i].university = null
        // this.userProfile.qualifications[i].qualification = null
        return false;
      } else if (val === this.$i18n.t("Intermediate")) {
        // this.userProfile.qualifications[i].university = null
        // this.userProfile.qualifications[i].qualification = null
        return false;
      } else if (val === this.$i18n.t("Secondary")) {
        return false;
      }
      return true;
    },
    isUserGreaterThanTwintyFive(val) {
      const userBirthdate = val;
      if (!userBirthdate) return false;
      // Parse the  birthdate using Moment.js
      const birthdateMoment = moment(userBirthdate);

      // Calculate the age
      const age = moment().diff(birthdateMoment, "years");

      // Check if the age is greater than 25
      if (age > 25) {
        return true;
      } else {
        return false;
      }
    },
    handlePhone(val) {
      // if (val.length > 9) {
      //   return;
      // }
      // console.log(val.length);
    },
    handleKeyDown(event) {
      const lang = localStorage.getItem("locale") || "ar";

      // Get the pressed key
      const keyPressed = event.key;

      let allowedKeyRegex;

      if (lang === "ar") {
        // If the language is Arabic, allow Arabic letters along with the specified keys
        allowedKeyRegex = /^[\u0600-\u06FF]|Backspace|Delete|ArrowLeft|ArrowRight|ArrowUp|ArrowDown$/;
      } else {
        // If the language is English, allow English letters along with the specified keys
        allowedKeyRegex = /^[a-zA-Z]|Backspace|Delete|ArrowLeft|ArrowRight|ArrowUp|ArrowDown$/;
      }

      // If the pressed key is not allowed, prevent its input

      if (keyPressed === "Backspace" || event.ctrlKey) {
        return;
      }
      if (
        !allowedKeyRegex.test(keyPressed) ||
        event.target?.value?.length >= 15
      ) {
        event.preventDefault();
      }
    },
    setSelectedExp({ value }) {
      this.selectedExp = value;
    },
    getDateDiff(a, b) {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;

      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

      const daysDiff = Math.floor((utc2 - utc1) / _MS_PER_DAY);
      // console.log({monthsDiff: Math.floor((daysDiff % 365 / 30)), yearsDiff: Math.floor(daysDiff / 365 )});
      return {
        monthsDiff: Math.floor((daysDiff % 365) / 30),
        yearsDiff: Math.floor(daysDiff / 365),
      };
    },

    checkDate(start, end) {
      if (new Date(start) > new Date(end)) {
        return "invalid";
      } else {
        return "valid";
      }
    },
    createWorkExperience() {
      this.userProfileImages.workExperiences.push({
        name: "",
        t1: "",
        t2: "",
        t3: "",
        t4: "",
        gosi_certificate: "",
      });
    },
    toggleTab(direction) {
      let tabs = document.querySelectorAll("#myTab .nav-link");
      let nextActive = 0;
      tabs.forEach((tab, index) => {
        if (tab.classList.contains("active")) {
          nextActive = direction === "next" ? index + 1 : index - 1;
        }
      });
      tabs[nextActive].click();
      this.currentActiveTab = nextActive;
    },
    selectTab(index) {
      let activeTab = document.querySelectorAll("#myTab .nav-link.active");
      activeTab.classList.remove("active");
      this.currentActiveTab = index;
    },
    // nextTab() {
    //   let tabs = document.querySelectorAll("#myTab .nav-link");
    //   let nextActive = 0;
    //   tabs.forEach((tab, index) => {
    //     if (tab.classList.contains("active") && index < tabs.length - 1) {
    //       nextActive = index + 1;
    //     }
    //   });
    //   tabs[nextActive].click();
    //   this.currentActiveTab = nextActive;
    // },
    // prevTab() {},
    maxHundred(e, measure = true) {
      if (measure) {
        if (
          isNaN(e.data) ||
          parseFloat(this.userProfile.degreeOfMeasurement) > 100
        ) {
          this.userProfile.degreeOfMeasurement = this.userProfile.degreeOfMeasurement.substr(
            0,
            this.userProfile.degreeOfMeasurement.length - 1
          );
          return;
        }
      }

      if (
        isNaN(e.data) ||
        parseFloat(this.userProfile.degreeOfAchievement) > 100
      ) {
        this.userProfile.degreeOfAchievement = this.userProfile.degreeOfAchievement.substr(
          0,
          this.userProfile.degreeOfAchievement.length - 1
        );
        return;
      }
    },
    tabContainErrors(tabs) {
      if (tabs.length && this.validationErrors)
        return tabs.some((tab) =>
          Object.keys(this.validationErrors)
            .join(",")
            .includes(tab)
        );
    },
    workExperienceCurrent(e, i) {
      if (e.target.checked)
        this.userProfileImages.workExperiences[i].t4 = "current";
      else this.userProfileImages.workExperiences[i].t4 = "";
    },
    checkInputNumber(e) {
      if (e.key.toLowerCase() === "e") {
        e.preventDefault();
      }
    },
    checkIDNumber(e) {
      if (e.key.toLowerCase() === "e") {
        e.preventDefault();
      }

      if (e.key === "0" && e.target.value.length === 0) {
        e.preventDefault();
      }
      if (isNaN(e.data) || this.userProfile.id_number.length >= 9)
        this.userProfile.id_number = this.userProfile.id_number.substr(0, 9);
    },
    checkDegree(e, i, degree = false) {
      const qualifications = this.userProfile.qualifications[i];

      // Parse input values
      let degreeValue = parseFloat(qualifications.degree) || 0;
      let finalDegreeValue = parseFloat(qualifications.finalDegree) || 0;

      // Ensure final degree does not exceed 100
      if (finalDegreeValue > 100) {
        qualifications.finalDegree = finalDegreeValue.toString().substr(0, 3);
        finalDegreeValue = 100;
      }

      // Prevent typing if degree exceeds final degree
      if (degreeValue > finalDegreeValue) {
        qualifications.degree = qualifications.degree.substr(
          0,
          qualifications.degree.length - 1
        );
        return;
      }

      // Allow only two decimal places for degree
      if (
        qualifications.degree.includes(".") &&
        qualifications.degree.split(".")[1].length > 2
      ) {
        qualifications.degree = parseFloat(qualifications.degree).toFixed(2);
      }

      // If the flag degree is false, reset the degree to 0
      if (!degree) {
        qualifications.degree = 0;
      }
    },
    checkDegree2(e, i, degree = false) {
      if (
        (isNaN(e.data) && e.data !== ".") ||
        parseFloat(this.userProfile.qualifications[i].degree) >
          parseFloat(this.userProfile.qualifications[i].finalDegree)
      ) {
        this.userProfile.qualifications[
          i
        ].degree = this.userProfile.qualifications[i].degree.substr(
          0,
          this.userProfile.qualifications[i].degree.length - 1
        );
        return;
      }

      if (
        (isNaN(e.data) && e.data !== ".") ||
        parseFloat(this.userProfile.qualifications[i].finalDegree) > 100
      ) {
        this.userProfile.qualifications[
          i
        ].finalDegree = this.userProfile.qualifications[i].finalDegree.substr(
          0,
          this.userProfile.qualifications[i].finalDegree.length - 1
        );
        return;
      }

      if (!degree) this.userProfile.qualifications[i].degree = 0;
      else {
        if (
          parseFloat(this.userProfile.qualifications[i].degree) >
          parseFloat(this.userProfile.qualifications[i].finalDegree)
        ) {
          this.userProfile.qualifications[i].degree = 0;
        } else if (
          (this.userProfile.qualifications[i].finalDegree == 100 &&
            this.userProfile.qualifications[i].degree > 9 &&
            this.userProfile.qualifications[i].degree <= 100) ||
          (this.userProfile.qualifications[i].finalDegree > 10 &&
            this.userProfile.qualifications[i].degree > 9 &&
            this.userProfile.qualifications[i].degree <
              this.userProfile.qualifications[i].finalDegree) ||
          (this.userProfile.qualifications[i].finalDegree < 10 &&
            this.userProfile.qualifications[i].degree > 0 &&
            this.userProfile.qualifications[i].degree <
              this.userProfile.qualifications[i].finalDegree)
        ) {
          if (this.userProfile.qualifications[i].degree.indexOf(".") === -1)
            this.userProfile.qualifications[i].degree += ".";
        }

        if (
          this.userProfile.qualifications[i].degree.split(".").length > 1 &&
          this.userProfile.qualifications[i].degree.split(".")[1].length > 2
        )
          this.userProfile.qualifications[i].degree = parseFloat(
            this.userProfile.qualifications[i].degree
          ).toFixed(2);
      }
    },
    addQualification() {
      this.otherUniversity.push("");
      this.userProfile.qualifications.push({
        specialist: "",
        other_specialist: "",
        university: "",
        degree: "",
        finalDegree: "",
        grade: "",
        qualification: "",
        graduated_at: "",
        certificate_photo: "",
        record_photo: "",
        equivalency_photo: "",
      });
    },
    update() {
      this.$store.dispatch("refresh_user");

      if (!this.isLoggedIn) {
        this.$router.push("/login");

        return;
      }
      this.loading = true;
      if (this.userProfile.city === "Other")
        this.userProfile.city = this.otherCity;
      this.userProfile.qualifications.map((x, i) => {
        if (x.university === "Other") x.university = this.otherUniversity[i];
        // if (x.specialist === 'Other') x.specialist = this.otherSpecialist[i];
      });

      let data = this.userProfile;
      data["newWorkExperience"] = this.getWorkExperiences;
      console.log(data["newWorkExperience"]);
      data["months_experience"] =
        this.getWorkExperiences.years * 12 + this.getWorkExperiences.months;

      data["photos"] = this.userProfileImages;

      if (this.selectedExp === "fresh") {
        data["months_experience"] = 0;
        data["photos"]["workExperiences"] = [];
      } else {
        if (
          data["photos"]["workExperiences"].length === 0 ||
          !data["photos"]["workExperiences"][0]?.t1
        ) {
          this.validationErrors["photos.workExperiences"] = [
            "The at least one work experience  field is required.",
          ];

          this.loading = false;
          return;
        }
      }

      data?.qualifications.forEach((q) => {
        if (!this.checkQualification(q.qualification)) {
          q.specialist = "";
          q.university = "";
        }
      });

      this.validationErrors = [];
      axios
        .post("website/user/profile", data)
        .then((response) => {
          if (response.data.success) {
            this.$store.dispatch("refresh_user", response.data.data);

            if (
              Object.values(this.cities).indexOf(this.userProfile.city) === -1
            ) {
              this.otherCity = this.userProfile.city;
              this.userProfile.city = "Other";
            }
            this.userProfile.qualifications.map((x) => {
              this.otherUniversity.push(x.university);
              if (Object.values(this.universities).indexOf(x.university) === -1)
                x.university = "Other";
            });
          }
          this.loading = false;
          this.$notify({
            group: "notify",
            type: "success",
            title: this.$i18n.t("Success"),
            text: this.$i18n.t("Profile updated successfully"),
          });
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            this.$notify({
              group: "notify",
              type: "error",
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("Session Expired"),
            });
            this.$store.dispatch("logout").then(() => {
              this.$router.push("/login");
            });
            return;
          }
          this.validationErrors = error.response.data.errors;
          this.loading = false;
          this.$notify({
            group: "notify",
            type: "error",
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("Validation errors"),
          });
        });
    },
    // changeAvatar() {
    //   this.loading = true;
    //   let file = document.getElementById("avatar").files[0];

    //   let formData = new FormData();
    //   formData.append("file", file);
    //   formData.append("folder", "users/" + this.user.id + "/avatar");
    //   axios
    //     .post("uploader", formData)
    //     .then((response) => {
    //       if (response.data.success) {
    //         this.userProfile.avatar = response.data.data;
    //         // this.update();
    //       }
    //       this.loading = false;
    //     })
    //     .catch(() => {
    //       this.loading = false;
    //     });
    // },
    changeAvatar() {
      (this.imageNotValid = false), (this.loading = true);
      let fileInput = document.getElementById("avatar");
      let file = fileInput.files[0];
      if (!file) {
        this.loading = false;
        return;
      }
      if (!file.type.startsWith("image/")) {
        this.loading = false;
        this.imageNotValid = true;
        return;
      }

      let formData = new FormData();
      formData.append("file", file);
      formData.append("folder", "users/" + this.user.id + "/avatar");

      axios
        .post("uploader", formData)
        .then((response) => {
          if (response.data.success) {
            this.userProfile.avatar = response.data.data;

            // this.update();
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    isPdf(url) {
      const isPdf = url.toLocaleLowerCase().endsWith(".pdf");
      if (isPdf) return true;
      return false;
    },
    getUrl(name = "") {
      const gaddUrl = window.gadd_url ?? "https://api-career.gadd.gov.sa";
      const ncmsUrl = window.ncms_url ?? "https://jas.ncms.sa";
      const BASEURL = window.BASEURL ?? gaddUrl;

      let { other_domain, other_domain_url } = this.userProfile || {};

      if (name == null || name === "") return "";
      if (name.startsWith("http")) return name.replace("public", "storage");
      let url = name.replace("public", "storage");
      if (name.includes("gadd/")) {
        return gaddUrl + "/" + url;
      }
      if (name.includes("ncms/")) {
        return ncmsUrl + "/" + url;
      }

      return BASEURL + "/" + url;
    },
    upload(e, type = "id", i = -1) {
      this.loading = true;
      let file;
      if (i === -1) file = document.getElementById("photo-" + type).files[0];
      else file = document.getElementById("photo-" + type + "-" + i).files[0];

      let formData = new FormData();
      formData.append("file", file);
      formData.append(
        "folder",
        "users/" +
          this.user.id +
          "/" +
          (type !== "gosi_certificate" ? type : "workExperiences")
      );
      axios
        .post("uploader", formData)
        .then((response) => {
          if (response.data.success) {
            if (type === "pdf") this.userProfile.cv = response.data.data;
            else if (type === "certificates")
              this.userProfile.qualifications[i].certificate_photo =
                response.data.data;
            else if (type === "records")
              this.userProfile.qualifications[i].record_photo =
                response.data.data;
            else if (type === "equivalency")
              this.userProfile.qualifications[i].equivalency_photo =
                response.data.data;
            else if (type === "trainingCourses") {
              this.userProfileImages[type][i].name = response.data.data;
            } else if (
              type === "workExperiences" ||
              type === "gosi_certificate"
            ) {
              if (type === "gosi_certificate") {
                this.userProfileImages["workExperiences"][i][type] =
                  response.data.data;
              } else {
                this.userProfileImages[type][i].name = response.data.data;
              }
            } else this.userProfileImages[type] = response.data.data;

            // this.update();
          }
          this.loading = false;
        })
        .catch((error) => {
          if (typeof error?.response?.data?.errors?.file !== "undefined") {
            this.$notify({
              group: "notify",
              type: "error",
              title: this.$i18n.t("Error"),
              text: this.$i18n.t(error?.response?.data?.errors?.file),
            });
            this.validationErrors["photos.pdf"] =
              error.response.data.errors.file;
          } else {
            this.validationErrors["photos.pdf"] = this.$i18n.t(
              "Failed to upload the file"
            );
          }
          this.loading = false;
        });
    },
  },
};
</script>

<template>
  <div id="jobs">
    <Navbar></Navbar>

    <main class="page-wrapper single-job">
      <div class="alert alert-danger text-center" v-if="!user.profileFilled">
        <!-- {{ $i18n.t('Fill Profile') }} -->
        {{ $i18n.t("Fill Profile data") }}
      </div>
      <div class="alert alert-success text-center" v-else>
        {{ $i18n.t("requirement-filled") }}
      </div>
      <div class="alert alert-warning text-center" v-if="user.update_cv">
        {{ $i18n.t("update_cv") }}
      </div>

      <div class="card-body container-control">
        <div class=" pt-3">
          <div class="row flex-reverse patient-profile">
            <div class="col-12">
              <div class="container">
                <div class="user-card card-info row">
                  <div class="col-12 col-lg-3 col-md-12 box-prfole ">
                    <div class="d-flex align-center justify-center">
                      <div>
                        <div class="text-center mb-2">
                          <img
                            class="user-card__img img-fluid img-circle"
                            :src="avatarUrl"
                            :alt="$i18n.t('User profile picture')"
                            style="cursor: pointer; min-width: 150px; min-height: 150px; max-width: 150px; max-height: 150px; border: 1px solid rgba(228, 230, 232, 1); border-radius: 50%"
                            onclick="document.getElementById('avatar').click()"
                          />
                          <input
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            id="avatar"
                            style="position: absolute; opacity: 0; z-index: -1"
                            @change="changeAvatar"
                          />
                        </div>
                        <!-- <i class="text-danger" v-if="!userProfile.avatar"
                          >* {{ $i18n.t("Personal photo is required") }}</i
                        > -->
                        <i
                          class="text-danger mx-2"
                          v-if="!!validationErrors['avatar']"
                          >{{ $i18n.t(validationErrors["avatar"][0]) }}</i
                        >
                        <i class="text-danger" v-if="imageNotValid"
                          >* {{ $i18n.t("Please select only image") }}</i
                        >
                      </div>
                      <div
                        class="d-flex flex-column mx-2"
                        style="justify-content: center; align-items: center;"
                      >
                        <label class="mb-0" style="color: #8083A3">{{
                          $i18n.t("name")
                        }}</label>
                        <h3
                          class="profile-username text-center mb-0"
                          style="color: #000"
                        >
                          {{ user.name }}
                        </h3>

                        <!--                        <p class="text-center text-sec-color">{{ user.email }}</p>-->
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-9 col-md-12 d-flex align-center">
                    <div class="row w-100" style="align-items: center">
                      <div
                        class="col-12 col-xl-3 col-lg-6 col-md-6 col-sm-8 mb-2"
                      >
                        <div
                          class="card-prof d-flex align-center"
                          style="background: #EAECF0; padding: 5px; border-radius: 8px;"
                        >
                          <div class="icon d-flex align-center mx-1">
                            <img src="/images/loc.svg" alt="" width="24" />
                          </div>
                          <div class="content mx-1">
                            <label
                              class="mb-2 font-weight-light"
                              style="text-transform: capitalize; color: #1D2939;"
                              >{{ $i18n.t("address") }}</label
                            >
                            <p class="font-weight-bold" style="color: #1D2939;">
                              {{ user.address }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-12 col-xl-2 col-lg-6 col-md-6 col-sm-4 mb-2"
                      >
                        <div
                          class="card-prof d-flex align-center"
                          style="background: #EAECF0; padding: 5px; border-radius: 8px;"
                        >
                          <div class="icon d-flex align-center mx-1">
                            <img src="/images/gender.svg" alt="" width="24" />
                          </div>
                          <div class="content mx-1">
                            <label
                              class="mb-2 font-weight-light"
                              style="text-transform: capitalize; color: #1D2939;"
                              >{{ $i18n.t("gender") }}</label
                            >
                            <p class="font-weight-bold" style="color: #1D2939;">
                              {{ user.gender ? $i18n.t(`${user.gender}`) : "" }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-12 col-xl-4 col-lg-6 col-md-6 col-sm-8 mb-2"
                      >
                        <div
                          class="card-prof d-flex align-center"
                          style="background: #EAECF0; padding: 5px; border-radius: 8px;"
                        >
                          <div class="icon d-flex align-center mx-1">
                            <img src="/images/email.svg" alt="" width="24" />
                          </div>
                          <div class="content mx-1">
                            <label
                              class="mb-2 font-weight-light"
                              style="text-transform: capitalize; color: #1D2939;"
                              >{{ $i18n.t("email") }}</label
                            >
                            <p class="font-weight-bold" style="color: #1D2939;">
                              {{ user.email }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-12 col-xl-3 col-lg-6 col-md-6 col-sm-4 mb-2"
                      >
                        <div
                          class="card-prof d-flex align-center"
                          style="background: #EAECF0; padding: 5px; border-radius: 8px;"
                        >
                          <div class="icon d-flex align-center mx-1">
                            <img src="/images/phone.svg" alt="" width="24" />
                          </div>
                          <div class="content mx-1">
                            <label
                              class="mb-2 font-weight-light"
                              style="text-transform: capitalize; color: #1D2939;"
                              >{{ $i18n.t("phone") }}</label
                            >
                            <p class="font-weight-bold" style="color: #1D2939;">
                              {{ user.phone }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--                  <div class="col-12 col-md-6 user-card__description">-->

                  <!--                    <h3>-->
                  <!--                      {{ user.job || $t("Major") }}-->
                  <!--                    </h3>-->
                  <!--                    <p>-->
                  <!--                      {{ user.bio || $t("Bio Description") + " .." }}-->
                  <!--                    </p>-->
                  <!--                  </div>-->
                  <!--                  <div class="col-12 col-md-3 user-card__action">-->
                  <!--                    <div class="user-card__details">-->
                  <!--                      <p>-->
                  <!--                        <b>{{ $i18n.t("Age") }}</b>-->
                  <!--                        <span class="float-right">{{ age }}</span>-->
                  <!--                      </p>-->
                  <!--                      <p>-->
                  <!--                        <b>{{ $i18n.t("Gender") }}</b>-->
                  <!--                        <span class="float-right">{{ user.gender }}</span>-->
                  <!--                      </p>-->
                  <!--                      <p>-->
                  <!--                        <b>{{ $i18n.t("Phone") }}</b>-->
                  <!--                        <span class="float-right">{{ user.phone }}</span>-->
                  <!--                      </p>-->
                  <!--                      <p>-->
                  <!--                        <b>{{ $i18n.t("Address") }}</b>-->
                  <!--                        <span class="float-right">{{ user.address }}</span>-->
                  <!--                      </p>-->
                  <!--                    </div>-->
                  <!--                    <router-link-->
                  <!--                        to="/applications"-->
                  <!--                        class="input-group-text btn btn-main"-->
                  <!--                    >-->
                  <!--                      <b class="text-center d-block w-100">{{-->
                  <!--                          $i18n.t("My Applications")-->
                  <!--                        }}</b>-->
                  <!--                    </router-link>-->
                  <!--                  </div>-->
                </div>
              </div>
            </div>
            <div class="col-12 mt-4">
              <div
                class="card container"
                style="box-shadow: none; background-color: #fff"
              >
                <div
                  class="card-body row px-0 pb-3 m-0 pt-0"
                  style="background-color: #fff"
                >
                  <div class="d-block w-100 profile-tabs">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <a
                          class="nav-link active"
                          id="personalInfo-tab"
                          data-toggle="tab"
                          href="#personalInfo"
                          role="tab"
                          aria-controls="personalInfo"
                          aria-selected="true"
                          :class="[
                            tabContainErrors([
                              'name',
                              'second_name',
                              'third_name',
                              'fourth_name',
                              'id_number',
                              'phone',
                              'phone_key',
                              'age',
                              'gender',
                              'address',
                              'socialStatus',
                              'city',
                              'photos.id',
                              'cv',
                            ])
                              ? 'tab-error'
                              : '',
                          ]"
                          >{{ $i18n.t("Personal Information") }}
                          <i
                            class="text-danger"
                            v-if="
                              tabContainErrors([
                                'name',
                                'second_name',
                                'third_name',
                                'fourth_name',
                                'id_number',
                                'phone',
                                'phone_key',
                                'age',
                                'gender',
                                'address',
                                'socialStatus',
                                'city',
                                'photos.id',
                                'cv',
                              ])
                            "
                            >*</i
                          >
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          class="nav-link"
                          id="Qualifications-tab"
                          data-toggle="tab"
                          href="#Qualifications"
                          role="tab"
                          aria-controls="Qualifications"
                          aria-selected="false"
                          :class="
                            tabContainErrors(['qualifications'])
                              ? 'tab-error'
                              : ''
                          "
                          >{{ $i18n.t("Qualifications") }}
                          <i
                            class="text-danger"
                            v-if="tabContainErrors(['qualifications'])"
                            >*</i
                          >
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          class="nav-link"
                          id="Courses-tab"
                          data-toggle="tab"
                          href="#Courses"
                          role="tab"
                          aria-controls="Courses"
                          aria-selected="false"
                          :class="
                            tabContainErrors(['photos.trainingCourses'])
                              ? 'tab-error'
                              : ''
                          "
                          >{{ $i18n.t("Training Courses") }}
                          <i
                            class="text-danger"
                            v-if="tabContainErrors(['photos.trainingCourses'])"
                            >*</i
                          >
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          class="nav-link"
                          id="Experiences-tab"
                          data-toggle="tab"
                          href="#Experiences"
                          role="tab"
                          aria-controls="Experiences"
                          aria-selected="false"
                          :class="
                            tabContainErrors(['photos.workExperiences'])
                              ? 'tab-error'
                              : ''
                          "
                          >{{ $i18n.t("Work Experiences") }}
                          <i
                            class="text-danger"
                            v-if="tabContainErrors(['photos.workExperiences'])"
                            >*</i
                          >
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          class="nav-link"
                          id="skills-tab"
                          data-toggle="tab"
                          href="#skills"
                          role="tab"
                          aria-controls="skills"
                          aria-selected="false"
                          :class="
                            tabContainErrors(['skills']) ? 'tab-error' : ''
                          "
                          >{{ $i18n.t("Skills") }}
                          <i
                            class="text-danger"
                            v-if="tabContainErrors(['skills'])"
                            >*</i
                          >
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          class="nav-link"
                          id="Questions-tab"
                          data-toggle="tab"
                          href="#Questions"
                          role="tab"
                          aria-controls="Questions"
                          aria-selected="false"
                          :class="
                            tabContainErrors([
                              'workingPerferedErea',
                              'smoker',
                              'currentSalary',
                              'expectedSalary',
                              'notifyPeriodOnAccept',
                            ])
                              ? 'tab-error'
                              : ''
                          "
                          >{{ $i18n.t("Questions") }}
                          <i
                            class="text-danger"
                            v-if="
                              tabContainErrors([
                                'workingPerferedErea',
                                'smoker',
                                'currentSalary',
                                'expectedSalary',
                                'notifyPeriodOnAccept',
                              ])
                            "
                            >*</i
                          >
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                      <div
                        class="tab-pane fade show active"
                        id="personalInfo"
                        role="tabpanel"
                        aria-labelledby="personalInfo-tab"
                      >
                        <div class="row">
                          <div class="form-group col-md-6 mb-4">
                            <div class="form-group-child">
                              <div class="d-flex justify-content-between">
                                <label class="font-weight-bold" for="name"
                                  >{{ $i18n.t("First Name") }}:</label
                                >
                                <i
                                  class="text-danger"
                                  v-if="!!validationErrors['name']"
                                  >{{ $i18n.t(validationErrors["name"][0]) }}</i
                                >
                              </div>
                              <input
                                type="text"
                                id="name"
                                class="form-control"
                                :class="
                                  !!validationErrors['name'] ? 'is-invalid' : ''
                                "
                                v-model="userProfile.name"
                              />
                            </div>
                          </div>
                          <div class="form-group col-md-6 mb-4">
                            <div class="form-group-child">
                              <div class="input-label-cont">
                                <label
                                  class="font-weight-bold"
                                  for="second_name"
                                  >{{ $i18n.t("Second Name") }}:</label
                                >
                                <i
                                  class="text-danger"
                                  v-if="!!validationErrors['second_name']"
                                  >{{
                                    $i18n.t(validationErrors["second_name"][0])
                                  }}</i
                                >
                              </div>
                              <input
                                type="text"
                                id="second_name"
                                class="form-control"
                                :class="
                                  !!validationErrors['second_name']
                                    ? 'is-invalid'
                                    : ''
                                "
                                v-model="userProfile.second_name"
                              />
                            </div>
                          </div>
                          <div class="form-group col-md-6 mb-4">
                            <div class="form-group-child">
                              <div class="input-label-cont">
                                <label class="font-weight-bold" for="third_name"
                                  >{{ $i18n.t("Third Name") }}:</label
                                >
                                <i
                                  class="text-danger"
                                  v-if="!!validationErrors['third_name']"
                                  >{{
                                    $i18n.t(validationErrors["third_name"][0])
                                  }}</i
                                >
                              </div>
                              <input
                                type="text"
                                id="third_name"
                                class="form-control"
                                :class="
                                  !!validationErrors['third_name']
                                    ? 'is-invalid'
                                    : ''
                                "
                                v-model="userProfile.third_name"
                              />
                            </div>
                          </div>
                          <div class="form-group col-md-6 mb-4">
                            <div class="form-group-child">
                              <div class="input-label-cont">
                                <label
                                  class="font-weight-bold"
                                  for="fourth_name"
                                  >{{ $i18n.t("Family Name") }}:</label
                                >
                                <i
                                  class="text-danger"
                                  v-if="!!validationErrors['fourth_name']"
                                  >{{
                                    $i18n.t(validationErrors["fourth_name"][0])
                                  }}</i
                                >
                              </div>
                              <input
                                type="text"
                                id="fourth_name"
                                class="form-control"
                                :class="
                                  !!validationErrors['fourth_name']
                                    ? 'is-invalid'
                                    : ''
                                "
                                v-model="userProfile.fourth_name"
                              />
                            </div>
                          </div>

                          <div class="form-group col-md-6 mb-4">
                            <div class="form-group-child">
                              <div class="input-label-cont">
                                <label class="font-weight-bold" for="id_number"
                                  >{{ $i18n.t("ID Number") }}:</label
                                >
                                <i
                                  class="text-danger"
                                  v-if="!!validationErrors['id_number']"
                                  >{{
                                    $i18n.t(validationErrors["id_number"][0])
                                  }}</i
                                >
                              </div>
                              <input
                                type="number"
                                @keydown="checkIDNumber($event)"
                                id="id_number"
                                class="form-control input-number"
                                :class="
                                  !!validationErrors['id_number']
                                    ? 'is-invalid'
                                    : ''
                                "
                                v-model="userProfile.id_number"
                              />
                            </div>
                          </div>
                          <div class="form-group col-md-6 mb-4">
                            <div class="form-group-child">
                              <div class="input-label-cont">
                                <label class="font-weight-bold"
                                  >{{ $i18n.t("Phone") }}:</label
                                >
                                <i
                                  class="text-danger"
                                  v-if="!!validationErrors['phone']"
                                  >{{
                                    $i18n.t(validationErrors["phone"][0])
                                  }}</i
                                >
                              </div>
                              <div>
                                <vue-phone-number-input
                                  v-model="userProfile.phone"
                                  :class="
                                    !!validationErrors['phone']
                                      ? 'phone-error'
                                      : !!validationErrors['phone_key']
                                      ? 'phone_key-error'
                                      : ''
                                  "
                                  @input="handlePhone"
                                  default-country-code="SA"
                                  @update="
                                    (x) => {
                                      userProfile.phone_key = x.countryCode;
                                    }
                                  "
                                  :ignored-countries="['IL']"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="form-group col-md-6 mb-4">
                            <div class="form-group-child">
                              <div class="input-label-cont">
                                <label class="font-weight-bold"
                                  >{{ $i18n.t("Date of birth") }}:</label
                                >
                                <i
                                  class="text-danger"
                                  v-if="!!validationErrors['age']"
                                  >{{ $i18n.t(validationErrors["age"][0]) }}</i
                                >
                              </div>
                              <v-date-picker
                                color="orange"
                                :max-date="disabledDates"
                                v-model="userProfile.age"
                                locale="ar"
                                disab
                              >
                                <template v-slot="{ inputValue, inputEvents }">
                                  <input
                                    class="form-control"
                                    :class="
                                      !!validationErrors['age']
                                        ? 'is-invalid'
                                        : ''
                                    "
                                    :value="inputValue"
                                    v-on="inputEvents"
                                  />
                                </template>
                              </v-date-picker>
                            </div>
                          </div>
                          <div
                            class="form-group col-md-6 mb-4"
                            :class="
                              !!validationErrors['nationality']
                                ? 'select-error'
                                : ''
                            "
                          >
                            <div class="form-group-child">
                              <div class="input-label-cont">
                                <label
                                  class="font-weight-bold"
                                  for="nationality"
                                  >{{ $i18n.t("Nationality") }}:</label
                                >
                                <i
                                  class="text-danger"
                                  v-if="!!validationErrors['nationality']"
                                  >{{
                                    $i18n.t(validationErrors["nationality"][0])
                                  }}</i
                                >
                              </div>
                              <v-select
                                id="nationality"
                                v-model="userProfile.nationality"
                                label="label"
                                :options="transCountries"
                                :reduce="(country) => country.country"
                              >
                              </v-select>
                            </div>
                          </div>
                          <div class="form-group col-md-6 mb-4">
                            <div class="form-group-child">
                              <div class="input-label-cont">
                                <label class="font-weight-bold" for="city"
                                  >{{ $i18n.t("City") }}:</label
                                >
                                <i
                                  class="text-danger"
                                  v-if="!!validationErrors['city']"
                                  >{{ $i18n.t(validationErrors["city"][0]) }}</i
                                >
                              </div>
                              <v-select
                                id="city"
                                v-model="userProfile.city"
                                label="label"
                                :options="transCities"
                                :reduce="(city) => city.city"
                              />
                              <input
                                type="text"
                                class="form-control mt-2"
                                :class="
                                  !!validationErrors['city'] ? 'is-invalid' : ''
                                "
                                :style="{
                                  display:
                                    userProfile.city === 'Other'
                                      ? 'block'
                                      : 'none',
                                }"
                                v-model="otherCity"
                                id="otherCity"
                              />
                            </div>
                          </div>
                          <div class="form-group col-md-6 mb-4">
                            <div class="form-group-child">
                              <div class="input-label-cont">
                                <label class="font-weight-bold" for="address"
                                  >{{ $i18n.t("Address") }}:</label
                                >
                                <i
                                  class="text-danger"
                                  v-if="!!validationErrors['address']"
                                  >{{
                                    $i18n.t(validationErrors["address"][0])
                                  }}</i
                                >
                              </div>
                              <input
                                type="text"
                                id="address"
                                class="form-control"
                                :class="
                                  !!validationErrors['address']
                                    ? 'is-invalid'
                                    : ''
                                "
                                v-model="userProfile.address"
                              />
                            </div>
                          </div>
                          <div class="form-group col-md-6 mb-4">
                            <div class="form-group-child">
                              <div class="input-label-cont">
                                <label class="font-weight-bold "
                                  >{{ $i18n.t("Social Status") }}:</label
                                >
                                <i
                                  class="text-danger"
                                  v-if="!!validationErrors['socialStatus']"
                                  >{{
                                    $i18n.t(validationErrors["socialStatus"][0])
                                  }}</i
                                >
                              </div>
                              <v-select
                                id="social"
                                v-model="userProfile.socialStatus"
                                :options="socialStatus"
                                label="name"
                                :reduce="(item) => item.value"
                              ></v-select>

                              <!--                              <label class="col-md-6">-->
                              <!--                                <input-->
                              <!--                                    type="radio"-->
                              <!--                                    value="single"-->
                              <!--                                    name="socialStatus"-->
                              <!--                                    v-model="userProfile.socialStatus"-->
                              <!--                                />-->
                              <!--                                {{ $i18n.t("Single") }}-->
                              <!--                              </label>-->
                              <!--                              <label class="col-md-6">-->
                              <!--                                <input-->
                              <!--                                    type="radio"-->
                              <!--                                    value="married"-->
                              <!--                                    name="socialStatus"-->
                              <!--                                    v-model="userProfile.socialStatus"-->
                              <!--                                />-->
                              <!--                                {{ $i18n.t("Married") }}-->
                              <!--                              </label>-->
                              <!-- <i
                                class="text-danger"
                                v-if="!!validationErrors['socialStatus']"
                                >{{ validationErrors["socialStatus"][0] }}</i
                              > -->
                            </div>
                          </div>

                          <div class="form-group col-md-6 mb-4 ">
                            <div class="form-group-child">
                              <div class="input-label-cont ">
                                <label class="font-weight-bold "
                                  >{{ $i18n.t("Gender") }}:</label
                                >
                                <i
                                  class="text-danger"
                                  v-if="!!validationErrors['gender']"
                                  >{{
                                    $i18n.t(validationErrors["gender"][0])
                                  }}</i
                                >
                              </div>
                              <v-select
                                id="social"
                                v-model="userProfile.gender"
                                :options="genders"
                                :reduce="(item) => item.value"
                                label="name"
                              ></v-select>
                            </div>
                            <!--                            <label class="col-md-6">-->
                            <!--                              <input-->
                            <!--                                  type="radio"-->
                            <!--                                  value="male"-->
                            <!--                                  name="gender"-->
                            <!--                                  v-model="userProfile.gender"-->
                            <!--                              />-->
                            <!--                              {{ $i18n.t("Male") }}-->
                            <!--                            </label>-->
                            <!--                            <label class="col-md-6">-->
                            <!--                              <input-->
                            <!--                                  type="radio"-->
                            <!--                                  value="female"-->
                            <!--                                  name="gender"-->
                            <!--                                  v-model="userProfile.gender"-->
                            <!--                              />-->
                            <!--                              {{ $i18n.t("Female") }}-->
                            <!--                            </label>-->
                            <!-- <i
                              class="text-danger"
                              v-if="!!validationErrors['gender']"
                              >{{ validationErrors["gender"][0] }}</i
                            > -->
                          </div>

                          <div class="form-group col-md-6 mb-4">
                            <div class="form-group-child">
                              <label
                                class="font-weight-bold"
                                for="degreeOfMeasurement"
                                >{{ $i18n.t("Degree of Measurement") }}:</label
                              >
                              <input
                                type="text"
                                id="degreeOfMeasurement"
                                class="form-control"
                                :class="
                                  !!validationErrors['degreeOfMeasurement']
                                    ? 'is-invalid'
                                    : ''
                                "
                                v-model="userProfile.degreeOfMeasurement"
                                @input="maxHundred($event)"
                              />
                            </div>
                          </div>
                          <div class="form-group col-md-6 mb-4">
                            <div class="form-group-child">
                              <div class="input-label-cont">
                                <label class="font-weight-bold" for="address"
                                  >{{
                                    $i18n.t("Degree of Achievement")
                                  }}:</label
                                >
                                <i
                                  class="text-danger"
                                  v-if="
                                    !!validationErrors['degreeOfAchievement']
                                  "
                                  >{{
                                    $i18n.t(
                                      validationErrors["degreeOfAchievement"][0]
                                    )
                                  }}</i
                                >
                              </div>
                              <input
                                type="text"
                                id="degreeOfAchievement"
                                :class="
                                  !!validationErrors['degreeOfAchievement']
                                    ? 'is-invalid'
                                    : ''
                                "
                                class="form-control"
                                v-model="userProfile.degreeOfAchievement"
                                @input="maxHundred($event, false)"
                              />
                            </div>
                          </div>

                          <!--                          Upload Divs-->
                          <div
                            class="form-group col-12 col-lg-6 col-md-6 mb-4 d-flex justify-content-between align-items-center"
                          >
                            <div class="form-group-child">
                              <div class="d-flex flex-column">
                                <div>
                                  <label
                                    class="font-weight-bold mb-0"
                                    for="photo-id"
                                    >{{
                                      $i18n.t("A copy of the ID card")
                                    }}:</label
                                  >
                                  <span class="mx-2">
                                    * ({{ $i18n.t("required_img_pdf") }})
                                    (2048kb)
                                  </span>
                                </div>
                                <i
                                  class="text-danger mx-2"
                                  v-if="!!validationErrors['photos.id']"
                                  >{{ $i18n.t("A copy of the ID card") }}</i
                                >
                              </div>
                              <div
                                class="d-flex justify-content-between align-center"
                              >
                                <button
                                  class="btn icon font-weight-bold"
                                  onclick="document.getElementById('photo-id').click()"
                                  style="direction: ltr;"
                                >
                                  <span v-if="userProfileImages.id">
                                    {{ userProfileImages.id.split("id/")[1] }}
                                  </span>
                                  <img
                                    width="25"
                                    src="/images/default-upload.png"
                                    alt=""
                                    v-else
                                  />
                                </button>

                                <div v-if="userProfileImages.id">
                                  <a
                                    v-if="isPdf(getUrl(userProfileImages.id))"
                                    :href="getUrl(userProfileImages.id)"
                                    style="cursor: pointer"
                                    class="ml-2"
                                    target="_blank"
                                  >
                                    <img src="@/assets/pdf.svg" width="30" />
                                  </a>
                                  <img
                                    v-else
                                    class="userProfileImage"
                                    :src="getUrl(userProfileImages.id)"
                                    style="cursor: pointer"
                                  />
                                </div>
                              </div>
                              <input
                                type="file"
                                accept=".jpg, .jpeg, .png, .pdf"
                                id="photo-id"
                                style="
                                position: absolute;
                                opacity: 0;
                                z-index: -1;
                              "
                                @change="upload($event, 'id')"
                              />
                            </div>
                          </div>

                          <div
                            class="form-group col-12 col-lg-6 col-md-6 mb-4 d-flex justify-content-between align-items-center"
                          >
                            <div class="form-group-child">
                              <div class="d-flex flex-column">
                                <div>
                                  <label
                                    class="font-weight-bold mb-0"
                                    :onclick="
                                      'document.getElementById(\'photo-pdf\').click()'
                                    "
                                    style="cursor: pointer"
                                    >{{
                                      $i18n.t("Click to upload/change CV")
                                    }}:</label
                                  >
                                  <span class="mx-2">
                                    * ({{ $i18n.t("required_pdf") }}) (2048kb)
                                  </span>
                                </div>
                                <i
                                  class="text-danger mx-2"
                                  v-if="!!validationErrors['cv']"
                                  >{{ $i18n.t("cv_required") }}</i
                                >
                              </div>
                              <div
                                class="d-flex justify-content-between align-center"
                              >
                                <button
                                  class="btn icon font-weight-bold"
                                  onclick="document.getElementById('photo-pdf').click()"
                                  style="direction: ltr;"
                                >
                                  <span v-if="userProfile.cv">
                                    {{ userProfile.cv.split("pdf/")[1] }}
                                  </span>
                                  <img
                                    width="25"
                                    src="/images/default-upload.png"
                                    alt=""
                                    v-else
                                  />
                                </button>
                                <a
                                  v-if="userProfile.cv"
                                  :href="getUrl(userProfile.cv)"
                                  style="cursor: pointer"
                                  class="ml-2"
                                  target="_blank"
                                >
                                  <img src="@/assets/pdf.svg" width="30" />
                                </a>
                              </div>
                              <input
                                type="file"
                                accept=".pdf"
                                :id="'photo-pdf'"
                                style="
                                position: absolute;
                                opacity: 0;
                                z-index: -1;
                              "
                                @change="upload($event, 'pdf')"
                              />
                            </div>
                          </div>
                          <!---->

                          <div class="form-group col-md-6 mb-4">
                            <div class="form-group-child">
                              <label class="font-weight-bold" for="bio"
                                >{{ $i18n.t("Bio Description") }}:</label
                              >
                              <textarea
                                id="bio"
                                class="form-control"
                                style="max-height: 200px"
                                v-model="userProfile.bio"
                              ></textarea>
                              <i
                                class="text-danger"
                                v-if="!!validationErrors['bio']"
                                >{{ validationErrors["bio"][0] }}</i
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="Qualifications"
                        role="tabpanel"
                        aria-labelledby="Qualifications-tab"
                      >
                        <div class="row mb-3">
                          <label class="font-weight-bold col-12"
                            >{{ $i18n.t("َQualifications") }}:</label
                          >
                          <div
                            v-for="(qualification,
                            i) in userProfile.qualifications"
                            class=""
                            style="display: contents"
                            :key="'qualification-' + i"
                          >
                            <div
                              class="form-group col-md-4"
                              :class="
                                !!validationErrors[
                                  'qualifications.' + i + '.qualification'
                                ]
                                  ? 'select-error'
                                  : ''
                              "
                            >
                              <div class="form-group-child">
                                <label
                                  class="font-weight-bold"
                                  for="qualification"
                                  >{{ $i18n.t("Qualification") }}:</label
                                >
                                <v-select
                                  id="qualification"
                                  v-model="
                                    userProfile.qualifications[i].qualification
                                  "
                                  :options="[
                                    $i18n.t('Primary'),
                                    $i18n.t('Intermediate'),
                                    $i18n.t('Secondary'),
                                    $i18n.t('Industrial High School'),
                                    $i18n.t('Diploma'),
                                    $i18n.t('Bachelor'),
                                    $i18n.t('M.A.'),
                                    $i18n.t('PhD'),
                                  ]"
                                />
                              </div>
                            </div>
                            <div
                              v-if="
                                checkQualification(
                                  userProfile.qualifications[i].qualification,
                                  i
                                )
                              "
                              class="form-group col-md-4"
                              :class="
                                !!validationErrors[
                                  'qualifications.' + i + '.specialist'
                                ]
                                  ? 'select-error'
                                  : ''
                              "
                            >
                              <div class="form-group-child">
                                <div class="input-label-cont">
                                  <label
                                    class="font-weight-bold"
                                    for="specialist"
                                    >{{ $i18n.t("Major") }}:</label
                                  >
                                  <i
                                    class="text-danger mx-2"
                                    v-if="
                                      !!validationErrors[
                                        'qualifications.' + i + '.specialist'
                                      ]
                                    "
                                    >{{
                                      $i18n.t(
                                        validationErrors[
                                          "qualifications." + i + ".specialist"
                                        ][0]
                                      )
                                    }}</i
                                  >
                                </div>
                                <v-select
                                  id="specialist"
                                  v-model="
                                    userProfile.qualifications[i].specialist
                                  "
                                  :options="specialists"
                                />
                              </div>
                            </div>
                            <div
                              class="form-group col-md-4 mb-4"
                              v-if="
                                userProfile.qualifications[i].specialist ===
                                  'Other'
                              "
                            >
                              <div class="form-group-child">
                                <label class="font-weight-bold" for="job"
                                  >{{ $i18n.t("Other Major") }}:</label
                                >
                                <input
                                  type="text"
                                  id="job"
                                  class="form-control"
                                  :class="
                                    !!validationErrors[
                                      'qualifications.' +
                                        i +
                                        '.other_specialist'
                                    ]
                                      ? 'is-invalid'
                                      : ''
                                  "
                                  v-model="
                                    userProfile.qualifications[i]
                                      .other_specialist
                                  "
                                />
                              </div>
                            </div>
                            <div
                              v-if="
                                checkQualification(
                                  userProfile.qualifications[i].qualification,
                                  i
                                )
                              "
                              class="form-group col-md-4"
                              :class="
                                !!validationErrors[
                                  'qualifications.' + i + '.university'
                                ]
                                  ? 'select-error'
                                  : ''
                              "
                            >
                              <div class="form-group-child">
                                <div class="input-label-cont">
                                  <label
                                    class="font-weight-bold"
                                    for="university"
                                    >{{ $i18n.t("Graduate From") }}:</label
                                  >
                                  <i
                                    class="text-danger mx-2"
                                    v-if="
                                      !!validationErrors[
                                        'qualifications.' + i + '.university'
                                      ]
                                    "
                                    >{{
                                      $i18n.t(
                                        validationErrors[
                                          "qualifications." + i + ".university"
                                        ][0]
                                      )
                                    }}</i
                                  >
                                </div>
                                <v-select
                                  id="university"
                                  v-model="
                                    userProfile.qualifications[i].university
                                  "
                                  :options="universities"
                                />
                                <input
                                  type="text"
                                  class="form-control mt-2"
                                  :class="
                                    !!validationErrors[
                                      'qualifications.' + i + '.university'
                                    ]
                                      ? 'is-invalid'
                                      : ''
                                  "
                                  :style="{
                                    display:
                                      userProfile.qualifications[i]
                                        .university === 'Other'
                                        ? 'block'
                                        : 'none',
                                  }"
                                  v-model="otherUniversity[i]"
                                  id="otherUniversity"
                                />
                              </div>
                            </div>

                            <div
                              class="form-group col-md-4"
                              :class="
                                !!validationErrors[
                                  'qualifications.' + i + '.finalDegree'
                                ]
                                  ? 'select-error'
                                  : ''
                              "
                            >
                              <div class="form-group-child">
                                <label
                                  class="font-weight-bold"
                                  for="finalDegree"
                                  >{{ $i18n.t("GPA From") }}:</label
                                >

                                <select
                                  id="finalDegree"
                                  class="form-control"
                                  style="border: none;"
                                  v-model="
                                    userProfile.qualifications[i].finalDegree
                                  "
                                  @change="checkDegree($event, i)"
                                >
                                  <option value="100">100</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                </select>
                              </div>
                            </div>
                            <div class="form-group col-md-4">
                              <div class="form-group-child">
                                <div class="input-label-cont">
                                  <label class="font-weight-bold" for="degree"
                                    >{{ $i18n.t("GPA") }}:</label
                                  >
                                  <i
                                    class="text-danger"
                                    v-if="
                                      !!validationErrors[
                                        'qualifications.' + i + '.degree'
                                      ]
                                    "
                                    >{{
                                      $i18n.t(
                                        validationErrors[
                                          "qualifications." + i + ".degree"
                                        ][0]
                                      )
                                    }}</i
                                  >
                                </div>
                                <input
                                  type="text"
                                  id="degree"
                                  :class="
                                    !!validationErrors[
                                      'qualifications.' + i + '.degree'
                                    ]
                                      ? 'is-invalid'
                                      : ''
                                  "
                                  class="form-control"
                                  v-model="userProfile.qualifications[i].degree"
                                  @input="checkDegree($event, i, true)"
                                />
                              </div>
                            </div>
                            <div class="form-group col-md-4 mb-3">
                              <div class="form-group-child">
                                <div class="input-label-cont">
                                  <label class="">{{
                                    $i18n.t("Graduation date")
                                  }}</label>
                                  <i
                                    class="text-danger mx-2"
                                    v-if="
                                      !!validationErrors[
                                        'qualifications.' + i + '.graduated_at'
                                      ]
                                    "
                                    >{{
                                      $i18n.t(
                                        validationErrors[
                                          "qualifications." +
                                            i +
                                            ".graduated_at"
                                        ][0]
                                      )
                                    }}</i
                                  >
                                </div>
                                <v-date-picker
                                  color="orange"
                                  v-model="
                                    userProfile.qualifications[i].graduated_at
                                  "
                                >
                                  <template
                                    v-slot="{ inputValue, inputEvents }"
                                  >
                                    <input
                                      class="form-control"
                                      :class="
                                        !!validationErrors[
                                          'qualifications.' +
                                            i +
                                            '.graduated_at'
                                        ]
                                          ? 'is-invalid'
                                          : ''
                                      "
                                      :value="inputValue"
                                      v-on="inputEvents"
                                    />
                                  </template>
                                </v-date-picker>
                              </div>
                            </div>

                            <div
                              class="form-group col-12 col-lg-6 col-md-6 d-flex justify-content-between align-items-center"
                            >
                              <div class="form-group-child">
                                <div class="d-flex flex-column">
                                  <div>
                                    <label
                                      class="font-weight-bold"
                                      :for="'photo-certificates-' + i"
                                      >{{
                                        $i18n.t(
                                          "A copy of the academic qualification"
                                        )
                                      }}:</label
                                    >
                                    <span class="mx-2">
                                      * ({{ $i18n.t("required_img_pdf") }})
                                      (2048kb)
                                    </span>
                                  </div>
                                  <i
                                    class="text-danger mx-2"
                                    v-if="
                                      !!validationErrors[
                                        'qualifications.' +
                                          i +
                                          '.certificate_photo'
                                      ]
                                    "
                                    >{{
                                      $i18n.t(
                                        validationErrors[
                                          "qualifications." +
                                            i +
                                            ".certificate_photo"
                                        ][0]
                                      )
                                    }}</i
                                  >
                                </div>

                                <div
                                  class="d-flex justify-content-between align-center"
                                >
                                  <button
                                    class="btn icon"
                                    :onclick="
                                      'document.getElementById(\'photo-certificates-' +
                                        i +
                                        '\').click()'
                                    "
                                  >
                                    <span
                                      v-if="qualification.certificate_photo"
                                    >
                                      {{
                                        qualification.certificate_photo.split(
                                          "certificates/"
                                        )[1]
                                      }}
                                    </span>
                                    <img
                                      v-else
                                      width="25"
                                      src="images/default-upload.png"
                                      alt=""
                                    />
                                  </button>
                                  <div v-if="qualification.certificate_photo">
                                    <a
                                      v-if="
                                        isPdf(
                                          getUrl(
                                            qualification.certificate_photo
                                          )
                                        )
                                      "
                                      :href="
                                        getUrl(qualification.certificate_photo)
                                      "
                                      style="cursor: pointer"
                                      class="ml-2"
                                      target="_blank"
                                    >
                                      <img src="images/pdf.svg" width="30" />
                                    </a>
                                    <img
                                      v-else
                                      class="userProfileImage"
                                      :src="
                                        getUrl(qualification.certificate_photo)
                                      "
                                      style="cursor: pointer"
                                    />
                                  </div>
                                </div>
                                <input
                                  type="file"
                                  accept=".jpg, .jpeg, .png, .pdf"
                                  :id="'photo-certificates-' + i"
                                  style="
                                  position: absolute;
                                  opacity: 0;
                                  z-index: -1;
                                "
                                  @change="upload($event, 'certificates', i)"
                                />
                              </div>
                            </div>
                            <div
                              class="form-group col-12 col-lg-6 col-md-6 d-flex justify-content-between align-items-center"
                            >
                              <div class="form-group-child">
                                <div class="d-flex flex-column">
                                  <div>
                                    <label
                                      class="font-weight-bold"
                                      :for="'photo-records-' + i"
                                      >{{
                                        $i18n.t(
                                          "A copy of the academic record"
                                        )
                                      }}:</label
                                    >
                                    <span class="mx-2">
                                      * ({{ $i18n.t("required_img_pdf") }})
                                      (2048kb)
                                    </span>
                                  </div>
                                  <i
                                    class="text-danger mx-2"
                                    v-if="
                                      !!validationErrors[
                                        'qualifications.' + i + '.record_photo'
                                      ]
                                    "
                                    >{{
                                      $i18n.t(
                                        validationErrors[
                                          "qualifications." +
                                            i +
                                            ".record_photo"
                                        ][0]
                                      )
                                    }}</i
                                  >
                                </div>
                                <div
                                  class="d-flex justify-content-between align-center"
                                >
                                  <button
                                    class="btn icon"
                                    :onclick="
                                      'document.getElementById(\'photo-records-' +
                                        i +
                                        '\').click()'
                                    "
                                  >
                                    <span v-if="qualification.record_photo">
                                      {{
                                        qualification.record_photo.split(
                                          "records/"
                                        )[1]
                                      }}
                                    </span>
                                    <img
                                      v-else
                                      width="25"
                                      src="images/default-upload.png"
                                      alt=""
                                    />
                                  </button>
                                  <div v-if="qualification.record_photo">
                                    <a
                                      v-if="
                                        isPdf(
                                          getUrl(qualification.record_photo)
                                        )
                                      "
                                      :href="getUrl(qualification.record_photo)"
                                      style="cursor: pointer"
                                      class="ml-2"
                                      target="_blank"
                                    >
                                      <img src="images/pdf.svg" width="30" />
                                    </a>
                                    <img
                                      v-else
                                      class="userProfileImage"
                                      :src="getUrl(qualification.record_photo)"
                                      style="cursor: pointer"
                                    />
                                  </div>
                                </div>
                                <input
                                  type="file"
                                  accept=".jpg, .jpeg, .png, .pdf"
                                  :id="'photo-records-' + i"
                                  style="
                                  position: absolute;
                                  opacity: 0;
                                  z-index: -1;
                                "
                                  @change="upload($event, 'records', i)"
                                />
                              </div>
                            </div>
                            <div
                              class="form-group col-12 col-lg-6 col-md-6 d-flex justify-content-between align-items-center"
                            >
                              <div class="form-group-child">
                                <div class="d-flex flex-column">
                                  <div>
                                    <label
                                      class="font-weight-bold"
                                      :for="'photo-equivalency-' + i"
                                      >{{
                                        $i18n.t(
                                          "A copy of the academic equivalency"
                                        )
                                      }}:</label
                                    >
                                    <span class="mx-2">
                                      * ({{ $i18n.t("required_img_pdf") }})
                                      (2048kb)
                                    </span>
                                  </div>
                                  <i
                                    class="text-danger mx-2"
                                    v-if="
                                      !!validationErrors[
                                        'qualifications.' +
                                          i +
                                          '.equivalency_photo'
                                      ]
                                    "
                                    >{{
                                      validationErrors[
                                        "qualifications." +
                                          i +
                                          ".equivalency_photo"
                                      ][0]
                                    }}</i
                                  >
                                </div>

                                <div
                                  class="d-flex justify-content-between align-center"
                                >
                                  <button
                                    class="btn icon"
                                    :onclick="
                                      'document.getElementById(\'photo-equivalency-' +
                                        i +
                                        '\').click()'
                                    "
                                  >
                                    <span
                                      v-if="qualification.equivalency_photo"
                                    >
                                      {{
                                        qualification.equivalency_photo.split(
                                          "equivalency/"
                                        )[1]
                                      }}
                                    </span>
                                    <img
                                      v-else
                                      width="25"
                                      src="images/default-upload.png"
                                      alt=""
                                    />
                                  </button>
                                  <div v-if="qualification.equivalency_photo">
                                    <a
                                      v-if="
                                        isPdf(
                                          getUrl(
                                            qualification.equivalency_photo
                                          )
                                        )
                                      "
                                      :href="
                                        getUrl(qualification.equivalency_photo)
                                      "
                                      style="cursor: pointer"
                                      class="ml-2"
                                      target="_blank"
                                    >
                                      <img src="images/pdf.svg" width="30" />
                                    </a>
                                    <img
                                      v-else
                                      class="userProfileImage"
                                      :src="
                                        getUrl(qualification.equivalency_photo)
                                      "
                                      style="cursor: pointer"
                                    />
                                  </div>
                                </div>

                                <input
                                  type="file"
                                  :id="'photo-equivalency-' + i"
                                  style="
                                  position: absolute;
                                  opacity: 0;
                                  z-index: -1;
                                "
                                  @change="upload($event, 'equivalency', i)"
                                />
                              </div>
                            </div>
                            <div
                              class="col-12 d-flex justify-content-end align-items-center"
                            >
                              <a
                                v-if="i === 0"
                                class="bg-dark text-white btn-sm add-block"
                                style="display: flex; align-items: center; justify-content: center;"
                                @click="addQualification"
                              >
                                <img
                                  src="/images/plus.svg"
                                  alt=""
                                  width="14"
                                  height="14"
                                  style="filter: brightness(0) invert(1)"
                                />
                              </a>
                              <a
                                v-else
                                class="bg-danger text-white btn-sm add-block"
                                @click="userProfile.qualifications.splice(i, 1)"
                              >
                                <img
                                  src="/images/minus.svg"
                                  alt=""
                                  width="14"
                                  height="14"
                                  style="filter: brightness(0) invert(1)"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="Courses"
                        role="tabpanel"
                        aria-labelledby="Courses-tab"
                      >
                        <div class="row">
                          <label class="font-weight-bold col-12"
                            >{{ $i18n.t("Training Courses") }}:</label
                          >
                          <div
                            v-for="(trainingCourse,
                            i) in userProfileImages.trainingCourses"
                            class="form-group align-items-end col-12"
                            :key="'trainingCourse-' + i"
                          >
                            <div class="row">
                              <div class="form-group col-md-6">
                                <div class="form-group-child">
                                  <label>{{
                                    $i18n.t("Training Courses Title")
                                  }}</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    :class="
                                      !!validationErrors[
                                        'photos.trainingCourses.' + i + '.t1'
                                      ]
                                        ? 'is-invalid'
                                        : ''
                                    "
                                    v-model="
                                      userProfileImages.trainingCourses[i].t1
                                    "
                                  />
                                  <!-- <i
                                    class="text-danger"
                                    v-if="
                                      !!validationErrors[
                                        'photos.trainingCourses.' + i + '.t1'
                                      ]
                                    "
                                    >{{
                                      validationErrors[
                                        'photos.trainingCourses.' + i + '.t1'
                                      ][0]
                                    }}</i
                                  > -->
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group-child">
                                  <label>{{
                                    $i18n.t("Training Agency")
                                  }}</label>
                                  <input
                                    type="text"
                                    class="form-control col"
                                    :class="
                                      !!validationErrors[
                                        'photos.trainingCourses.' + i + '.t2'
                                      ]
                                        ? 'is-invalid'
                                        : ''
                                    "
                                    v-model="
                                      userProfileImages.trainingCourses[i].t2
                                    "
                                  />
                                  <!-- <i
                                    class="text-danger"
                                    v-if="
                                      !!validationErrors[
                                        'photos.trainingCourses.' + i + '.t2'
                                      ]
                                    "
                                    >{{
                                      validationErrors[
                                        'photos.trainingCourses.' + i + '.t2'
                                      ][0]
                                    }}</i
                                  > -->
                                </div>
                              </div>
                              <div class="col-md-6 mt-3">
                                <div class="form-group-child">
                                  <label>{{ $i18n.t("Start Date") }}</label>
                                  <v-date-picker
                                    color="orange"
                                    v-model="
                                      userProfileImages.trainingCourses[i].t3
                                    "
                                  >
                                    <template
                                      v-slot="{ inputValue, inputEvents }"
                                    >
                                      <input
                                        class="form-control"
                                        :class="
                                          !!validationErrors[
                                            'photos.trainingCourses.' +
                                              i +
                                              '.t3'
                                          ]
                                            ? 'is-invalid'
                                            : ''
                                        "
                                        :value="inputValue"
                                        v-on="inputEvents"
                                      />
                                    </template>
                                  </v-date-picker>

                                  <!-- <i
                                    class="text-danger"
                                    v-if="
                                      !!validationErrors[
                                        'photos.trainingCourses.' + i + '.t3'
                                      ]
                                    "
                                    >{{
                                      validationErrors[
                                        'photos.trainingCourses.' + i + '.t3'
                                      ][0]
                                    }}</i
                                  > -->
                                </div>
                              </div>
                              <div class="col-md-6 mt-3">
                                <div class="form-group-child">
                                  <label>
                                    {{ $i18n.t("End Date") }}
                                  </label>
                                  <v-date-picker
                                    color="orange"
                                    v-model="
                                      userProfileImages.trainingCourses[i].t4
                                    "
                                  >
                                    <template
                                      v-slot="{ inputValue, inputEvents }"
                                    >
                                      <input
                                        class="form-control"
                                        :class="
                                          !!validationErrors[
                                            'photos.trainingCourses.' +
                                              i +
                                              '.t4'
                                          ]
                                            ? 'is-invalid'
                                            : ''
                                        "
                                        :value="inputValue"
                                        v-on="inputEvents"
                                      />
                                    </template>
                                  </v-date-picker>

                                  <!-- <i
                                    class="text-danger"
                                    v-if="
                                      !!validationErrors[
                                        'photos.trainingCourses.' + i + '.t4'
                                      ]
                                    "
                                    >{{
                                      validationErrors[
                                        'photos.trainingCourses.' + i + '.t4'
                                      ][0]
                                    }}</i
                                  > -->
                                </div>
                              </div>
                              <div
                                class="col-12 col-lg-6 col-md-6 mt-3 mb-4 ml-3"
                              >
                                <div class="form-group-child">
                                  <div class="d-flex flex-column">
                                    <div>
                                      <label for="">
                                        {{
                                          $i18n.t(
                                            "A copy of the training course certificate"
                                          )
                                        }}
                                      </label>
                                      <span class="mx-2">
                                        * ({{ $i18n.t("required_img_pdf") }})
                                        (2048kb)
                                      </span>
                                      <i
                                        class="text-danger mx-2"
                                        v-if="
                                          !!validationErrors[
                                            'photos.trainingCourses.' +
                                              i +
                                              '.name'
                                          ]
                                        "
                                        >{{
                                          $i18n.t(
                                            validationErrors[
                                              "photos.trainingCourses." +
                                                i +
                                                ".name"
                                            ][0]
                                          )
                                        }}</i
                                      >
                                    </div>

                                    <div
                                      class="d-flex justify-content-between align-center"
                                    >
                                      <button
                                        class="btn icon"
                                        :onclick="
                                          'document.getElementById(\'photo-trainingCourses-' +
                                            i +
                                            '\').click()'
                                        "
                                      >
                                        <span v-if="trainingCourse.name">
                                          {{
                                            trainingCourse.name.split(
                                              "trainingCourses/"
                                            )[1]
                                          }}
                                        </span>
                                        <img
                                          v-else
                                          width="25"
                                          src="images/default-upload.png"
                                          alt=""
                                        />
                                      </button>
                                      <div v-if="trainingCourse.name">
                                        <a
                                          v-if="
                                            isPdf(getUrl(trainingCourse.name))
                                          "
                                          :href="getUrl(trainingCourse.name)"
                                          style="cursor: pointer"
                                          class="ml-2"
                                          target="_blank"
                                        >
                                          <img
                                            src="images/pdf.svg"
                                            width="30"
                                          />
                                        </a>
                                        <img
                                          v-else
                                          class="userProfileImage"
                                          :src="getUrl(trainingCourse.name)"
                                        />
                                      </div>
                                    </div>
                                    <input
                                      type="file"
                                      :id="'photo-trainingCourses-' + i"
                                      style="
                                      position: absolute;
                                      opacity: 0;
                                      z-index: -1;
                                    "
                                      @change="
                                        upload($event, 'trainingCourses', i)
                                      "
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                class="col-12 d-flex justify-content-end align-items-center"
                              >
                                <a
                                  v-if="i === 0"
                                  class="bg-dark text-white add-block btn-sm"
                                  style="display: flex; align-items: center; justify-content: center;"
                                  @click="
                                    userProfileImages.trainingCourses.push({
                                      name: '',
                                      t1: '',
                                      t2: '',
                                      t3: '',
                                      t4: '',
                                    })
                                  "
                                >
                                  <img
                                    src="/images/plus.svg"
                                    alt=""
                                    width="14"
                                    height="14"
                                    style="filter: brightness(0) invert(1)"
                                  />
                                </a>
                                <a
                                  v-else
                                  class="bg-danger text-white ml-2 add-block btn-sm"
                                  @click="
                                    userProfileImages.trainingCourses.splice(
                                      i,
                                      1
                                    )
                                  "
                                >
                                  <img
                                    src="/images/minus.svg"
                                    alt=""
                                    width="14"
                                    height="14"
                                    style="filter: brightness(0) invert(1)"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="Experiences"
                        role="tabpanel"
                        aria-labelledby="Experiences-tab container"
                      >
                        <div class="row">
                          <div class="form-group col-12 col-lg-6 col-md-6">
                            <div class="form-group-child">
                              <label
                                class="font-weight-bold"
                                for="Select Experience"
                                >{{ $i18n.t("Select Experience") }}:</label
                              >
                              <v-select
                                id="exp"
                                v-model="selectedExp"
                                :clearable="false"
                                :options="expLevels"
                                label="label"
                                :reduce="(exp) => exp.value"
                              />
                            </div>
                            <i
                              class="text-danger"
                              v-if="validationErrors['photos.workExperiences']"
                              >{{
                                $i18n.t(
                                  validationErrors["photos.workExperiences"][0]
                                )
                              }}</i
                            >
                          </div>

                          <div
                            class="col-12 mt-2"
                            v-if="selectedExp === 'experienced'"
                          >
                            <div class="d-flex justify-content-between">
                              <div>
                                <div class="d-flex">
                                  <label class="font-weight-bold"
                                    >{{ $i18n.t("Work Experiences") }}:
                                  </label>
                                  <!-- <strong class="mx-2"> ( `${years} ${this.$i18n.t('years')}, ${months} ${this.$i18n.t('months')} ` {{getWorkExperiences.months}})</strong> -->
                                  <strong class="mx-2">
                                    ( {{ getWorkExperiences.years }}
                                    {{ $i18n.t("years") }} ,
                                    {{ getWorkExperiences.months }}
                                    {{ $i18n.t("months") }} )</strong
                                  >
                                </div>
                                <!-- <i
                                  class="text-danger"
                                  v-if="
                                    validationErrors[
                                      'photos.workExperiences'
                                    ] &&
                                      validationErrors[
                                        'photos.workExperiences'
                                      ][0] ===
                                        'The at least one work experience  field is required.'
                                  "
                                  >{{
                                    $i18n.t(
                                      validationErrors[
                                        "photos.workExperiences"
                                      ][0]
                                    )
                                  }}</i
                                > -->
                              </div>

                              <a
                                class="bg-dark text-white mt-2 add-block btn-sm"
                                style="display: flex; align-items: center; justify-content: center;"
                                @click="createWorkExperience"
                              >
                                <img
                                  src="/images/plus.svg"
                                  alt=""
                                  width="14"
                                  height="14"
                                  style="filter: brightness(0) invert(1)"
                                />
                              </a>
                            </div>
                            <div>
                              <!-- gosi certificate -->
                              <div class="mt-4 mb-4">
                                <div
                                  class="form-group col-12 col-md-6"
                                  v-if="
                                    userProfileImages.workExperiences.length
                                  "
                                >
                                  <div class="form-group-child">
                                    <div class="d-flex flex-column">
                                      <label for="" class="mb-2">
                                        {{
                                          $i18n.t(
                                            "A copy of the gosi certificate"
                                          )
                                        }}
                                      </label>
                                      <!-- <i
                                    class="text-danger"
                                    v-if="
                                      !!validationErrors[
                                        'photos.workExperiences.' + i + '.gosi_certificate'
                                      ]
                                    "
                                    >{{
                                      validationErrors[
                                        'photos.workExperiences.' + i + '.gosi_certificate'
                                      ][0]
                                    }}</i
                                  > -->
                                    </div>

                                    <div
                                      class="d-flex justify-content-between align-center"
                                    >
                                      <button
                                        class="btn icon"
                                        :onclick="
                                          'document.getElementById(\'photo-gosi_certificate-0' +
                                            '\').click()'
                                        "
                                      >
                                        <span
                                          v-if="
                                            userProfileImages.workExperiences[0]
                                              .gosi_certificate
                                          "
                                        >
                                          {{
                                            userProfileImages.workExperiences[0].gosi_certificate.split(
                                              "workExperiences/"
                                            )[1]
                                          }}
                                        </span>
                                        <img
                                          v-else
                                          width="25"
                                          src="images/default-upload.png"
                                          alt=""
                                        />
                                      </button>
                                      <div
                                        v-if="
                                          userProfileImages.workExperiences[0]
                                            .gosi_certificate
                                        "
                                      >
                                        <a
                                          v-if="
                                            isPdf(
                                              getUrl(
                                                userProfileImages
                                                  .workExperiences[0]
                                                  .gosi_certificate
                                              )
                                            )
                                          "
                                          :href="
                                            getUrl(
                                              userProfileImages
                                                .workExperiences[0]
                                                .gosi_certificate
                                            )
                                          "
                                          style="cursor: pointer"
                                          class="ml-2"
                                          target="_blank"
                                        >
                                          <img
                                            src="images/pdf.svg"
                                            width="30"
                                          />
                                        </a>
                                        <img
                                          v-else
                                          class="userProfileImage"
                                          :src="
                                            getUrl(
                                              userProfileImages
                                                .workExperiences[0]
                                                .gosi_certificate
                                            )
                                          "
                                          style="cursor: pointer"
                                          :onclick="
                                            'document.getElementById(\'photo-gosi_certificate-0' +
                                              '\').click()'
                                          "
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <input
                                type="file"
                                :id="'photo-gosi_certificate-0'"
                                style="
                                  position: absolute;
                                  opacity: 0;
                                  z-index: -1;
                                "
                                @change="upload($event, 'gosi_certificate', 0)"
                              />
                            </div>
                            <div
                              v-for="(workExperience,
                              i) in userProfileImages.workExperiences"
                              class="row form-group"
                              :key="'workExperience-' + i"
                            >
                              <div class="form-group col-12 col-md-6">
                                <div class="form-group-child">
                                  <div class="input-label-cont">
                                    <label>{{
                                      $i18n.t("Working Company")
                                    }}</label>
                                    <i
                                      class="text-danger"
                                      v-if="
                                        !!validationErrors[
                                          'photos.workExperiences.' + i + '.t1'
                                        ]
                                      "
                                      >{{
                                        $i18n.t(
                                          validationErrors[
                                            "photos.workExperiences." +
                                              i +
                                              ".t1"
                                          ][0]
                                        )
                                      }}</i
                                    >
                                  </div>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="
                                      userProfileImages.workExperiences[i].t1
                                    "
                                  />
                                </div>
                              </div>
                              <div class="form-group col-12 col-md-6">
                                <div class="form-group-child">
                                  <div class="input-label-cont">
                                    <label>{{ $i18n.t("Position") }}</label>
                                    <i
                                      class="text-danger"
                                      v-if="
                                        !!validationErrors[
                                          'photos.workExperiences.' + i + '.t2'
                                        ]
                                      "
                                      >{{
                                        $i18n.t(
                                          validationErrors[
                                            "photos.workExperiences." +
                                              i +
                                              ".t2"
                                          ][0]
                                        )
                                      }}</i
                                    >
                                  </div>
                                  <input
                                    type="text"
                                    class="form-control col"
                                    v-model="
                                      userProfileImages.workExperiences[i].t2
                                    "
                                  />
                                </div>
                              </div>
                              <div class="form-group col-12 col-md-6 mt-3">
                                <div class="form-group-child">
                                  <div class="input-label-cont">
                                    <label>{{ $i18n.t("Start Date") }}</label>
                                    <i
                                      class="text-danger"
                                      v-if="
                                        !!validationErrors[
                                          'photos.workExperiences.' + i + '.t3'
                                        ]
                                      "
                                      >{{
                                        $i18n.t(
                                          validationErrors[
                                            "photos.workExperiences." +
                                              i +
                                              ".t3"
                                          ][0]
                                        )
                                      }}</i
                                    >
                                  </div>
                                  <v-date-picker
                                    color="orange"
                                    v-model="
                                      userProfileImages.workExperiences[i].t3
                                    "
                                  >
                                    <template
                                      v-slot="{ inputValue, inputEvents }"
                                    >
                                      <input
                                        class="form-control"
                                        :value="inputValue"
                                        v-on="inputEvents"
                                      />
                                    </template>
                                  </v-date-picker>
                                </div>
                              </div>
                              <div class="form-group col-12 col-md-6 mt-3">
                                <div class="form-group-child">
                                  <label class="col-12 row">
                                    <div class="col">
                                      {{ $i18n.t("End Date") }}
                                    </div>
                                    <div class="col text-right">
                                      <input
                                        type="checkbox"
                                        :checked="
                                          userProfileImages.workExperiences[i]
                                            .t4 === 'current'
                                        "
                                        @click="
                                          workExperienceCurrent($event, i)
                                        "
                                      />
                                      {{ $i18n.t("Current") }}
                                    </div>
                                  </label>
                                  <v-date-picker
                                    color="orange"
                                    v-model="
                                      userProfileImages.workExperiences[i].t4
                                    "
                                    v-if="
                                      userProfileImages.workExperiences[i]
                                        .t4 !== 'current'
                                    "
                                  >
                                    <template
                                      v-slot="{ inputValue, inputEvents }"
                                    >
                                      <input
                                        class="form-control"
                                        :value="inputValue"
                                        v-on="inputEvents"
                                      />
                                    </template>
                                  </v-date-picker>

                                  <i
                                    class="text-danger"
                                    v-if="
                                      userProfileImages.workExperiences[i].t3 &&
                                        userProfileImages.workExperiences[i]
                                          .t4 &&
                                        checkDate(
                                          userProfileImages.workExperiences[i]
                                            .t3,
                                          userProfileImages.workExperiences[i]
                                            .t4
                                        ) === 'invalid'
                                    "
                                  >
                                    {{ $i18n.t("end_date_error") }}
                                  </i>
                                </div>
                              </div>
                              <div class="col-12 col-md-6 mt-4 mb-4">
                                <div class="form-group">
                                  <div class="form-group-child">
                                    <div class="d-flex flex-column">
                                      <label for="" class="mb-2">
                                        {{
                                          $i18n.t(
                                            "A copy of the work experience certificate"
                                          )
                                        }}
                                      </label>
                                      <i
                                        class="text-danger"
                                        v-if="
                                          !!validationErrors[
                                            'photos.workExperiences.' +
                                              i +
                                              '.name'
                                          ]
                                        "
                                        >{{
                                          $i18n.t(
                                            validationErrors[
                                              "photos.workExperiences." +
                                                i +
                                                ".name"
                                            ][0]
                                          )
                                        }}</i
                                      >
                                    </div>

                                    <div
                                      class="d-flex justify-content-between align-center"
                                    >
                                      <button
                                        class="btn icon"
                                        :onclick="
                                          'document.getElementById(\'photo-workExperiences-' +
                                            i +
                                            '\').click()'
                                        "
                                      >
                                        <span v-if="workExperience.name">
                                          {{
                                            workExperience.name.split(
                                              "workExperiences/"
                                            )[1]
                                          }}
                                        </span>
                                        <img
                                          v-else
                                          width="25"
                                          src="images/default-upload.png"
                                          alt=""
                                        />
                                      </button>
                                      <div v-if="workExperience.name">
                                        <a
                                          v-if="
                                            isPdf(getUrl(workExperience.name))
                                          "
                                          :href="getUrl(workExperience.name)"
                                          style="cursor: pointer"
                                          class="ml-2"
                                          target="_blank"
                                        >
                                          <img
                                            src="images/pdf.svg"
                                            width="30"
                                          />
                                        </a>

                                        <img
                                          v-else
                                          class="userProfileImage"
                                          :src="getUrl(workExperience.name)"
                                          style="cursor: pointer"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <input
                                type="file"
                                :id="'photo-workExperiences-' + i"
                                style="
                                  position: absolute;
                                  opacity: 0;
                                  z-index: -1;
                                "
                                @change="upload($event, 'workExperiences', i)"
                              />

                              <div
                                class="col-12 d-flex justify-content-between align-items-center"
                              >
                                <i
                                  class="text-danger"
                                  v-if="
                                    validationErrors[
                                      'photos.workExperiences.' + i
                                    ]
                                  "
                                  >{{
                                    $i18n.t(
                                      validationErrors[
                                        "photos.workExperiences." + i
                                      ][0]
                                    )
                                  }}</i
                                >
                                <!-- <a
                                  v-if="i === 0"
                                  class="bg-dark text-white mt-2  add-block btn-sm"
                                  @click="createWorkExperience"
                                >
                                  +
                                </a> -->
                                <a
                                  class="bg-danger text-white mt-2 add-block btn-sm"
                                  style="display: flex; align-items: center; justify-content: center;"
                                  @click="
                                    userProfileImages.workExperiences.splice(
                                      i,
                                      1
                                    )
                                  "
                                >
                                  <img
                                    src="/images/minus.svg"
                                    alt=""
                                    width="20"
                                    height="20"
                                    style="filter: brightness(0) invert(1)"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="skills"
                        role="tabpanel"
                        aria-labelledby="skills-tab"
                      >
                        <div class="form-group-child">
                          <label class="font-weight-bold"
                            >{{ $i18n.t("Skills") }}:</label
                          >
                        </div>
                        <div
                          class="row mx-0 my-2 p-lg-3 p-2"
                          v-for="(skill, i) in userProfile.skills"
                          :key="'skill-' + i"
                          style="border: 1px solid rgba(228, 230, 232, 1); border-radius: 16px;"
                        >
                          <div class="col-11">
                            <div class="row">
                              <div class="form-group col-12 mb-4">
                                <div class="">
                                  <div class="input-label-cont">
                                    <label
                                      class="font-weight-bold"
                                      style="height: fit-content"
                                      >{{ $i18n.t("skill") }} - {{ i + 1 }} :
                                    </label>
                                    <i
                                      class="text-danger"
                                      v-if="validationErrors['skills.' + i]"
                                      >{{
                                        $i18n.t(
                                          validationErrors["skills." + i][0]
                                        )
                                      }}</i
                                    >
                                  </div>
                                  <div class="row">
                                    <div
                                      class="form-group col-12 col-md-6 mb-2"
                                    >
                                      <div class="form-group-child">
                                        <div class="">
                                          <input
                                            type="text"
                                            class="form-control"
                                            :placeholder="$i18n.t('skill-name')"
                                            v-model="userProfile.skills[i].name"
                                          />
                                        </div>
                                      </div>
                                      <i
                                        class="text-danger mt-2"
                                        v-if="
                                          !!validationErrors[
                                            'skills.' + i + '.name'
                                          ]
                                        "
                                        >{{
                                          $i18n.t(
                                            validationErrors[
                                              "skills." + i + ".name"
                                            ][0]
                                          )
                                        }}</i
                                      >
                                    </div>
                                    <div
                                      class="form-group col-12 col-md-6 mb-2"
                                    >
                                      <div class="form-group-child">
                                        <select
                                          class="form-control font-weight-bold"
                                          style="border: none"
                                          :placeholder="$i18n.t('skill-level')"
                                          v-model="userProfile.skills[i].level"
                                        >
                                          <option disabled selected value="">
                                            {{ $i18n.t("skill-level") }}
                                          </option>
                                          <option value="Basic">
                                            {{ $i18n.t("Basic") }}
                                          </option>
                                          <option value="Medium">
                                            {{ $i18n.t("Medium") }}
                                          </option>
                                          <option value="Advanced">
                                            {{ $i18n.t("Advanced") }}
                                          </option>
                                        </select>
                                        <i
                                          class="text-danger"
                                          v-if="
                                            !!validationErrors[
                                              'skills.' + i + '.level'
                                            ]
                                          "
                                          >{{
                                            validationErrors[
                                              "skills." + i + ".level"
                                            ][0]
                                          }}</i
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-1 d-flex justify-content-end align-items-center"
                          >
                            <a
                              v-if="i === 0"
                              class="bg-dark text-white add-block btn-sm"
                              style="display: flex; align-items: center; justify-content: center;"
                              @click="
                                userProfile.skills.push({
                                  name: '',
                                  level: '',
                                })
                              "
                            >
                              <img
                                src="/images/plus.svg"
                                alt=""
                                width="14"
                                height="14"
                                style="filter: brightness(0) invert(1)"
                              />
                            </a>
                            <a
                              v-else
                              class="bg-danger text-white btn-sm add-block"
                              style="display: flex; align-items: center; justify-content: center;"
                              @click="userProfile.skills.splice(i, 1)"
                            >
                              <img
                                src="/images/minus.svg"
                                alt=""
                                width="14"
                                height="14"
                                style="filter: brightness(0) invert(1)"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="Questions"
                        role="tabpanel"
                        aria-labelledby="Questions-tab"
                      >
                        <div class="row">
                          <div class="col-12">
                            <label class="font-weight-bold col-12"
                              >{{ $i18n.t("Questions") }}:</label
                            >
                          </div>
                          <div class="col-12">
                            <div class="row mb-4">
                              <div
                                class="form-group col-md-6 mb-4"
                                style="display: none;"
                              >
                                <div class="form-group-child">
                                  <div class="input-label-cont">
                                    <label class="font-weight-bold" for="city"
                                      >{{
                                        $i18n.t("Preferred working area")
                                      }}:</label
                                    >
                                    <i
                                      class="text-danger"
                                      v-if="
                                        !!validationErrors[
                                          'workingPerferedErea'
                                        ]
                                      "
                                      >{{
                                        $i18n.t(
                                          validationErrors[
                                            "workingPerferedErea"
                                          ][0]
                                        )
                                      }}</i
                                    >
                                  </div>
                                  <select
                                    id="city"
                                    class="form-control font-weight-bold"
                                    style="border: 0"
                                    v-model="userProfile.workingPerferedErea"
                                  >
                                    <option value="jeddah">
                                      {{ $i18n.t("Jeddah") }}
                                    </option>
                                    <option value="kharj">
                                      {{ $i18n.t("Al-Kharj") }}
                                    </option>
                                    <option selected="selected" value="riyadh">
                                      {{ $i18n.t("Riyadh") }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div class="form-group col-md-6 mb-4">
                                <div class="form-group-child">
                                  <div class="input-label-cont">
                                    <label class="font-weight-bold">{{
                                      $i18n.t("Do you smoke")
                                    }}</label>
                                    <i
                                      class="text-danger"
                                      v-if="!!validationErrors['smoker']"
                                      >{{
                                        $i18n.t(validationErrors["smoker"][0])
                                      }}</i
                                    >
                                  </div>
                                  <!--                                  <label class="col-md-6">-->
                                  <!--                                    <input-->
                                  <!--                                        type="radio"-->
                                  <!--                                        value="yes"-->
                                  <!--                                        name="smoker"-->
                                  <!--                                        v-model="userProfile.smoker"-->
                                  <!--                                    />-->
                                  <!--                                    {{ $i18n.t("Yes") }}-->
                                  <!--                                  </label>-->
                                  <!--                                  <label class="col-md-6">-->
                                  <!--                                    <input-->
                                  <!--                                        type="radio"-->
                                  <!--                                        value="no"-->
                                  <!--                                        name="smoker"-->
                                  <!--                                        v-model="userProfile.smoker"-->
                                  <!--                                    />-->
                                  <!--                                    {{ $i18n.t("No") }}-->
                                  <!--                                  </label>-->
                                  <select
                                    id="smoker"
                                    class="form-control font-weight-bold"
                                    style="border: 0"
                                    v-model="userProfile.smoker"
                                  >
                                    <option value="yes">
                                      {{ $i18n.t("Yes") }}
                                    </option>
                                    <option value="no">
                                      {{ $i18n.t("No") }}
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <div class="form-group col-md-6 mb-4">
                                <div class="form-group-child">
                                  <div class="input-label-cont">
                                    <label class="font-weight-bold"
                                      >{{ $i18n.t("Current Salary") }}:</label
                                    >
                                    <i
                                      class="text-danger"
                                      v-if="!!validationErrors['currentSalary']"
                                      >{{
                                        $i18n.t(
                                          validationErrors["currentSalary"][0]
                                        )
                                      }}</i
                                    >
                                  </div>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="userProfile.currentSalary"
                                    @keydown="checkInputNumber"
                                  />
                                </div>
                              </div>
                              <div class="form-group col-md-6 mb-4">
                                <div class="form-group-child">
                                  <div class="input-label-cont">
                                    <label class="font-weight-bold"
                                      >{{ $i18n.t("Expected Salary") }}:</label
                                    >
                                    <i
                                      class="text-danger"
                                      v-if="
                                        !!validationErrors['expectedSalary']
                                      "
                                      >{{
                                        $i18n.t(
                                          validationErrors["expectedSalary"][0]
                                        )
                                      }}</i
                                    >
                                  </div>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="userProfile.expectedSalary"
                                    @keydown="checkInputNumber"
                                  />
                                </div>
                              </div>

                              <div class="form-group col-md-6 mb-4">
                                <div class="form-group-child">
                                  <div class="input-label-cont">
                                    <label class="font-weight-bold"
                                      >{{
                                        $i18n.t("Notify Period on Accept")
                                      }}:</label
                                    >
                                    <i
                                      class="text-danger"
                                      v-if="
                                        !!validationErrors[
                                          'notifyPeriodOnAccept'
                                        ]
                                      "
                                      >{{
                                        $i18n.t(
                                          validationErrors[
                                            "notifyPeriodOnAccept"
                                          ][0]
                                        )
                                      }}</i
                                    >
                                  </div>
                                  <input
                                    type="number"
                                    @keydown="checkInputNumber"
                                    class="form-control"
                                    v-model="userProfile.notifyPeriodOnAccept"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="container">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <button
                        :disabled="currentActiveTab === 0"
                        @click="toggleTab('prev')"
                        class="btn btn-second prev"
                        style="background: var(--main-color)"
                        title="previous"
                      >
                        <!-- {{ $i18n.t("Previous") }} -->
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-arrow-left"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                          />
                        </svg>
                      </button>
                      <button
                        type="button"
                        class="btn btn-main col-6"
                        @click="update"
                        :disabled="loading"
                      >
                        <div class="row pl-2 pr-2">
                          <div class="text-left">
                            <span
                              class="spinner-border"
                              role="status"
                              :style="
                                'display: ' + (loading ? 'block' : 'none')
                              "
                            >
                              <span class="sr-only">{{
                                $i18n.t("Loading")
                              }}</span>
                            </span>
                          </div>
                          <div class="col d-block p-1 text-center">
                            {{ $i18n.t("Update Profile") }}
                          </div>
                        </div>
                      </button>
                      <button
                        class="btn btn-second next"
                        :disabled="currentActiveTab === 5"
                        @click="toggleTab('next')"
                        style="background: var(--main-color)"
                        title="Next"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-arrow-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                          />
                        </svg>
                        <!-- {{ $i18n.t("Next") }} -->
                      </button>
                    </div>
                  </div>

                  <!-- <div class="form-group col-md-6 mb-4">
                    <label class="col-12">&nbsp;</label>
                    <div class="grid">
                     
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer></Footer>
  </div>
</template>

<style lang="scss" scoped>
div#app,
div#jobs {
  background: #fff !important;
  display: flex;
  flex-direction: column;
  min-width: 100vh;
}
::v-deep .footer-cont {
  margin-top: auto;
}

.vs__dropdown-toggle {
  border: none !important;
}

.page-wrapper {
  background: #fff !important;
  min-height: calc(100vh - 224px) !important;

  .card {
    border: none !important;

    .nav-tabs {
      border-bottom: none;
    }

    .tab-pane {
      margin-top: 30px !important;

      label {
        color: #8083a3;
      }

      .form-group {
        //margin: 0 5px;
        .input-tel__input {
          padding-top: 0 !important;
        }

        .form-group-child {
          width: 90%;
          //padding: 5px 0;
          border-bottom: 1px solid rgba(228, 230, 232, 0.6);
        }

        label {
          color: #8083a3;
          margin-bottom: 5px;
        }

        input,
        textarea {
          border: none;
          font-weight: bold;
          padding: 10px 0;
        }
      }
    }
  }
}

.profile-tabs #myTab .nav-item {
  border: none !important;
  padding: 12px 15px;
  font-weight: bold;

  a {
    color: #050000;
    min-height: 48px;
    line-height: 48px;

    &.active {
      border-radius: 12px;
      background: var(--main-color) !important;
      color: #fff;
      border: none !important;
    }
  }
}
</style>
<style>
.userProfileImage {
  /* width: 100%;
  max-height: 300px; */
  width: 30px;
  height: 30px;
  margin-left: 0.5rem;
}

#tj-datetime-input,
#tj-datetime-input:focus,
#tj-datetime-input:active {
  border: 0;
  outline: 0;
}

.card-body {
  height: auto;
}

.profile-user-img {
  margin: 0 auto;
  width: 100px;
  padding: 3px;
  border: 0.5px solid #adb5bd;
  box-shadow: 1px 1px 1px #eee;
  border-radius: 50%;
}

.profile-username {
  font-size: 1rem;
  margin-top: 5px;
  font-weight: 600;
}

.list-group-unbordered > .list-group-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

#Courses,
#Experiences {
  margin-bottom: 10rem;
}

.vs__dropdown-toggle {
  padding: 2px 0 4px 0;
}

.phone-error .input-tel__input {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.select-error .vs__dropdown-toggle,
.select-error .vs__dropdown-toggle {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.phone_key-error .input-country-selector {
  border-color: red !important;
}
.card-prof {
  min-height: 80px;
}
.card-prof p.font-weight-bold {
  font-size: 0.9rem;
}
</style>
