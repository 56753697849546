import { render, staticRenderFns } from "./CheckboxElement.vue?vue&type=template&id=71076cc4&scoped=true&"
import script from "./CheckboxElement.vue?vue&type=script&lang=js&"
export * from "./CheckboxElement.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../website-vue/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71076cc4",
  null
  
)

export default component.exports