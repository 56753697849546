<template>
  <div id="jobs" class="main-wrapper">
    <Navbar></Navbar>
    <!-- <breadcrumb :links="links"></breadcrumb> -->
    <main class="page-wrapper single-job pt-5">
      <div class="container">
        <div class="row pt-md-5 custom-container">
          <div class="col-12 mb-4">
            <job-filter></job-filter>
          </div>
          <div class="col-12  h-full" v-if="loading">
            <div
              class="mt-5 text-content d-flex align-items-center flex-column"
            >
              <div
                style="width: 100px; height: 100px;"
                class="d-flex justify-content-center align-items-center"
              >
                <div class="loader loading"></div>
              </div>
              <p class="mt-3 font-weight-bold" style="color: #002e29">
                {{ $i18n.t("please_wait") }}.
              </p>
            </div>
          </div>
          <div class="col-12 h-full" v-else>
            <div class="latest-jobs-container jobs-page-card m-0 p-0 h-full">
              <jobs-component
                v-if="jobs.data.length > 0"
                :jobs="jobs.data"
              ></jobs-component>
              <div
                class="d-flex justify-center align-center flex-column"
                v-else
              >
                <img
                  src="@/assets/logo.png"
                  alt="GADD"
                  class="mx-auto mb-3"
                  width="100"
                />
                <p class="mb-0 font-weight-bold text-center">
                  {{ $i18n.t("No available jobs") }}
                </p>
              </div>
            </div>
            <paginate
              v-model="currentPage"
              v-if="jobs.meta.lastPage"
              :page-count="jobs.meta.lastPage"
              :click-handler="
                (pageNum) => {
                  this.$router.push('?page=' + pageNum);
                  this.getData(pageNum);
                }
              "
              :prev-text="'<'"
              :next-text="'>'"
              :page-range="5"
              :margin-pages="0"
              :container-class="'pagination flex-wrap mt-3 mb-3'"
              :page-class="'page-link cursor-pointer'"
            >
            </paginate>
          </div>
        </div>
      </div>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";

import JobsComponent from "@/components/JobsComponent";
import JobFilter from "@/pages/jobs/partials/JobFilter";

export default {
  name: "Jobs",
  components: { JobFilter, JobsComponent, Footer, Navbar },
  data() {
    return {
      links: [
        {
          key: "Jobs",
          value: "jobs",
        },
      ],
      currentPage: 1,
      loading: false,
      jobs: {
        data: [],
        meta: {},
      },
      limit: 10,
      field: "id",
      sort: "DESC",
      deleted: false,
      resource: "all",
      columns: [],
      operand: [],
      column_values: [],
      regions: [],
    };
  },
  created() {
    // document.body.style.overflowY = "hidden";

    if (this.$route.query.keyword) {
      this.columns.push("title");
      this.operand.push("like");
      this.column_values.push(this.$route.query.keyword);
    }
    if (typeof this.$route.query["regions[]"] === "undefined")
      this.regions = [];
    if (typeof this.$route.query["regions[]"] === "string")
      this.regions.push(this.$route.query["regions[]"]);
    else this.regions = this.$route.query["regions[]"];

    this.getData(
      parseInt(this.$route.query.page) ? parseInt(this.$route.query.page) : 1
    );
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    getData(offset = 1) {
      this.loading = true;

      if (offset === parseInt(this.jobs.meta.currentPage)) {
        return;
      }
      // reload after create
      if (offset === -2) {
        offset = 1;
      }
      // reload after update
      if (offset === -1) {
        offset = this.jobs.meta.currentPage;
      }

      axios
        .get("website/jobs", {
          params: {
            limit: this.limit,
            offset: offset,
            field: this.field,
            sort: this.sort,
            deleted: this.deleted,
            resource: this.resource,
            columns: this.columns,
            operand: this.operand,
            column_values: this.column_values,
            regions: this.regions,
          },
        })
        .then((response) => {
          // console.log(response);
          if (typeof response.data.data !== "undefined") {
            this.jobs = response.data.data;
          }
          this.loading = false;
          document.body.style.overflowY = "auto";
          const loaderElm = document.querySelector(".preloader");
          if (loaderElm) {
            document.querySelector(".preloader").style.display = "none";
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  min-height: calc(100vh - 224px) !important;
  //background: #0a676b !important;
}
</style>
